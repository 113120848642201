export const EventEmitter = {
    events: {},

    on: function (event, callback) {
        if (!this.events[event]) this.events[event] = [];
        this.events[event].push(callback);
    },

    off: function  (event) {
        // console.log('b', this.events[event].length, this.events[event])
        // // this.events[event].filter(e => e !== callback);
        // this.events[event].filter(e => { console.log(e !== callback); return e !== callback});
        // console.log('a', this.events[event].length, this.events[event]);
        this.events[event] = undefined;
    },

    emit: function (event, ...data) {
        if (!this.events[event]) return;
        this.events[event].forEach(callback => callback(...data));
    }
};

