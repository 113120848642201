import React from "react";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import { URLS } from "../Utils/Constants";
import { withRouter } from "react-router-dom";
import { AppContext } from "../Utils/AppContext";

class ConfirmAccount extends React.Component {
    state = {
        message: "Activating account..."
    };

    componentDidMount() {
        this.confirmAccount();
    }

    confirmAccount = async () => {
        let url = new URL(window.location.href);
        let token = url.searchParams.get("token");
        if (token) {
            try {
                let response = await axios.get(`${URLS.ACCOUNTS}/users/account_verification?token=${token}`);
                if (response.status === 200) {
                    this.setState({ message: "Your account has been successfully activated. Redirecting to login..." });
                    this.redirectToLogin();
                }

            } catch (error) {
                if (error.response && error.response.status === 404) {
                    this.setState({ message: "Your account has already been activated. Redirecting to login..." });
                    this.redirectToLogin();
                } else {
                    this.setState({ message: "Failed to activate account. Please try again by reloading this page." });
                }
            }
        }
    };

    redirectToLogin = () =>{
        setTimeout(()=>{
            this.props.history.push({ pathname: "/login" });
        },5000);
    }

    render() {
        return (
            <div className="container page-container">
                <Row className="justify-content-center">
                    <Col xl={5} lg={8} md={10} xs={11}>
                        {this.state.message}
                    </Col>
                </Row>
            </div>
        );
    }
}

ConfirmAccount.contextType = AppContext;
export default withRouter(ConfirmAccount);
