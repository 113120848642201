import React from "react";
import { Card, Form, Button, Col } from "react-bootstrap";

class ProfileStudyCard extends React.Component {
    render() {

        let currentYear = new Date().getFullYear();

        return (
            <Card className="simple-card mb-3">
                <Card.Body>
                    <div className="d-flex align-items-start justify-content-between">
                        <Form.Row>
                            <Form.Group as={ Col } md="6">
                                <Form.Label>Beginning year</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="beginningStudyYear"
                                    value={ this.props.study.beginningStudyYear }
                                    onChange={ this.props.onChange }
                                    data-id={ this.props.index }
                                    className="study-field"
                                    min="1920"
                                    max={currentYear}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">Please enter the beginning year.</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={ Col } md="6">
                                <Form.Label>Ending year</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="endingStudyYear"
                                    value={ this.props.study.endingStudyYear || "" }
                                    onChange={ this.props.onChange }
                                    data-id={ this.props.index }
                                    className="study-field"
                                    min="1920"
                                    max= {currentYear}
                                />
                                <Form.Text>Leave empty if still ongoing</Form.Text>
                            </Form.Group>
                        </Form.Row>
                        <Button variant="outline-primary" className="ml-2" data-id={ this.props.index } onClick={ this.props.delete }>
                            Delete
                        </Button>
                    </div>

                    <Form.Group>
                        <Form.Label>Institution</Form.Label>
                        <Form.Control
                            name="school"
                            value={ this.props.study.school }
                            onChange={ this.props.onChange }
                            data-id={ this.props.index }
                            className="study-field"
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter the name of the institution you { (this.props.study.endingStudyYear) ? "have studied" : "are studying" } at.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Certification</Form.Label>
                        <Form.Control
                            name="name"
                            value={ this.props.study.name }
                            onChange={ this.props.onChange }
                            data-id={ this.props.index }
                            className="study-field"
                        />
                        <Form.Control.Feedback type="invalid">Please enter the obtained certification.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            name="studiesDescription"
                            as="textarea"
                            value={ this.props.study.studiesDescription }
                            onChange={ this.props.onChange }
                            data-id={ this.props.index }
                            className="study-field"
                        />

                    </Form.Group>
                </Card.Body>
            </Card>
        );
    }
}

export default ProfileStudyCard;
