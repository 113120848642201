import React from "react";
import axios from "axios";
import { AppContext } from "../Utils/AppContext";
import NotificationModal from "../Utils/NotificationModal";
import "./JobOpenings.css";
import { URLS } from "../Utils/Constants";
import JobOpeningCard from "./JobOpeningCard";
import { withRouter } from "react-router-dom";
import { EventEmitter } from "../Utils/EventEmitter";
import briefcase from "../Images/work.svg";

import EmptyListInfo from "../Utils/EmptyListInfo";

class JobOpeningsList extends React.Component {
    state = {
        candidateHasProfile: false,
        notLoggedInMsgVisible: false,
        jobToSee: null,
        showAll: false,
        keyword: '',
        shortlists: [],
        isLoadingApply: []
    };

    componentDidMount() {
        if (this.context.role === "CANDIDATE_ROLE") {
            this.checkIfCandidateHasProfile();
        }
    }

    checkIfCandidateHasProfile = async () => {
        try {
            let resumeResponse = await axios.get(`${URLS.PROFILES}/resumes/${this.context.userId}`, {
                headers: { "Authorization": "Bearer " + this.context.token }
            });
            if (resumeResponse.status === 200) {
                this.setState({ candidateHasProfile: true });
            }
        } catch (error) {
            this.setState({ authError: `Fetch error ${error}` });
        }
    };

    handleApply = async (idJob) => {
        if (this.context.role === "CANDIDATE_ROLE") {
            if (this.state.candidateHasProfile === false) {
                EventEmitter.emit("showNotification", "warning", "Please create a profile or upload your CV.");
            } else {
                this.setState(prevState => {
                    let isLoadingApply = [...prevState.isLoadingApply];
                    isLoadingApply.push(idJob);
                    return { isLoadingApply };
                });
                try {
                    let response = await axios.put(`${URLS.JOBS}/jobs/${idJob}`, {}, {
                        headers: { "Authorization": "Bearer " + this.context.token }
                    });
                    if (response.status === 200) {
                        EventEmitter.emit("showNotification", "success", "You have applied successfully to the selected job opening.");
                        this.props.reloadJobs();
                    }
                } catch (error) {
                    EventEmitter.emit("showNotification", "danger", "Failed to apply to the selected job opening.");
                }
                this.setState(prevState => {
                    let isLoadingApply = [...prevState.isLoadingApply];
                    isLoadingApply.filter(e => e !== idJob);
                    return { isLoadingApply };
                });
            }
        } else {
            this.showNotLoggedInMsg(true);
        }
    };

    showNotLoggedInMsg = (status) => {
        this.setState({ notLoggedInMsgVisible: status });

    }

    showJobInfo = (job) => {
        this.setState({ jobToSee: job });
        this.props.history.push("/jobOpening?id=" + job.id);
    }

    render() {
        let isCandidate = this.context.role === "CANDIDATE_ROLE";
        let enabledJobOpeningsList = [];
        let disabledJobOpeningsList = [];
        let jobsContent;

        if (this.props.jobsList) {
            this.props.jobsList.forEach((job, index) => {
                let userApplied = false;

                for (let cnd of job.candidates) {
                    if (cnd.candidate === this.context.userId) {
                        userApplied = true;
                        break;
                    }
                }
                let jobCard = <JobOpeningCard
                    job={job}
                    deleteJob={this.props.deleteJob}
                    openEditJobModal={this.props.openEditJobModal}
                    showJobInfo={this.showJobInfo}
                    handleApply={this.handleApply}
                    key={index}
                    isLoadingApply={this.state.isLoadingApply.includes(job.id)}
                />;

                if (!isCandidate || (isCandidate && ((userApplied && this.props.appliedMode === 1) || (!userApplied && this.props.appliedMode === 0) || this.props.appliedMode === 2))) {
                    if (job.enabled) {
                        enabledJobOpeningsList.push(jobCard);
                    } else {
                        disabledJobOpeningsList.push(jobCard);
                    }
                }
            });
        }

        if (enabledJobOpeningsList.length + disabledJobOpeningsList.length > 0) {
            jobsContent = (
                <div className="items-grid">
                {/*<React.Fragment>*/}
                    {enabledJobOpeningsList}
                    {disabledJobOpeningsList}
                {/*</React.Fragment>*/}
                </div>
            );
        } else {
            jobsContent = <EmptyListInfo icon={briefcase} width="10rem" text="You do not have any jobs matching the requested search."/>
        }


        let notLoggedInModalContent = (
            <span>You need to <a href="/register">create an account</a> or <a href="/login">log in</a> in order to apply for this job.</span>
        );

        return (
            <div>
                {jobsContent}

                <NotificationModal
                    title="You can not perform this action"
                    content={notLoggedInModalContent}
                    confirmAction={this.deleteAssessment}
                    show={this.state.notLoggedInMsgVisible}
                    onHide={() => {
                        this.showNotLoggedInMsg(false)
                    }}
                />
            </div>
        );
    }
}

JobOpeningsList.contextType = AppContext;
export default withRouter(JobOpeningsList);
