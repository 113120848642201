import React from "react";
import axios from "axios";
import { Button, Card, Form } from "react-bootstrap";
import HRList from "./HRList";
import CreateHR from "./CreateHR";
import NotificationModal from "../Utils/NotificationModal";
import { URLS } from "../Utils/Constants";
import { AppContext } from "../Utils/AppContext";
import { EventEmitter } from "../Utils/EventEmitter";

class ManageHR extends React.Component {
    state = {
        hrToEdit: null,
        hrToDelete: null,
        shouldCreateNewHR: false,
        searchTerm: "",
        hrs: [],
        isLoadingSaveHR: false
    };

    componentDidMount() {
        this.getHRs();
    }

    getHRs = async () => {
        try {
            let response = await axios.get(`${ URLS.ACCOUNTS }/users/hrs`, {
                headers: { "Authorization": "Bearer " + this.context.token }
            });
            if (response.status === 200) {
                this.setState({ hrs: response.data });
            }
        } catch (error) {
            EventEmitter.emit("showNotification", "danger", "Failed to load HR specialist accounts.");
            console.log("Fetch error", error);
        }
    };

    deleteHR = async () => {
        try {
            let response = await axios.delete(`${ URLS.ACCOUNTS }/users/hrs/${ this.state.hrToDelete }`, {
                headers: { "Authorization": "Bearer " + this.context.token }
            });
            if (response.status === 204) {
                this.getHRs();
                this.showDeleteHRModal(null);
                EventEmitter.emit("showNotification", "success", "The selected HR specialist account has been successfully deleted.");
            }
        } catch (error) {
            console.log("Fetch error", error);
            EventEmitter.emit("showNotification", "danger", "Failed to delete HR specialist account.");
        }
    };

    openEditHRModal = (hr) => {
        this.setState({
            hrToEdit: hr,
            shouldCreateNewHR: false
        });
    };

    openCreateHRModal = () => {
        this.setState({
            hrToEdit: {
                "name": "",
                "companyIdentifier": this.context.user.id,
                "address": "",
                "email": "",
                "password": "",
                "confirmPassword": "",
                "role": "HR_ROLE"
            },
            shouldCreateNewHR: true
        });
    };

    closeEditHRModal = () => {
        this.setState({ hrToEdit: null });
    };

    removeSearchFilter = () => {
        this.setState({ searchTerm: "" });
    };

    handleSearchInput = (e) => {
        this.setState({ searchTerm: e.target.value });
    };

    saveHR = async (hr) => {
        this.setState({isLoadingSaveHR: true});
        if (this.state.shouldCreateNewHR) {
            try {
                let response = await axios.post(`${ URLS.ACCOUNTS }/users/hrs`, hr);
                if (response.status === 201) {
                    this.getHRs();
                    EventEmitter.emit("showNotification", "success", "HR specialist account successfully created.");
                    this.closeEditHRModal();
                    this.setState({isLoadingSaveHR: false});
                }
            } catch (error) {
                EventEmitter.emit("showNotification", "danger", error.response.data.message);
                this.setState({isLoadingSaveHR: false});
            }
        } else {
            // edit HR
            console.log("To be implemented");
        }
    };

    showDeleteHRModal = (hrId) => {
        this.setState({ hrToDelete: hrId });
    };

    render() {
        return (
            <div className="container page-container">
                <div className="d-flex align-items-center justify-content-between">
                    <h1 className="page-title">Manage HRs</h1>
                    <Button onClick={ this.openCreateHRModal }>Add HR</Button>
                </div>

                <Card className="simple-card mb-3">
                    <Card.Body className="d-flex align-items-center">
                        <Form>
                            <Form.Control placeholder="Search by name or email..." onChange={ this.handleSearchInput } value={ this.state.searchTerm } />
                        </Form>
                        <Button variant="outline-primary" className="ml-2" hidden={ !this.state.searchTerm } onClick={ this.removeSearchFilter }>Clear filter</Button>
                    </Card.Body>
                </Card>

                <HRList
                    hrs={ this.state.hrs }
                    deleteHR={ this.showDeleteHRModal }
                    openEditHRModal={ this.openEditHRModal }
                    searchTerm={ this.state.searchTerm }
                />

                <CreateHR
                    createNew={ this.state.shouldCreateNewHR }
                    saveHR={ this.saveHR }
                    onHide={ this.closeEditHRModal }
                    hrToEdit={ this.state.hrToEdit }
                    isLoadingSaveHR={this.state.isLoadingSaveHR}
                />

                <NotificationModal
                    title="Delete HR account"
                    content="Are you sure you want to delete the selected HR specialist account?"
                    confirmAction={ this.deleteHR }
                    show={ this.state.hrToDelete !== null }
                    onHide={ () => { this.showDeleteHRModal(null) } }
                />
            </div>
        );
    }
}

ManageHR.contextType = AppContext;
export default ManageHR;
