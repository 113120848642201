import * as React from 'react';
import { Button, Card, Form } from "react-bootstrap";
import { AppContext } from "./AppContext";

class Filter extends React.Component {
    state = {
        selectedStatus: '',
        searchKeywords: '',
        location: '',
        selectedAppliedMode: 2,
        crtAppliedMode: 2,
        candidateStatus: '',
        skill: '',
        jobs:{},
        jobSelected:{}
    };

    // componentDidMount() {
    //     if (this.props.entity === "jobOpenings") {
    //         this.props.search(this.state.selectedStatus, this.state.searchKeywords, this.state.location, this.state.selectedAppliedMode);
    //     }
    // }

    removeSearchFilter = () => {
        let updater = {
            selectedStatus: "",
            searchKeywords: "",
            location: "",
            selectedAppliedMode: 2,
            crtAppliedMode: 2,
            candidateStatus: "",
            skill: ""
        };

        if (this.props.entity === "jobOpenings") {
            this.setState(updater, () => {
                this.props.updateState(updater, this.props.search);
            });
            
        } else {
            this.setState(updater, () => {
                this.props.updateState(updater, this.props.updateFilter);
            });
        }
    };

    handleChange = (e) => {
        if (e.target.name === "selectedAppliedMode") {
            this.setState({ selectedAppliedMode: parseInt(e.target.value, 10) });
            this.props.updateState({ selectedAppliedMode: parseInt(e.target.value, 10) });
        } else
            {
                this.setState({ [e.target.name]: e.target.value });
                this.props.updateState({ [e.target.name]: e.target.value });
            }
    };

    render() {
        let isCandidate = this.context.role === "CANDIDATE_ROLE";
        let isHr = this.context.role === "HR_ROLE";
        let isCompany = this.context.role === "COMPANY_ROLE";
        // let jobOpenings = this.props.entity === "jobOpenings";
        let candidates = this.props.entity === "candidates";
        // let jobOptions=[];
        // {
        //     if (this.props.jobs && this.props.jobs.length > 0) {
        //         console.log("joburile din filter ",this.props.jobs);
        //         // for (const [index,job] of this.props.jobs){
        //         //     console.log(index,job)
        //         // }
        //         this.props.jobs.forEach((job,index)=>{
        //             jobOptions.push(<option value={index}>{job.postingTitle}</option>)
        //         })
        //     }
        // }
        return (
            <div>
                <Card className="simple-card mb-4">
                    <Card.Body className="d-flex align-items-center py-3">
                        <Form className="d-flex" onSubmit={ this.handleSubmit } onChange={ this.handleChange }>
                            <Form.Control className="mr-2" name="searchKeywords" type="text"
                                onChange={ this.handleChange } placeholder="Job title, type or description"
                                hidden={ candidates }
                                value={ this.state.searchKeywords } />
                            <Form.Control className="mr-2" name="location" type="text" onChange={ this.handleChange }
                                hidden={ candidates }
                                placeholder="Location" value={ this.state.location } />
                            <Form.Control className="mr-2" name="selectedStatus" as="select"
                                hidden={ !(isHr || isCompany) || candidates } onChange={ this.handleChange }
                                value={ this.state.selectedStatus }>
                                <option value="">Any availability</option>
                                <option value="enabled">Available</option>
                                <option value="disabled">Unavailable</option>
                            </Form.Control>

                            <Form.Control className="mr-2" name="selectedAppliedMode" as="select"
                                hidden={ !isCandidate || candidates }
                                onChange={ this.handleChange } value={ this.state.selectedAppliedMode }>
                                <option value="2">All jobs</option>
                                <option value="1">Applied</option>
                                <option value="0">Not applied</option>
                            </Form.Control>

                            {/*<Form.Control className="mr-2" name="jobSelected" as="select"*/}
                            {/*              hidden={ !candidates } onChange={ this.handleChange }*/}
                            {/*              value={ this.state.jobSelected }>*/}
                            {/*    <option value="">Select Job</option>*/}
                            {/*    {jobOptions}*/}
                            {/*</Form.Control>*/}
                            {/*<Form.Control placeholder="Filter by skill" name="skill" className="mr-2"*/}
                            {/*    value={ this.state.skill }*/}
                            {/*    type="text" hidden={ !candidates } onChange={ this.handleChange } />*/}
                            {/*<Form.Control className="mr-2" name="candidateStatus" as="select"*/}
                            {/*    hidden={ !candidates } onChange={ this.handleChange }*/}
                            {/*    value={ this.state.candidateStatus }>*/}
                            {/*    <option value="">Any status</option>*/}
                            {/*    <option value="APPLIED">Applied</option>*/}
                            {/*    <option value="PENDING">Pending</option>*/}
                            {/*    <option value="SCHEDULED">Scheduled</option>*/}
                            {/*    <option value="REFUSED">Refused</option>*/}
                            {/*    <option value="REJECTED">Rejected</option>*/}
                            {/*    <option value="ACCEPTED">Accepted</option>*/}
                            {/*    <option value="RECHECK">Re-check</option>*/}
                            {/*</Form.Control>*/}


                        </Form>

                        <Button className="mr-2" onClick={ this.props.search } hidden={ candidates }>Search</Button>
                        <Button className="mr-2" variant="outline-primary" onClick={ this.removeSearchFilter }
                            hidden={ !this.props.filterApplied }>Remove filter</Button>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

Filter.contextType = AppContext;
export default Filter;
