import React from "react";
import { Form, Button, Modal } from "react-bootstrap";

class InterviewNotesModal extends React.Component {
    state = {
        notes: (this.props.notes) ? this.props.notes : "",
        changed: false
    };

    componentDidUpdate() {
        if (!this.state.changed && this.props.notes && this.props.notes !== this.state.notes) {
            this.setState({ notes: this.props.notes });
        }
    }

    onNotesChanged = (e) => {
        this.setState({
            notes: e.target.value,
            changed: true
        });
    };

    saveNotes = () => {
        this.setState({ changed: false });
        this.props.onSaveNotes(this.state.notes);
    };

    onClose = () => {
        this.setState({ changed: false });
        this.props.onHide();
    };

    render() {
        let saveButton = null;
        if (this.props.onSaveNotes) {
            saveButton = (<Button onClick={ this.saveNotes }>Save</Button>);
        }

        let notesContent;
        if (this.props.onSaveNotes) {
            notesContent = (
                <Form.Group>
                    <Form.Control as="textarea" name="notes" type="text" defaultValue={ this.state.notes } onChange={ this.onNotesChanged } rows="10"></Form.Control>
                </Form.Group>
            );
        } else {
            notesContent = (<div>{ this.state.notes }</div>);
        }

        return (
            <Modal size="lg" show={ this.props.show } onHide={ this.onClose }>
                <Modal.Header closeButton>
                    <Modal.Title>Interview notes</Modal.Title>
                </Modal.Header>

                <Modal.Body>{ notesContent }</Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={ this.onClose }>Close</Button>
                    { saveButton }
                </Modal.Footer>
            </Modal>
        );
    }
}

export default InterviewNotesModal;
