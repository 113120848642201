import React from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Row, Col, Card, Form } from "react-bootstrap";
import EditAvatar from "../Utils/EditAvatar";
import { AppContext } from "../Utils/AppContext";
import { URLS } from "../Utils/Constants";
import { EventEmitter } from "../Utils/EventEmitter";
import { Formik, Field, ErrorMessage } from 'formik';
import { Form as FormFormik } from 'formik';
import * as Yup from 'yup';
import LoadingButton from "../Utils/LoadingButton";
import { withRouter } from "react-router-dom";

const ChangePasswordSchema = Yup.object().shape({
    password: Yup.string()
        .required("Password is required"),
    newPassword: Yup.string()
        .matches(/((?=.*[a-z])(?=.*\d)(?=.*[A-Z]).{8,20})/, "The password must contain at least 8 characters, of which one number, one uppercase and one lowercase letter.")
        .required("Password is required"),
    newPasswordConfirm: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], "Please make sure the new password and the confirmation match.")
        .required("Password confirmation is required")
});

const ResetPassword = Yup.object().shape({
    newPassword: Yup.string()
        .matches(/((?=.*[a-z])(?=.*\d)(?=.*[A-Z]).{8,20})/, "The password must contain at least 8 characters, of which one number, one uppercase and one lowercase letter.")
        .required("Password is required"),
    newPasswordConfirm: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], "Please make sure the new password and the confirmation match.")
        .required("Password confirmation is required")
});

class AccountSettings extends React.Component {
    state = {
        name: "",
        avatar: "",
        nameFormValidated: false,
        passwordFormValidated: false,
        isLoadingUpdatePassword: false,
        isLoadingUpdateName: false
    };

    componentDidMount() {
        this.setState({ name: this.context.crtUserName });
        if (this.context.role === "COMPANY_ROLE") {
            this.setState({ avatar: this.context.user.avatar });
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    changePassword = async (fields, {resetForm}) => {
        this.setState({ passwordFormValidated: false, isLoadingUpdatePassword: true });
        try {
            if (this.props.forgotPassword) {
                let url = new URL(window.location.href);
                let email = url.searchParams.get("email");
                let token = url.searchParams.get("token");
                if (email && token) {
                    let data = {
                        "newPassword": fields.newPassword,
                        "confirmNewPassword": fields.newPasswordConfirm
                    }
                    let response = await axios.put(`${URLS.ACCOUNTS}/users/forgot_password/validation?email=${email}&token=${token}`, data);
                    if (response.status === 200) {
                        EventEmitter.emit("showNotification", "success", "Your password has been successfully changed.");
                        this.setState({ isLoadingUpdatePassword: false });
                        setTimeout(() => { this.props.history.push({ pathname: "/login" }) }, 1000);
                    }
                }

            } else {
                let data = {
                    password: fields.password,
                    newPassword: fields.newPassword
                };
                let response = await axios.put(`${URLS.ACCOUNTS}/users/change_password`, data, { "Authorization": "Bearer " + this.context.token });
                if (response.status === 200) {
                    EventEmitter.emit("showNotification", "success", "Your password has been successfully changed.");
                    this.setState({ isLoadingUpdatePassword: false });
                    resetForm();
                }
            }
        } catch (error) {
            EventEmitter.emit("showNotification", "danger", "Failed to change password.");
            this.setState({ isLoadingUpdatePassword: false });
        }
    };

    changeName = async (e) => {
        this.setState({ isLoadingUpdateName: true });
        e.preventDefault();
        if (e.target.checkValidity()) {
            this.setState({ nameFormValidated: false });
            try {
                let response = await axios.put(`${URLS.ACCOUNTS}/users/name`, { name: this.state.name }, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${this.context.token}`
                    }
                });
                if (response.status === 200) {
                    this.updateCookies();
                    EventEmitter.emit("showNotification", "success", "Name updated successfully.");
                    this.setState({ isLoadingUpdateName: false });
                }
            } catch (error) {
                EventEmitter.emit("showNotification", "danger", "Failed to update name.");
                this.setState({ isLoadingUpdateName: false });
            }
        } else {
            EventEmitter.emit("showNotification", "warning", "The entered data seems to be incorrect or incomplete. Please try again.");
            this.setState({ nameFormValidated: true, isLoadingUpdateName: true });
        }
    };

    changeAvatar = async (file) => {
        let formdata = new FormData();
        formdata.append("file", file);
        try {
            let response = await axios.put(`${URLS.ACCOUNTS}/users/avatars`, formdata, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.context.token}`
                }
            });
            if (response.status === 200) {
                this.setState({ avatar: response.data.avatar });
                let user = JSON.parse(Cookies.get("user"));
                user.avatar = this.state.avatar;
                this.context.update({ user: user });
                EventEmitter.emit("showNotification", "success", "Logo updated successfully.");
            }
        } catch (error) {
            EventEmitter.emit("showNotification", "danger", "Failed to update logo.");
        }
    };

    updateCookies = () => {
        let user = JSON.parse(Cookies.get("user"));
        user.name = this.state.name;
        Cookies.set("user", user);
        Cookies.set("crtUserName", this.state.name);
        this.context.update({
            crtUserName: this.state.name,
            user: user
        });
    };

    render() {
        let isHR = this.context.role === "HR_ROLE";
        let isCompany = this.context.role === "COMPANY_ROLE";
        let cardTitle = "Reset password";
        let changeLogoForm = null;
        let changeNameForm = null;

        if (!this.props.forgotPassword) {
            if (isHR || isCompany) {
                cardTitle = "Account settings";
                changeNameForm = (
                    <React.Fragment>
                        <h3>Change name</h3>
                        <Form className="d-flex flex-column" onSubmit={this.changeName} noValidate validated={this.state.nameFormValidated}>
                            <Form.Group>
                                <Form.Label>New name</Form.Label>
                                <Form.Control
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                    required
                                    minLength="3"
                                    autoComplete="new-name"
                                />
                            </Form.Group>

                            <div className="d-flex flex-column align-items-end">
                                <LoadingButton type={"submit"} text={"Update name"} isLoading={this.state.isLoadingUpdateName} />
                            </div>
                        </Form>
                    </React.Fragment>
                );

                if (isCompany) {
                    changeLogoForm = (
                        <div className="mb-4">
                            <h3>Change logo</h3>
                            <div className="mb-3">The new logo will be saved automatically.</div>
                            <EditAvatar
                                name={this.state.name}
                                img={this.state.avatar}
                                updateAvatar={this.changeAvatar}
                            />
                        </div>
                    );
                }

            } else {
                cardTitle = "Change password";
            }
        }

        return (

            <div className="container page-container">
                <Row className="justify-content-center">
                    <Col xl={5} lg={8} md={10} xs={11}>
                        <Card className="simple-card">
                            <Card.Body>
                                <h1 className="large-card-title">{cardTitle}</h1>

                                {changeLogoForm}
                                {changeNameForm}

                                <h3 hidden={!isCompany && !isHR}>Change password</h3>
                                <div className="d-flex flex-column"  >
                                    <Formik
                                        initialValues={{ password: "", newPassword: "", newPasswordConfirm: "" }}
                                        validationSchema={this.props.forgotPassword ? ResetPassword : ChangePasswordSchema}
                                        onSubmit={(fields, {resetForm}) => { this.changePassword(fields, {resetForm});  }}
                                        render={({ touched, errors }) => (
                                            <FormFormik>
                                                {!this.props.forgotPassword && (
                                                    <div className="form-group">
                                                        <label htmlFor="password">Current password</label>
                                                        <Field
                                                            type="password"
                                                            name="password"
                                                            className={`form-control ${touched.password && errors.password ? "is-invalid" : ""}`}
                                                        />
                                                        <ErrorMessage
                                                            component="div"
                                                            name="password"
                                                            className="invalid-feedback"
                                                        />
                                                    </div>)
                                                }

                                                <div className="form-group">
                                                    <label htmlFor="newPassword">New password</label>
                                                    <Field
                                                        type="password"
                                                        name="newPassword"
                                                        className={`form-control ${touched.newPassword && errors.newPassword ? "is-invalid" : ""}`}
                                                    />
                                                    <ErrorMessage
                                                        component="div"
                                                        name="newPassword"
                                                        className="invalid-feedback"
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="newPasswordConfirm">Confirm password</label>
                                                    <Field
                                                        type="password"
                                                        name="newPasswordConfirm"
                                                        className={`form-control ${touched.newPasswordConfirm && errors.newPasswordConfirm ? "is-invalid" : ""}`}
                                                    />
                                                    <ErrorMessage
                                                        component="div"
                                                        name="newPasswordConfirm"
                                                        className="invalid-feedback"
                                                    />
                                                </div>

                                                <Form.Text>The password must contain at least 8 characters, of which one number, one uppercase and one lowercase letter.</Form.Text>
                                                <div className="d-flex flex-column align-items-end"   >
                                                    <LoadingButton type={"submit"} text={"Update password"} isLoading={this.state.isLoadingUpdatePassword} />
                                                </div>
                                            </FormFormik>
                                        )}
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

AccountSettings.contextType = AppContext;
export default withRouter(AccountSettings);