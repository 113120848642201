import * as React from "react";
import {Button, Card, Form, Modal} from "react-bootstrap";

class ResumeCriteria extends React.Component{
    state = {
        isLoadingBestCandidates : false,
        criteriaToEdit : this.props.criteriaToEdit
    };

    shouldDisplayModal = () => {
        return (this.props.criteriaToEdit) ? true : false;
    };

    onHide = () => {
        this.props.closeModal();
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.findCandidates();
        this.onHide();
    };

    render() {
        let skillsInput = null;
        let jobInfo = null;

        if (this.props.shouldDisplayCriteriaModal && this.props.job && this.props.criteriaToEdit) {
            let skillsList = this.props.criteriaToEdit.skills.map((skill, index) => (
                <Card className="simple-card mb-3" key={index}>
                    <Card.Body className="d-flex align-items-center py-3">
                        <Form.Control
                            name="skill"
                            data-id={index}
                            value={skill}
                            onChange={this.props.handleChange}
                            className="flex-1 skill-field"
                            required
                        />
                        <Button variant="outline-primary" className="ml-2" data-id={index} onClick = {()=>this.props.deleteSkill(skill)}>
                            Delete
                        </Button>
                    </Card.Body>
                </Card>
            ));

            skillsInput = (
                <React.Fragment>
                    <div className="d-flex justify-content-between">
                        <Form.Label>Required skills</Form.Label>
                    </div>
                    <div className="d-flex justify-content-end">
                        <Button variant="link" className="p-0 mb-2" onClick={this.props.addSkill}>Add skill</Button>
                    </div>
                    {skillsList}
                </React.Fragment>
            );

            jobInfo = (
                <div>
                    <Form.Group>
                    <Form.Label>Posting title</Form.Label>
                    <Form.Control name="postingTitle" type="text" onChange={this.props.handleChange} value={this.props.criteriaToEdit.jobTitle} disabled/>
                    <Form.Control.Feedback type="invalid">Please enter a posting title containing at least 3 characters.</Form.Control.Feedback>
                    </Form.Group>
                <Form.Group>
                <div className="d-flex justify-content-between">
                <Form.Label>Required work experience</Form.Label>
                </div>
                <Form.Control as="select" name="yearsExperience" onChange={this.props.handleChange} value={this.props.criteriaToEdit.yearsExperience} >
                    <option value={0}> No experience required</option>
                    <option value={1}> 1+ years experience</option>
                    <option value={2}> 2+ years experience</option>
                    <option value={3}> 3+ years experience</option>
                    <option value={5}> 5+ years experience</option>
                    <option value={10}> 10+ years experience</option>
            </Form.Control>
            </Form.Group>
                </div>)
        }
        return (
            <div>
                <Modal size="lg" show={this.props.shouldDisplayCriteriaModal} onHide={this.onHide} backdrop="static">
                    <Form onSubmit={this.handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Set resume criteria</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {jobInfo}
                            {skillsInput}
                        </Modal.Body>
                        <Modal.Footer className="justify-content-between">
                            <div>
                                <Button className="mr-2" variant="secondary" onClick={this.onHide}>Cancel</Button>
                                <Button className="ml-2" type="submit">Show candidates</Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </div>
        );
    }

}
export default ResumeCriteria;