import {Button, Card} from "react-bootstrap";
import Avatar from "../Utils/Avatar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboard, faTrash} from "@fortawesome/free-solid-svg-icons";
import Rating from "../Utils/Rating";
import React from "react";
import {useContext} from "react";
import {AppContext} from "../Utils/AppContext";
import moment from "moment";
import {URLS} from "../Utils/Constants";
import {withRouter} from "react-router-dom";
import {EventEmitter} from "../Utils/EventEmitter";

const InterviewCard = (props) => {

    const context = useContext(AppContext);
    let isCandidate = context.role === "CANDIDATE_ROLE";
    let interviewCard = null;
    let interviewActionBtn = null;
    let additionalInfo = "";
    let formattedExpDate = moment(props.interview.expiryDate).format("DD/MM/YYYY HH:mm");

    const getHtURL = () => {
        let index = props.interview.participants.map((participant) => {
            return participant.email;
        }).indexOf(context.user.email);
        let token = props.interview.participants[index].access_token;
        let toBeRecorded = props.interview.toBeRecorded;
        return `${URLS.HYPERTALK_MEETING}/#!/attend?token=${token}&email=${context.user.email}&language=en&parentComponent=careers&recording=${toBeRecorded}`;
    };

    const openLiveInterviewRecord = (liveInterview) => {
        let index = liveInterview.participants.map((participant) => {
            return participant.email;
        }).indexOf(context.user.email);
        let token = liveInterview.participants[index].access_token;
        let data=btoa(JSON.stringify({
            name: liveInterview.title,
            id: liveInterview.activity_id,
            email: context.user.email,
            token: token,
            language: 'en'
        }));
        let link=`${URLS.HYPERTALK_PLAYER}`+data;
        window.open(link,'_blank');
    };

    const showCandidateInfo = () => {
        EventEmitter.emit("showCandidateInfoModal", props.candidate, props.interview.job.id, props.interview.candidateStatus, null);
    };

    if (isCandidate) {
        let cardLeftContent = null;
        let actionButton = null;
        let isLiveInterview = props.interview.type === "live";
        let currentTime = new Date();
        // currentTime = new Date(currentTime.setMinutes(currentTime.getMinutes() + new Date().getTimezoneOffset()));
        let interviewStartDate = new Date(props.interview.start_date);
        let endDate = new Date(interviewStartDate);
        endDate.setMinutes(endDate.getMinutes() + props.interview.duration);
        if (isLiveInterview) {
            let formattedStartDate = moment(props.interview.start_date).format("DD/MM/YYYY HH:mm");

            if ((currentTime > interviewStartDate) && (currentTime < endDate)) {

                cardLeftContent = <div className="text-sec2">On going interview</div>;
                actionButton = <Button onClick={() => window.open(getHtURL(), "_blank")}>Join</Button>;

            } else if (currentTime < interviewStartDate) {

                cardLeftContent = <div className="text-sec2">Starts on {formattedStartDate}</div>;
                actionButton = <div className="pending-interview-badge">Pending</div>;

            } else {

                if (currentTime > endDate) {
                    cardLeftContent = <div className="text-sec2">Live interview session ended</div>;
                }

            }

            interviewCard = (
                <Card className="simple-card">
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <Avatar name={props.interview.candidate.name} img={props.candidate.personalInformation.profilePictureUrl}/>
                            <div className="mx-3">
                                <div className="font-weight-bold">{props.interview.candidate.name}</div>
                                <div className="text-sec1">applied
                                    for {props.interview.job.postingTitle} at {props.interview.job.companyName}</div>
                            </div>
                        </div>
                        {actionButton}
                    </Card.Header>
                    <Card.Body className="d-flex align-items-center justify-content-between py-3">
                        {cardLeftContent}
                    </Card.Body>
                </Card>);

        } else {

                if (props.interview.assessments.length > 0 || props.interview.isCompleted === true) { // answered
                    interviewActionBtn =
                        <Button variant="outline-primary" onClick={() => props.viewInterview(props.interview)}>View</Button>;
                    additionalInfo = "You have responded to this interview";

                } else { // pending
                    interviewActionBtn =
                        <Button variant="primary" onClick={() => props.displayModal(true, props.interview)}>Respond</Button>;
                    additionalInfo = `Expires on ${formattedExpDate}`;
                }

                interviewCard = (
                    <Card className={"simple-card"}>
                        <Card.Header className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <Avatar name={props.interview.job.companyName} img={props.interview.job.logo}/>
                                <div className="mx-3">
                                    <div className="font-weight-bold">{props.interview.job.companyName}</div>
                                    <div className="text-sec1">You have applied for {props.interview.job.postingTitle}</div>
                                </div>
                            </div>
                            {interviewActionBtn}
                        </Card.Header>
                        <Card.Body className="text-sec2 py-3">{additionalInfo}</Card.Body>
                    </Card>
                )
                }
    } else {
        let notesButton = null;
        let cardLeftContent = null;
        let actionButton = null;
        let viewButton = null;
        let isLiveInterview = props.interview.type === "live";
        let currentTime = new Date();
        // currentTime = new Date(currentTime.setMinutes(currentTime.getMinutes() + new Date().getTimezoneOffset()));
        let interviewStartDate = new Date(props.interview.start_date);
        let endDate = new Date(interviewStartDate);
        endDate.setMinutes(endDate.getMinutes() + props.interview.duration);

        if (isLiveInterview) {

            let formattedStartDate = moment(props.interview.start_date).format("DD/MM/YYYY HH:mm");

            if ((currentTime > interviewStartDate) && (currentTime < endDate)) {

                cardLeftContent = <div className="text-sec2">On going interview</div>;
                actionButton = <Button onClick={() => window.open(getHtURL(), "_blank")}>Join</Button>;

            } else if (currentTime < interviewStartDate) {

                cardLeftContent = <div className="text-sec2">Starts on {formattedStartDate}</div>;
                actionButton = <div className="pending-interview-badge">Pending</div>;

            } else {
                if (props.interview.toBeRecorded === true)
                    actionButton = <Button className={""} onClick={() => openLiveInterviewRecord(props.interview)}>View</Button>;
                if (currentTime > endDate) {
                    cardLeftContent = <div className="text-sec2">Live interview session ended</div>;
                }

            }


            interviewCard = (
                <Card className="simple-card">
                    <Card.Header className="d-flex align-items-center justify-content-between" style={{cursor:'pointer'}} onClick={showCandidateInfo}>
                        <div className="d-flex align-items-center">
                            <Avatar name={props.interview.candidate.name} img={props.candidate.personalInformation.profilePictureUrl}/>
                            <div className="mx-3">
                                <div className="font-weight-bold">{props.interview.candidate.name}</div>
                                <div className="text-sec1">applied
                                    for {props.interview.job.postingTitle} at {props.interview.job.companyName}</div>
                            </div>
                        </div>
                        {actionButton}
                    </Card.Header>
                    <Card.Body className="d-flex align-items-center justify-content-between py-3">
                        {cardLeftContent}
                        <div>
                            {/*{notesButton}*/}
                            {/*<Button variant="basic" className="btn-ic ml-2"*/}
                            {/*        title="Delete interview" onClick={() => props.deleteInterview(props.interview)}>*/}
                            {/*    <FontAwesomeIcon icon={faTrash}/>*/}
                            {/*</Button>*/}
                        </div>
                    </Card.Body>
                </Card>);

        } else {
            if (props.interview.assessments.length > 0) { // answered
                if (props.interview.notes) { // interview has notes
                    notesButton = (
                        <Button variant="basic" className="btn-ic" title="View interview notes"
                                onClick={() => props.showNotesModal(true, props.interview.notes)}>
                            <FontAwesomeIcon icon={faClipboard}/>
                        </Button>
                    );
                }
                cardLeftContent = <Rating rating={props.interview.rating}/>;
                viewButton = <Button onClick={() => props.viewInterview(props.interview)}>View</Button>;

            } else { //  pending
                cardLeftContent = <div className="text-sec2">Expires on {formattedExpDate}</div>;
                viewButton = <div className="pending-interview-badge">Pending</div>;
            }

            interviewCard = (
                <Card className="simple-card">
                    <Card.Header className="d-flex align-items-center justify-content-between" style={{cursor:'pointer'}} onClick={showCandidateInfo}>
                        <div className="d-flex align-items-center">
                            <Avatar name={props.cndName} img={props.candidate.personalInformation.profilePictureUrl} />
                            <div className="mx-3">
                                <div className="font-weight-bold">{props.cndName}</div>
                                <div className="text-sec1">applied
                                    for {props.interview.job.postingTitle} at {props.interview.job.companyName}</div>
                            </div>
                        </div>
                        {viewButton}
                    </Card.Header>
                    <Card.Body className="d-flex align-items-center justify-content-between py-3">
                        {cardLeftContent}
                        <div>
                            {notesButton}
                            <Button variant="basic" className="btn-ic ml-2"
                                    title="Delete interview" onClick={() => props.deleteInterview(props.interview)}>
                                <FontAwesomeIcon icon={faTrash}/>
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            );
        }
    };

    return (<div>
        {interviewCard}
    </div>)
};

export default withRouter(InterviewCard);