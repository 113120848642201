import React from 'react';
import axios from 'axios';
import Cookies from "js-cookie";
import { Button, Col, Row, Card } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import NotificationModal from "../Utils/NotificationModal";
import { AppContext } from "../Utils/AppContext";
import { URLS, COOKIES_EXP_DAYS } from "../Utils/Constants";
import { EventEmitter } from '../Utils/EventEmitter';
import { LinkContainer } from "react-router-bootstrap";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import LoadingButton from "../Utils/LoadingButton";

class Login extends React.Component {
    state = {
        loginFailedModalVisible: false,
        forgotPasswordVisible: false,
        isLoadingLogin: false,
        isLoadingResetPassword: false
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleLogin = async (fields) => {
        this.setState({
            isLoadingLogin: true
        })
        let loginData = new URLSearchParams();
        loginData.append("username", fields.email);
        loginData.append("password", fields.password);
        loginData.append("grant_type", "password");

        try {
            let loginResponse = await axios.post(`${URLS.ACCOUNTS}/oauth/token`, loginData, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "Basic " + window.btoa("WebClient:Parola123")
                },
            });

            if (loginResponse.status === 200) {
                let userDataResponse = await axios.get(`${URLS.ACCOUNTS}/users/user_info`, {
                    headers: { "Authorization": "Bearer " + loginResponse.data.access_token }
                });

                if (userDataResponse.status === 200) {
                    this.setState({
                        isLoadingLogin: false
                    })
                    Cookies.set("token", loginResponse.data.access_token, { expires: COOKIES_EXP_DAYS });
                    Cookies.set("refresh_token", loginResponse.data.refresh_token, { expires: COOKIES_EXP_DAYS });
                    Cookies.set("role", userDataResponse.data.role, { expires: COOKIES_EXP_DAYS });
                    Cookies.set("userId", userDataResponse.data.id, { expires: COOKIES_EXP_DAYS });
                    Cookies.set("user", userDataResponse.data, { expires: COOKIES_EXP_DAYS });
                    Cookies.set("crtUserName", userDataResponse.data.name);

                    this.context.update({
                        token: loginResponse.data.access_token,
                        refresh_token: loginResponse.data.refresh_token,
                        role: userDataResponse.data.role,
                        userId: userDataResponse.data.id,
                        user: userDataResponse.data,
                        crtUserName: userDataResponse.data.name
                    });

                    this.goToLastLocation();
                } else {
                    this.setState({
                        isLoadingLogin: false
                    })
                    EventEmitter.emit("showNotification", "danger", "User data not found.");
                }
            }
        } catch (error) {
            this.setState({
                isLoadingLogin: false
            })
            if (error.response.status === 400 || error.response.status === 401) {
                EventEmitter.emit("showNotification", "danger", error.response.data.error_description);
            } else {
                EventEmitter.emit("showNotification", "danger", "Log in failed. Please try again.");
            }
        }
    };

    goToLastLocation = () => {
        if (this.props.location.state && this.props.location.state.from) {
            this.props.history.push({ pathname: this.props.location.state.from.pathname });
        } else {
            if (this.context.isIframe) {
                this.props.history.push({ pathname: "/jobOpenings" });
            } else {
                this.props.history.push({ pathname: "/home" });
            }
        }
    }

    handleForgotPassword = async (fields) => {
        this.setState({
            isLoadingResetPassword: true
        })
        try {
            let response = await axios.put(`${URLS.ACCOUNTS}/users/forgot_password?domain=${window.location.origin}`, { email: fields.email });
            if (response.status === 200) {
                EventEmitter.emit("showNotification", "success", "A message containing password reset instructions has been sent to the given email address.");
                this.setState({
                    isLoadingResetPassword: false
                })
            }
        } catch (error) {
            this.setState({
                isLoadingResetPassword: false
            })
            EventEmitter.emit("showNotification", "danger", "Password reset email failed to send. Please try again.");
        }
    };

    renderForm = () => {
        if (this.state.forgotPasswordVisible) { // forgot password form
            return (
                <Formik
                    initialValues={{ email: "", password: "" }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string()
                            .email("Invalid email address format")
                            .required("Email is required")
                    })}
                    onSubmit={fields => {this.handleForgotPassword(fields);}}

                    render={({ touched, errors }) => (
                        <Form>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <Field
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    className={`form-control ${
                                        touched.email && errors.email ? "is-invalid" : "" }`}
                                />
                                <ErrorMessage
                                    component="div"
                                    name="email"
                                    className="invalid-feedback" />
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <Button variant="link" className="pl-0" onClick={() => this.showForgotPassword(false)}>Log in</Button>
                                {/* <Button variant="dark" type="submit">Reset password</Button> */}
                                <LoadingButton variant={"dark"} type={"submit"} text={"Reset password"} isLoading={this.state.isLoadingResetPassword}></LoadingButton>
                            </div>
                        </Form>
                    )}
                />
            )

        } else { // log in form
            return (

                <Formik
                    initialValues={{ email: "", password: "" }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string()
                            .email("Invalid email address format")
                            .required("Email is required"),
                        password: Yup.string()
                            .required("Password is required")
                    })}
                    onSubmit={fields => {this.handleLogin(fields); } }


                    render={({ touched, errors }) => (
                        <Form>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <Field
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    className={`form-control ${
                                        touched.email && errors.email ? "is-invalid" : ""
                                        }`}
                                />
                                <ErrorMessage
                                    component="div"
                                    name="email"
                                    className="invalid-feedback" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <Field
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    className={`form-control ${
                                        touched.password && errors.password ? "is-invalid" : ""
                                        }`}
                                />
                                <ErrorMessage
                                    component="div"
                                    name="password"
                                    className="invalid-feedback" />
                            </div>

                            <div className="d-flex align-items-end justify-content-between">
                                <div>
                                    <Button variant="link" className="pl-0" onClick={() => this.showForgotPassword(true)}>Forgot password</Button>
                                    <LinkContainer to={"/register"} className="d-block">
                                        <a className="pl-0" >Don't have an account?</a>
                                    </LinkContainer>
                                </div>
                                <LoadingButton variant={"dark"} type={"submit"} text={"Log in"} isLoading={this.state.isLoadingLogin}></LoadingButton>
                            </div>
                        </Form>
                    )}
                />
            );
        }
    };

    showLoginFailedModal = (status) => {
        this.setState({ loginFailedModalVisible: status });
    };

    showForgotPassword = (status) => {
        this.setState({ forgotPasswordVisible: status });
    };



    render() {
        let title = (this.state.forgotPasswordVisible) ? "Forgot password" : "Log in";
        return (
            <React.Fragment>
                <div className="bg-container"></div>
                <div className="container page-container">
                    <Row className="justify-content-center">
                        <Col xl={5} lg={8} md={10} xs={11}>
                            <Card className="simple-card transp-card">
                                <Card.Body>
                                    <h1 className="large-card-title">{title}</h1>
                                    {this.renderForm()}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <NotificationModal
                        title="Log in failed"
                        content="The provided credentials are not correct. Please try again."
                        show={this.state.loginFailedModalVisible}
                        onHide={() => { this.showLoginFailedModal(false) }}
                    />
                </div>
            </React.Fragment>
        );
    }
}

Login.contextType = AppContext;
export default withRouter(Login);
