import React from "react";
import axios from "axios";
import Dropzone from "react-dropzone";
import { Button, Card, Modal, ProgressBar } from "react-bootstrap";
import { AppContext } from "../Utils/AppContext";
import { URLS } from "../Utils/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import "./UploadCV.css";
import { EventEmitter } from "../Utils/EventEmitter";
import LoadingButton from "../Utils/LoadingButton";


class UploadCV extends React.Component {
    state = {
        acceptedFile: null,
        uploadInProgress: false,
        uploadPercent: 0,
        show: false,
        isLoadingUploadCV: false
    };

    componentDidMount() {
        EventEmitter.on("showUploadCVModal", () => {
            this.setState({ show: true });
        });
    }

    closeModal = () => {
        this.setState({ show: false });
    };

    onDrop = (droppedFiles) => {
        this.setState({ acceptedFile: droppedFiles[0] });
    };

    uploadCV = async () => {
        this.setState({
            uploadInProgress: true,
            uploadPercent: 0,
            isLoadingUploadCV: true
        });
        let formdata = new FormData();
        formdata.append("file", this.state.acceptedFile);

        try {
            let response = await axios.post(`${URLS.PROFILES}/uploadPdf`, formdata, {
                contentType: false,
                headers: { "Authorization": "Bearer " + this.context.token },
                onUploadProgress: (progressEvent) => {
                    if (progressEvent.lengthComputable) {
                        this.setState({ uploadPercent: progressEvent.loaded / progressEvent.total * 100 });
                    }
                }
            });

            if (response.status === 200) {
                EventEmitter.emit("showNotification", "success", "Your CV has been uploaded and your new profile has been successfully saved.");
                this.closeModal();
                this.setState({ uploadInProgress: false });
                this.props.refreshProfile();

            } else {
                if (response.status === 400) {
                    EventEmitter.emit("showNotification", "danger", "The uploaded CV must respect the Europass format.");
                } else {
                    EventEmitter.emit("showNotification", "danger", "CV upload failed.");
                }
                this.setState({ uploadInProgress: false });
            }
            this.setState({ acceptedFile: null, isLoadingUploadCV: false });

        } catch (error) {
            this.setState({
                authError: `Fetch error ${error}`,
                acceptedFile: null,
                uploadInProgress: false,
                isLoadingUploadCV: false
            });

            if (error.response.status === 400) {
                EventEmitter.emit("showNotification", "danger", "The uploaded CV must respect the Europass format.");
            }
        }
    };

    render() {
        let acceptedFile = null;
        let uploadBtn = null;
        if (this.state.acceptedFile) {
            let fileMessage = (this.state.uploadInProgress) ? "Uploading" : "Ready to upload";
            acceptedFile = (
                <Card className="simple-card my-3">
                    <Card.Body className="file-card py-2">
                        <div className="text-sec2">{fileMessage}</div>
                        <div className="d-flex align-items-center">
                            <FontAwesomeIcon icon={faFile} className="file-icon mr-2 text-sec1" />
                            <div>{this.state.acceptedFile.name}</div>
                        </div>
                    </Card.Body>
                </Card>
            );
            uploadBtn = <LoadingButton className="align-self-end" onClick={() => this.uploadCV()}
                text={"Upload CV"} isLoading={this.state.isLoadingUploadCV} />
        }

        let progressBarOverlay = null;
        if (this.state.uploadInProgress) {
            progressBarOverlay = (
                <div className="upload-progress-bar-overlay">
                    <ProgressBar className="mx-3" striped animated now={this.state.uploadPercent} />
                </div>
            );
        }
        const uploadCVBoxText= <p>Drop the CV file here or click to upload. The uploaded file should be in <b>PDF</b> format and should respect the <b>Europass template</b>.</p>
        return (
            <Modal show={this.state.show} onHide={this.closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload CV</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex flex-column">
                    <div className="position-relative">
                        <Dropzone onDrop={this.onDrop} multiple={false} accept="application/pdf">
                            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                <div {...getRootProps()} className="dropzone-wrapper">
                                    <input {...getInputProps()} />
                                    {!isDragActive && !this.state.acceptedFile && uploadCVBoxText}
                                    {!isDragActive && this.state.acceptedFile && !this.state.uploadInProgress && "You can upload a different file by dropping it or clicking here"}
                                    {isDragActive && !isDragReject && "Drop your CV here"}
                                    {isDragReject && "File type not supported. Please ensure you are uploading a single PDF file."}
                                </div>
                            )}
                        </Dropzone>
                        {progressBarOverlay}
                    </div>

                    <div className="mt-3 text-sec1">Uploading your CV will automatically fill your profile with the data on your CV and will automatically save your profile.</div>
                    {acceptedFile}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeModal}>Close</Button>
                    {uploadBtn}
                </Modal.Footer>
            </Modal>
        );
    }
}

UploadCV.contextType = AppContext;
export default UploadCV;
