import React from "react";
import {Card, Form, Button} from "react-bootstrap";
import {SkillCategories} from "../Utils/Constants";

class ProfileSkillCard extends React.Component {
    addSkill = () => {
        this.setState(prevState => {
            let profile = JSON.parse(JSON.stringify(prevState.profile));
            profile.skills.push({skillName: ""});
            return {profile: profile};
        });
    };

    render() {
        let category=this.props.category;
        let skills = this.props.skills;
        let skillsList = skills.map((skill, index) => (
            <div className="d-flex py-2 align-items-center" key={index}>
                <Form.Control
                    name="skillName"
                    data-id={index}
                    data-category={category}
                    value={skill}
                    as="textarea"
                    rows='1'
                    role="textbox"
                    onChange={this.props.onChange}
                    className=" skill-field "
                    required
                />
                <Button variant="outline-primary" className="ml-2" data-id={index}
                        onClick={(e) => this.props.delete(e, category)}>
                    Delete
                </Button>
            </div>
        ));
        return (
            <Card className="simple-card mb-3">
                <Card.Body className="d-flex flex-column py-3">
                    <div className="d-flex align-items-center justify-content-between">
                        <p>{SkillCategories[category]} skills:</p>
                        <Button onClick={() => this.props.addSkill(category)}>Add skill</Button>
                    </div>
                    {skillsList}
                </Card.Body>
            </Card>
        );
    }
}

export default ProfileSkillCard;
