import React from 'react';
import {Modal, Button} from 'react-bootstrap';
import { faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class InterviewInfoModal extends React.Component{

    componentDidMount(){
    }
    render() {
        let modalTitle = "Attention";
        return(
            <Modal size="lg" show={this.props.show} onHide={this.props.onHide}>
               <Modal.Header>
                   <Modal.Title><FontAwesomeIcon icon={faInfoCircle} className=" mr-2 text-warning"  />{modalTitle}
                   </Modal.Title>
               </Modal.Header>
               <Modal.Body>
                   Please keep in mind that once you leave this interview you will not be able to finish it.
                       <br/> There is a response time for every question and you cannot go back to a previous question.
               </Modal.Body>
               <Modal.Footer className="justify-content-end">
                    <Button className="ml-2 mr-2" variant="secondary" onClick={this.props.onHide}>Cancel</Button>
                    <Button onClick={() => this.props.respondToInterview(this.props.interview)}> Proceed </Button>
               </Modal.Footer>
            </Modal>
        )
    }
}
export default InterviewInfoModal;