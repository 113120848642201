import React from "react";
import Cookies from "js-cookie";

export const AppContext = React.createContext();

export class AppContextProvider extends React.Component {
    constructor(props) {
        super(props);

        let token = Cookies.get("token");
        let refresh_token = Cookies.get("refresh_token");
        let role = Cookies.get("role");
        let userId = parseInt(Cookies.get("userId"), 10);
        let crtUserName = Cookies.get("crtUserName");
        let user = Cookies.get("user");
        let shortlists = localStorage.getItem("shortlists");

        if (!shortlists) {
            shortlists = {};
        } else {
            shortlists = JSON.parse(shortlists);
        }

        let newState = {
            isIframe: this.checkIfIframe(),
            update: (updater) => { this.setState(updater) }
        };

        if (token && refresh_token && role && userId && crtUserName && user) {
            newState.token = token;
            newState.refresh_token = refresh_token;
            newState.role = role;
            newState.userId = userId;
            newState.crtUserName = crtUserName;
            newState.user = JSON.parse(user);
            newState.shortlists = shortlists;
        } 

        this.state = newState;
    }

    checkIfIframe = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    };

    render() {
        return (
            <AppContext.Provider value={ this.state }>
                { this.props.children }
            </AppContext.Provider>
        );
    }
}
