import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import { AppContext } from "../Utils/AppContext";
import Rating from "../Utils/Rating";
import InterviewTextAnswerForm from "./InterviewTextAnswerForm";
import InterviewMultipleAnswerForm from "./InterviewMultipleAnswerForm";
import InterviewRecordedAnswerForm from "./InterviewRecordedAnswerForm";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import "./RecordedInterview.css";
import { URLS } from "../Utils/Constants";
import QuestionTimer from "./QuestionTimer";
import { EventEmitter } from "../Utils/EventEmitter";
import InterviewNotesModal from "./InterviewNotesModal";
import LoadingButton from "../Utils/LoadingButton";
import NavigationPrompt from "react-router-navigation-prompt";
import Modal from "react-bootstrap/Modal";
import ReactQuill from "react-quill";
import {styleEditorShow} from "../Utils/EditorShow.css";
import 'react-quill/dist/quill.snow.css';
import JobOpeningCard from "../JobOpenings/JobOpeningCard"; // ES6


class RecordedInterview extends React.Component {
    state = {
        interview: null,
        candidate: null,
        crtAsmInd: (new URL(window.location.href).searchParams.get("crtAsmInd")) ? parseInt(new URL(window.location.href).searchParams.get("crtAsmInd")) : 0 ,
        crtQnInd: (new URL(window.location.href).searchParams.get("crtQnInd")) ? parseInt(new URL(window.location.href).searchParams.get("crtQnInd")) : 0,
        shouldDisplayNotesModal: false,
        timerExpired: false,
        isLoadingSubmit: false,
        shouldBlockNavigation : true
    };

    constructor(props){
        super(props);
        this.navigationPromptRef = React.createRef();
    }

    componentDidMount() {
        this.getInterview(() => {
            // if (this.props.respond && !this.state.htCredentials && this.interviewContainsRecordedQuestion()) {
            //     this.createHtSession();
            // }
            if (!this.props.respond) {
                this.getCandidate();
            }
        });

        EventEmitter.on("recordedInterviewQuestionTimeExpired", () => {
            this.setState({ timerExpired: true });
        });

    };

    getInterview = async (callback) => {
        let interviewId = new URL(window.location.href).searchParams.get("id");
        try {
            let response = await axios.get(`${ URLS.JOBS }/interview/${ interviewId }`, {
                headers: { "Authorization": `Bearer ${ this.context.token }` }
            });
            if (response.status === 200) {
                // if (this.props.respond && response.data.isCompleted){
                //     this.props.history.push("/interviews");
                // }else{
                    this.setState({ interview: response.data }, callback);
                    if (this.props.respond)
                        this.changeInterviewStatus(interviewId);
                // }
            }
        } catch (e) {
            EventEmitter.emit("showNotification", "danger", "Failed to load job interview.");
        }
    };

    changeInterviewStatus = async (interviewId) => {
            try{
                let response = await axios.put(`${ URLS.JOBS }/interview/${ interviewId }?isCompleted=true`,{
                    headers: {  "Content-Type": "application/json","Authorization": `Bearer ${ this.context.token }` }
                });
                if (response.status === 200){

                }
            }catch (e) {
                EventEmitter.emit("showNotification", "danger", "Failed to load job interview.");
            }
    };

    getCandidate = async () => {
        try {
            let response = await axios.get(`${ URLS.PROFILES }/resumes/${ this.state.interview.candidateId }`, {
                headers: { "Authorization": `Bearer ${ this.context.token }` }
            });
            if (response.status === 200) {
                this.setState({ candidate: response.data[0] });
            } 
        } catch (error) {
            EventEmitter.emit("showNotification", "danger", "Failed to load candidate data.");
        }
    }

    interviewContainsRecordedQuestion = () => {
        for (let assessment of this.state.interview.assessmentsTemplate) {
            for (let question of assessment.questions) {
                if (question.questionType === "recorded") {
                    return true;
                }
            }
        }
        return false;
    };

    createHtSession = async () => {
        let plannerData = {
            "job_id": this.state.interview.job.id,
            "candidate_id": this.context.userId,
            "name": "Recorded interview",
            "duration": 30,
            "start_date": null,
            "description": "Recorded interview",
            "participants": [],
            "type": "recorded"
        };
        try {
            let response = await axios.post(`${ URLS.JOBS }/interview/liveInterview`, plannerData, {
                headers: { "Authorization": `Bearer${ this.context.token }` }
            });
            if (response.status === 201) {
                this.setState({
                    htCredentials: {
                        email: response.data.participants[0].email,
                        token: response.data.participants[0].access_token
                    }
                });
            }
        } catch (error) {
            console.log("Fetch error", error);
        }
    };

    goToPrevQuestion = () => {
        if (this.state.crtQnInd === 0) {
            let assessments = (this.props.respond) ? this.state.interview.assessmentsTemplate : this.state.interview.assessments;
            this.setState({
                crtAsmInd: this.state.crtAsmInd - 1,
                crtQnInd: assessments[this.state.crtAsmInd - 1].questions.length - 1
            },()=>{
                if (this.props.respond){
                    let currentUrlParams = new URLSearchParams(window.location.search);
                    currentUrlParams.set('crtAsmInd', this.state.crtAsmInd);
                    currentUrlParams.set('crtQnInd', this.state.crtQnInd);
                    this.props.history.push(window.location.pathname + "?" + currentUrlParams.toString());
                }
            });
        } else {
            this.setState({ crtQnInd: this.state.crtQnInd - 1 },()=>{
                if (this.props.respond){
                    let currentUrlParams = new URLSearchParams(window.location.search);
                    currentUrlParams.set('crtAsmInd', this.state.crtAsmInd);
                    currentUrlParams.set('crtQnInd', this.state.crtQnInd);
                    this.props.history.push(window.location.pathname + "?" + currentUrlParams.toString());
                }
            });
        }
    };

    goToNextQuestion = () => {
        let assessments = (this.props.respond || this.state.interview.assessments.length <=0) ? this.state.interview.assessmentsTemplate : this.state.interview.assessments;
        if (this.state.crtQnInd + 1 < assessments[this.state.crtAsmInd].questions.length) {
            this.setState({
                crtQnInd: this.state.crtQnInd + 1,
                timerExpired: false
            },()=>{
                if (this.props.respond){
                    if (assessments[this.state.crtAsmInd].questions[this.state.crtQnInd].questionType === "recorded"){
                        console.log("RECORDED QUESTION NEXT");
                        this.createHtSession();
                    }
                    let currentUrlParams = new URLSearchParams(window.location.search);
                    currentUrlParams.set('crtAsmInd', this.state.crtAsmInd);
                    currentUrlParams.set('crtQnInd', this.state.crtQnInd);
                    this.props.history.push(window.location.pathname + "?" + currentUrlParams.toString());
                }
            });

        } else if (this.state.crtAsmInd + 1 < assessments.length) {
            this.setState({
                crtAsmInd: this.state.crtAsmInd + 1,
                crtQnInd: 0,
                timerExpired: false
            },()=>{
                if (this.props.respond){
                    let currentUrlParams = new URLSearchParams(window.location.search);
                    currentUrlParams.set('crtAsmInd', this.state.crtAsmInd);
                    currentUrlParams.set('crtQnInd', this.state.crtQnInd);
                    this.props.history.push(window.location.pathname + "?" + currentUrlParams.toString());
                }
            });
        }
    };

    isFirstQuestion = () => {
        if (this.state.crtAsmInd === 0 && this.state.crtQnInd === 0) {
            console.log("is first question")

            return true;
        }
        return false;
    };

    isLastQuestion = () => {
        let assessments = (this.props.respond || this.state.interview.assessments <= 0 ) ? this.state.interview.assessmentsTemplate : this.state.interview.assessments;
        if (this.state.crtAsmInd === assessments.length - 1 && this.state.crtQnInd === assessments[this.state.crtAsmInd].questions.length - 1) {
            return true;
        }
        return false;
    };

    getQuestionNumber = () => {
        let qnNumber = this.state.crtQnInd + 1;
        if (this.state.crtAsmInd > 0) {
            let assessments = (this.props.respond || this.state.interview.assessments <= 0 ) ? this.state.interview.assessmentsTemplate : this.state.interview.assessments;
            for (let asmInd = 0; asmInd < this.state.crtAsmInd; asmInd++) {
                qnNumber += assessments[asmInd].questions.length;
            }
        }
        return qnNumber;
    };

    getQuestionsCount = () => {
        let assessments = (this.props.respond || this.state.interview.assessments <= 0 ) ? this.state.interview.assessmentsTemplate : this.state.interview.assessments;
        let total = 0;
        for (let assessment of assessments) {
            total += assessment.questions.length;
        }
        return total;
    };

    nextQnBtnVisible = () => {
        if (this.isLastQuestion()) return false;
        // if (this.props.respond) {
        //     let crtQuestion = this.state.interview.assessmentsTemplate[this.state.crtAsmInd].questions[this.state.crtQnInd];
        //     if ((["text", "recorded"].includes(crtQuestion.questionType) && !crtQuestion.response) ||
        //         (["singleChoice", "multipleChoice"].includes(crtQuestion.questionType) && !crtQuestion.selectedAnswers.length)) {
        //         return false;
        //     }
        // }
        return true;
    };

    prevQnBtnVisible = () => {
        if (this.props.respond || this.isFirstQuestion()) {
            return false;
        }
        return true;
    };

    goToInterviewsList = () => {
        // this.props.history.push("/interviews");
         ( this.props.respond ) ? this.props.history.push("/interviews") : this.props.history.goBack();
    };

    updateRating = (newRating) => {
        let updatedInterview = this.state.interview;
        updatedInterview.assessments[this.state.crtAsmInd].questions[this.state.crtQnInd].rating = newRating;
        this.setState({ interview: updatedInterview });
    };

    updateNotes = (notes) => {
        let updatedInterview = this.state.interview;
        updatedInterview.notes = notes;
        this.setState({
            interview: updatedInterview,
            shouldDisplayNotesModal: false
        });
    };

    showNotesModal = (status) => {
        this.setState({ shouldDisplayNotesModal: status });
    };

    onAnswerTextChanged = (e) => {
        let target = e.target;
        this.setState(prevState => {
            let interview = JSON.parse(JSON.stringify(prevState.interview));
            interview.assessmentsTemplate[this.state.crtAsmInd].questions[this.state.crtQnInd].response = target.value;
            return { interview: interview };
        });
    };

    onAnswerVariantSelected = (selectedAnswers) => {
        this.setState(prevState => {
            let interview = JSON.parse(JSON.stringify(prevState.interview));
            interview.assessmentsTemplate[prevState.crtAsmInd].questions[prevState.crtQnInd].selectedAnswers = selectedAnswers;
            return { interview: interview };
        });
    };

    onAnswerRecordingChanged = (url) => {
        this.setState(prevState => {
            let interview = JSON.parse(JSON.stringify(prevState.interview));
            interview.assessmentsTemplate[this.state.crtAsmInd].questions[this.state.crtQnInd].response = url;
            return { interview: interview };
        });
    };

    saveInterview = async () => {
        this.setState({isLoadingSubmit: true});
        this.setState({shouldBlockNavigation : false});
        let interview = this.state.interview;
        interview.job = interview.job.id;
        try {
            let response = await axios.put(`${ URLS.JOBS }/interview/${ this.state.interview.id }/answers`, interview, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.context.token
                }
            });
            if (response.status === 200) {
                this.goToInterviewsList();
            }
            this.setState({isLoadingSubmit: false});
        } catch (error) {
            this.setState({ authError: `Fetch error ${ error }` , isLoadingSubmit: false});
        }
    }

    render() {
        if (this.state.interview) {
            let crtQuestion;

            if (this.props.respond || this.state.interview.assessments.length <= 0 ) {
                crtQuestion = this.state.interview.assessmentsTemplate[this.state.crtAsmInd].questions[this.state.crtQnInd];
            } else {
                crtQuestion = this.state.interview.assessments[this.state.crtAsmInd].questions[this.state.crtQnInd];
            }
            let isHR = this.context.role === "HR_ROLE";

            let questionCardFooter = null;
            let addNotesBtn = null;
            let addNotesModal = null;
            let saveBtn = null;
            let questionCardAnswer = null;

            let newResponseTime = crtQuestion.responseTime * 60;

            let headerLeftContent = (
                <div>
                    <h3>{ this.state.interview.job.companyName }</h3>
                    Applying for <strong>{ this.state.interview.job.postingTitle }</strong>
                </div>
            );

            let timer = null;
            if (this.props.respond && crtQuestion.questionType !== "recorded") {

                timer = <QuestionTimer time={ newResponseTime } count questionId={ crtQuestion.id } />
            }

            if (crtQuestion.questionType === "text") {
                questionCardAnswer = <InterviewTextAnswerForm
                    respond={ this.props.respond }
                    crtQuestion={ crtQuestion }
                    onAnswerChanged={ this.onAnswerTextChanged }
                    timerExpired={ this.state.timerExpired }
                />

            } else if (["singleChoice", "multipleChoice"].includes(crtQuestion.questionType)) {
                questionCardAnswer = <InterviewMultipleAnswerForm
                    respond={ this.props.respond }
                    crtQuestion={ crtQuestion }
                    onAnswerChanged={ this.onAnswerVariantSelected }
                    timerExpired={ this.state.timerExpired }
                />

            } else if (crtQuestion.questionType === "recorded") {

                questionCardAnswer = <InterviewRecordedAnswerForm
                    respond={ this.props.respond }
                    crtQuestion={ crtQuestion }
                    onAnswerChanged={ this.onAnswerRecordingChanged }
                    htCredentials={ this.state.htCredentials }
                    timerExpired={ this.state.timerExpired }
                    createHtSession={this.createHtSession}
                />
            }

            if (this.props.respond && this.isLastQuestion()) {
                saveBtn = <LoadingButton className="ml-2" onClick={ this.saveInterview } text={"Submit"} isLoading={this.state.isLoadingSubmit} />;

            } else {
                if (isHR) {
                    let cndName = null;
                    if (this.state.candidate){
                        cndName = this.state.candidate.personalInformation.firstName + " " + this.state.candidate.personalInformation.lastName;
                    }
                    headerLeftContent = (
                        <div>
                            Candidate
                            <h3>{ cndName }</h3>
                            Applying for <strong>{ this.state.interview.job.postingTitle }</strong> at <strong>{ this.state.interview.job.companyName }</strong>
                        </div>
                    );
                    questionCardFooter = (
                        <div className="d-flex justify-content-between align-items-end mt-4">
                            <div><span className="text-sec2">Response time limit:</span> { crtQuestion.responseTime } minutes</div>
                            <Rating rating={ crtQuestion.rating } onRatingUpdate={ this.updateRating } />
                        </div>
                    );
                    addNotesModal = <InterviewNotesModal onSaveNotes={ this.updateNotes } notes={ this.state.interview.notes } show={ this.state.shouldDisplayNotesModal } onHide={ () => { this.showNotesModal(false) } } />;
                    addNotesBtn = <Button variant="outline-primary" className="ml-2 mr-3" onClick={ () => { this.showNotesModal(true) } }>Interview notes</Button>;
                    saveBtn = <LoadingButton className="ml-2" onClick={ this.saveInterview } text={"Submit"} isLoading={this.state.isLoadingSubmit} />;
                }
            }

            let prevQuestionBtn = null;
            if (this.prevQnBtnVisible()) {
                prevQuestionBtn = (
                    <Button variant="link" onClick={ this.goToPrevQuestion } className="back-button mr-4">
                        <FontAwesomeIcon icon={ faChevronLeft } /> Previous
                    </Button>
                );
            }
            let nextQuestionBtn = null;
            if (this.nextQnBtnVisible()) {
                nextQuestionBtn = (
                    <Button variant="primary" onClick={ this.goToNextQuestion } >
                        Next <FontAwesomeIcon icon={ faChevronRight } />
                    </Button>
                );
            }

            return (
                <>


                <div>
                    <div className="recorded-interview-header">
                        { headerLeftContent }
                        <div>
                            <Button className="mr-2" variant="outline-primary" onClick={ ()=>  {this.goToInterviewsList()}}>
                                <FontAwesomeIcon icon={ faChevronLeft } /> Interviews list
                            </Button>
                            { addNotesBtn }
                            { saveBtn }
                        </div>
                    </div>
                    <div className="container">
                        <Card className="simple-card my-3">
                            <Card.Body>
                                <div className="d-flex justify-content-between align-items-center" style={ { minHeight: "2.5rem" } }>
                                    <div className="d-flex align-items-center">
                                        { prevQuestionBtn }
                                        <div className="text-sec2">Question { this.getQuestionNumber() } of { this.getQuestionsCount() }</div>
                                    </div>
                                    { nextQuestionBtn }
                                </div>
                                <div className="interview-question-text">
                                    <ReactQuill
                                        id="editor-show"
                                        readOnly={true}
                                        theme='snow'
                                        value={crtQuestion.value}
                                        modules={RecordedInterview.modules}
                                        style={styleEditorShow}
                                        className="job-description"
                                    />
                                </div>
                                { questionCardAnswer }
                                { timer }
                                { questionCardFooter }
                            </Card.Body>
                        </Card>
                    </div>
                    { addNotesModal }
                    <NavigationPrompt
                        // message='Are you sure you want to leave this interview? Your changes will be lost.'
                        // beforeConfirm={(clb)=>this.cleanup(clb)} //call the callback function when finished cleaning up
                        // Children will be rendered even if props.when is falsey and isActive is false:
                        renderIfNotActive={true}
                        // Confirm navigation if going to a path that does not start with current path:
                        when={(crntLocation, nextLocation) =>{console.log(crntLocation);
                            return crntLocation.pathname === "/takeRecordedInterview" && this.state.shouldBlockNavigation && (!nextLocation || !nextLocation.pathname.startsWith(crntLocation.pathname))}
                        }
                        afterConfirm={this.saveInterview}
                        ref = {this.navigationPromptRef}
                    >
                        {({ isActive, onCancel, onConfirm }) => {
                            if (isActive) {
                                return (
                                    <Modal show={true}  onHide={onCancel}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Warning</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>Are you sure you want to leave this interview? You will not be able to finish it!</Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={onCancel}>Cancel</Button>
                                            <Button onClick={onConfirm}>Leave</Button>
                                        </Modal.Footer>
                                    </Modal>
                                );
                            }
                            return null;
                        }}
                    </NavigationPrompt>;
                </div>
            </>
            );

        } else {
            return <div className="container page-container">No interview selected</div>;
        }
    }
}
RecordedInterview.modules = {
    toolbar: false,
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: true,
    }
}
RecordedInterview.contextType = AppContext;
export default withRouter(RecordedInterview);
