import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import CandidatesList from "./CandidatesList";
import { URLS } from "../Utils/Constants";
import { AppContext } from "../Utils/AppContext";
import "./Candidates.css";
import { EventEmitter } from "../Utils/EventEmitter";
import {LoadingSpinnerComponent} from "../Utils/LoadingSpinnerComponent";
import {Button, Card, Form} from "react-bootstrap";
import candidate from "../Images/portfolio.svg";
import EmptyListInfo from "../Utils/EmptyListInfo";
import Pagination from "react-js-pagination";
import ResumeCriteria from "./ResumeCriteria";

class Candidates extends React.Component {
    state = {
        jobs: [],
        candidates: {},
        // filteredCandidates :[],
        isLoadingCandidates: false,
        filterCandidatesApplied: false,
        skill: "",
        candidateStatus: "",
        candidateName: "",
        jobSelected: "",
        jobId: "",
        jobToSee: null,
        candidatesProfiles : {},
        candidatesProfilesWithJob: {},
        activePageCandidates: 1,
        activePageCandidatesWithJob: 1,
        // candidatesId: [],
        shouldDisplayCriteriaModal : false,
        criteriaToEdit :  { jobTitle: "", skills: [], yearsExperience: 0, listCandidates: [] }
    };

    componentDidMount(){
        this.getJobs();
    }

    getJobs = async () => {
        // this.setState({ isLoadingCandidates: true });
        try {
            let response = await axios.get(`${ URLS.JOBS }/jobs`, {
                headers: { "Authorization": `Bearer ${ this.context.token }` }
            });
            if (response.status === 200) {
                // this.setState({ jobs: response.data }, ()=>this.getCandidates(scrollTop));
                this.setState({ jobs: response.data } );
                // this.setState({ isLoadingCandidates: false });
                console.log("state candidates ",this.state);
            } else {
                EventEmitter.emit("showNotification", "danger", "Failed to get jobs.");
                // this.setState({ isLoadingCandidates: false });

            }
        } catch (error) {
            EventEmitter.emit("showNotification", "danger", "Failed to get jobs.");
            // this.setState({ isLoadingCandidates: false });
        }
    };

    getJob = async (jobId, resetCriteria = false) => {
        this.setState({ isLoadingCandidates: true });
        try {
            let response = await axios.put(`${ URLS.JOBS }/jobs_candidates/${ jobId }?status=${this.state.candidateStatus}&candidateName=${this.state.candidateName}`, this.state.criteriaToEdit, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${ this.context.token }`
                }
            });
            let candidatesId=[];
            if (response.status === 200) {
                this.setState({ jobToSee: response.data },()=>{
                    // candidatesId = this.state.jobToSee.orderedCandidates.map(item => item.candidate);
                    // this.setState({candidatesId : candidatesId},()=>
                        this.getCandidates(this.state.jobToSee,candidatesId
                        // )
                );
                        // if (!resetCriteria){
                        //     this.setState({criteriaToEdit: { jobTitle: this.state.jobToSee.postingTitle, skills: this.state.jobToSee.skillset, yearsExperience: this.state.jobToSee.workExperience, listCandidates: [] }})
                        // }

                });
            }
        } catch (e) {
            EventEmitter.emit("showNotification", "danger", "Failed to load job opening.");
            this.setState({ isLoadingCandidates: true });
        }
    };

    getCandidates = async (jobSelected,idCandidates) => {
        // this.setState({filteredCandidates: {}});
        if (jobSelected.orderedCandidates.length <= 0){
            this.setState({candidatesProfiles:[]});
            this.setState({ isLoadingCandidates: false });
        } else {
            try {
                let response = await axios.put(`${URLS.PROFILES}/resumes/withStatus?page=${this.state.activePageCandidates-1}&size=10`, this.state.jobToSee.orderedCandidates,{
                    headers: {"Authorization": `Bearer ${this.context.token}`}
                });
                if (response.status === 200) {
                    this.setState({candidatesProfiles : response.data}, ()=>{
                        // this.filterCandidates();
                    });
                    this.setState({ isLoadingCandidates: false });
                }
            } catch (error) {
                EventEmitter.emit("showNotification", "danger", "Failed to get candidates.");
                this.setState({ isLoadingCandidates: false });
            }
        this.setState({isLoadingCandidates: false});
        }
    };

    getCandidatesWithJobInfo = async () => {
        this.setState({ isLoadingCandidates: true });
        try {
            let response = await axios.get(`${URLS.PROFILES}/resumes/withJob?candidateName=${this.state.candidateName}&page=${this.state.activePageCandidatesWithJob-1}&size=10`,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${ this.context.token }`
                },
                data: "none"
            });
            if (response.status === 200) {
                this.setState({candidatesProfilesWithJob : response.data});
                this.setState({ isLoadingCandidates: false });
            }
        }catch (error) {
            console.log(error.response)
            EventEmitter.emit("showNotification", "danger", "Failed to get candidates.");
            this.setState({isLoadingCandidates: false});
        }
        this.setState({isLoadingCandidates: false});

    }

    filterCandidates = () =>{
        let candidates = {};
        let filteredCandidates = {};
        this.state.candidatesProfiles.resumes.forEach( candidateProfile => {
            if (!candidates[candidateProfile.accountIdentifier]){
                console.log(candidateProfile.accountIdentifier)
                candidates[candidateProfile.accountIdentifier] = candidateProfile;
                this.setState( {candidates: candidates},()=>{
                        filteredCandidates[candidateProfile.accountIdentifier]=this.state.candidates[candidateProfile.accountIdentifier];
                        this.setState({filteredCandidates: filteredCandidates});
                })
            }

        })
    };

    handleChange = (e) => {
        if (e.target.name === "jobId"){
           this.resetCriteria();
        }
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = (e, resetCriteria = false)=>{
        e.preventDefault();
        if (resetCriteria) this.resetCriteria();
        if (this.state.jobId !== ""){
            if (this.state.candidateStatus !== "" || this.state.candidateName !== "")
                this.setState({filterCandidatesApplied: true,candidatesProfilesWithJob: {}});
            this.setState({activePageCandidates:1},()=>this.getJob(this.state.jobId));
        }else

            if (this.state.candidateName !== ""){
                this.setState({filterCandidatesApplied: true});
                this.setState({activePageCandidatesWithJob:1,jobToSee:null},()=>this.getCandidatesWithJobInfo());
            }
            else
                {
            EventEmitter.emit("showNotification", "warning", "Please, select a job first.");
            }
    };

    removeSearchFilter = () =>{
        this.setState({ candidateStatus: "",candidateName: "",filterCandidatesApplied: false,candidatesProfilesWithJob: {}},()=>
        {if(this.state.jobToSee) this.getJob(this.state.jobToSee.id);})
    };

    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({activePageCandidates: pageNumber},()=>this.getCandidates(this.state.jobToSee,this.state.candidatesId));
    }
    handlePageCandidatesWithJobChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({activePageCandidatesWithJob: pageNumber},()=>this.getCandidatesWithJobInfo());
    }
    openCriteriaModal = () => {
        if (this.state.jobToSee)
        {
            this.setState({
                shouldDisplayCriteriaModal: true,
                criteriaToEdit: { jobTitle: this.state.jobToSee.postingTitle, skills: this.state.jobToSee.skillset, yearsExperience: this.state.jobToSee.workExperience, listCandidates: [] }

            });
        }
        else
        {
            EventEmitter.emit("showNotification", "warning", "Please, select a job first.");
        }
    };

    closeCriteriaModal = () => {
        this.setState({
            shouldDisplayCriteriaModal: false});
    };

    addSkill = () => {
        // console.log(this.state.criteriaToEdit.skills)
        this.setState(prevState => {
            let updatedCriteria = JSON.parse(JSON.stringify(prevState.criteriaToEdit));
            updatedCriteria.skills.push("");
            return { criteriaToEdit: updatedCriteria };
        });
    };

    deleteSkill = (skill) => {
        this.setState(prevState => {
            let updatedCriteria = JSON.parse(JSON.stringify(prevState.criteriaToEdit));
            let remainingSkills = updatedCriteria.skills.filter(item => item !== skill);
            updatedCriteria.skills = remainingSkills;
            return { criteriaToEdit: updatedCriteria };
        });
    };

    handleChangeCriteria = (e) => {
        let target = e.target;
        this.setState(prevState => {
            let updatedCriteria = JSON.parse(JSON.stringify(prevState.criteriaToEdit));
            if (target.name === "skill") {
                updatedCriteria.skills[target.dataset.id] = target.value;
            } else if(target.name === "yearsExperience"){
                updatedCriteria[target.name] = parseInt(target.value);
            } else {
                updatedCriteria[target.name] = target.value;
                }
            return { criteriaToEdit: updatedCriteria };
        });
    };

    resetCriteria = () =>{
        this.setState({criteriaToEdit: { jobTitle: "", skills: [], yearsExperience: 0, listCandidates: []}});
    };

    render() {
        let jobOptions=[];
        {
            if (this.state.jobs && this.state.jobs.length > 0) {
                this.state.jobs.forEach((job,index)=>{
                    jobOptions.push(<option value={job.id}>{job.postingTitle}</option>)
                })
            }
        }
        return (

            <div className="container page-container"  >
                <h1 className="page-title">Candidates</h1>
                <div>
                    <Card className="simple-card mb-4">
                        <Card.Body className="d-flex justify-content-between align-items-center py-3">
                            <div className="d-flex">
                                <Form className="d-flex" onSubmit={ this.handleSubmit } onChange={ this.handleChange }>

                                    <Form.Control className="mr-2" name="jobId" as="select"

                                                  value={ this.state.jobId }>
                                        <option value="">Select Job</option>
                                        {jobOptions}
                                    </Form.Control>
                                    {/*<Form.Control placeholder="Filter by skill" name="skill" className="mr-2"*/}
                                    {/*              value={ this.state.skill }*/}
                                    {/*              type="text"   />*/}
                                    <Form.Control className="mr-2" name="candidateStatus" as="select"

                                                  value={ this.state.candidateStatus }>
                                        <option value="">Any status</option>
                                        <option value="APPLIED">Applied</option>
                                        <option value="PENDING">Pending</option>
                                        <option value="SCHEDULED">Scheduled recorded</option>
                                        <option value="SCHEDULED_LIVE">Scheduled live</option>
                                        <option value="REFUSED">Refused</option>
                                        <option value="REJECTED">Rejected</option>
                                        <option value="ACCEPTED">Accepted</option>
                                        <option value="RECHECK">Re-check</option>
                                    </Form.Control>
                                    <Form.Control className="mr-2" name="candidateName" placeHolder={"Candidate name"} value={this.state.candidateName}>
                                    </Form.Control>
                                </Form>
                                <Button className="mr-2" onClick={(e)=>this.handleSubmit(e,true)}>Search</Button>
                                <Button className="mr-2" variant="outline-primary" onClick={ this.removeSearchFilter }
                                        hidden={ !this.state.filterCandidatesApplied }>Remove filter</Button>
                            </div>
                            <div>
                                <Button className="mr-2" onClick={this.openCriteriaModal} hidden={!this.state.jobToSee}>Set resume criteria</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                {this.state.isLoadingCandidates?
                    <LoadingSpinnerComponent isLoading={this.state.isLoadingCandidates}/>
                    :
                    (this.state.jobId !== "" && this.state.jobToSee) ?
                        <div>
                            <CandidatesList
                            job={ this.state.jobToSee }
                            candidates={ this.state.candidatesProfiles.resumes }
                            getCandidates={ () => this.getJob(this.state.jobToSee.id)}/>
                            <div className="pt-3 d-flex flex-column align-items-center">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={this.state.activePageCandidates}
                                    itemsCountPerPage={10}
                                    totalItemsCount={this.state.candidatesProfiles.totalNoResumes}
                                    pageRangeDisplayed={5}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                        </div>
                        : (this.state.candidateName !== "" && this.state.jobId === "") ?
                            <div>
                                <CandidatesList
                                    // job={ this.state.jobToSee }
                                    candidates={ this.state.candidatesProfilesWithJob.candidates }
                                    getCandidates={ () => this.getCandidatesWithJobInfo()}
                                />
                                <div className="pt-3 d-flex flex-column align-items-center">
                                    <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={this.state.activePageCandidatesWithJob}
                                        itemsCountPerPage={10}
                                        totalItemsCount={this.state.candidatesProfilesWithJob.totalNoCandidates}
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageCandidatesWithJobChange.bind(this)}
                                    />
                                </div>
                            </div>
                            :
                            <EmptyListInfo icon={candidate} width="10rem" text="Please, select a job first."/>}
                        <ResumeCriteria
                            criteriaToEdit={this.state.criteriaToEdit}
                            shouldDisplayCriteriaModal ={this.state.shouldDisplayCriteriaModal}
                            job={this.state.jobToSee}
                            closeModal = {this.closeCriteriaModal}
                            addSkill = {this.addSkill}
                            deleteSkill = {this.deleteSkill}
                            handleChange = {this.handleChangeCriteria}
                            findCandidates = {() => this.getJob(this.state.jobId, true)}
                        />

            </div>
        );
    }
}

Candidates.contextType = AppContext;
export default withRouter(Candidates);