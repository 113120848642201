import React from 'react';
import Loader from 'react-loader-spinner'

export const LoadingSpinnerComponent = (props) => {

    return (
        <div className="d-flex justify-content-center">
            {props.isLoading && (
                <Loader
                    type="Oval"
                    color="#029687"
                    height={100}
                    width={100}
                    //timeout={3000} //3 secs
                />
            )}
        </div>
    )
};