import React from "react";
import axios from "axios";
import { Button, Form, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Avatar from "../Utils/Avatar";
import { URLS } from "../Utils/Constants";
import { AppContext } from "../Utils/AppContext";
import { EventEmitter } from "../Utils/EventEmitter";

class Shortlist extends React.Component {
    state = {
        notes: ""
    };

    componentDidMount() {
        EventEmitter.on("addCandidateToShortlist", this.addToShortlist);
        EventEmitter.on("removeCandidateFromShortlist", this.removeFromShortlist);
    }

    addToShortlist = (candidate) => {
        let shortlists = (this.context.shortlists) ? JSON.parse(JSON.stringify(this.context.shortlists)) : {};
        if (!shortlists[this.props.job.id]) {
            shortlists[this.props.job.id] = [];
        }
        if (shortlists[this.props.job.id].map(candidate => candidate.accountIdentifier).indexOf(candidate.accountIdentifier) === -1) {
            shortlists[this.props.job.id].push(candidate);
            this.context.update({ shortlists: shortlists });
            localStorage.setItem("shortlists", JSON.stringify(shortlists));
        }
    };

    removeFromShortlist = (candidate) => {
        if (this.context.shortlists[this.props.job.id]) {
            let candidateIndex = this.context.shortlists[this.props.job.id].findIndex(cnd => cnd.accountIdentifier === candidate.accountIdentifier);
            if (candidateIndex !== -1) {
                let shortlists = JSON.parse(JSON.stringify(this.context.shortlists));
                shortlists[this.props.job.id].splice(candidateIndex, 1);
                this.context.update({ shortlists: shortlists });
                localStorage.setItem("shortlists", JSON.stringify(shortlists));
            }
        }
    };

    submitShortlist = async (e) => {
        e.preventDefault();
        try {
            let candidatesToSend = this.context.shortlists[this.props.job.id].map(candidate => candidate.accountIdentifier);
            let recommendation = {
                "id_job": this.props.job.id,
                "id_company": null,
                "candidates": candidatesToSend,
                "details": this.state.notes
            };
            let response = await axios.post(`${URLS.JOBS}/recommendations`, recommendation, {
                headers: { "Authorization": `Bearer ${this.context.token}` }
            });
            if (response.status === 201) {
                EventEmitter.emit("showNotification", "success", "Recommendation successfully posted.");
            } else {
                EventEmitter.emit("showNotification", "danger", "Failed to post recommendation.");
            }
        } catch (e) {
            EventEmitter.emit("showNotification", "danger", "Failed to post recommendation.");
            console.log("Failed to post recommendation. ", e);
        }
    };

    onNotesChanged = (e) => {
        this.setState({ notes: e.target.value });
    };

    render() {
        let shortlistContent;
        let shortListCandidates = [];
        if (this.context.role === "HR_ROLE" && this.context.shortlists) {
            if (this.context.shortlists[this.props.job.id]) {
                this.context.shortlists[this.props.job.id].forEach((candidate, index) => {
                    let candidateName = candidate.personalInformation.firstName + " " + candidate.personalInformation.lastName;
                    shortListCandidates.push(
                        <div className="d-flex flex-column align-items-center mr-5 mt-2" key={index}>
                            <div className="selected-candidate mb-2" key={index}>
                                <Avatar name={candidateName} img={candidate.personalInformation.profilePicture} />
                                <button className="btn-cnd-x" onClick={() => { this.removeFromShortlist(candidate) }} title="Remove from shortlist">
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                            <div>{candidateName}</div>
                        </div>
                    );
                });
            }
        }

        if (shortListCandidates.length) {
            shortlistContent = (
                <React.Fragment>
                    <div className="d-flex flex-wrap">{shortListCandidates}</div>
                    <Form className="mt-4 d-flex align-items-center" onSubmit={this.submitShortlist}>
                        {this.context.user.companyIdentifier == null ? <div></div> :
                            <div className="d-flex flex-1">
                                <Form.Control className="flex-1 mr-2" placeholder="Add some notes" value={this.state.notes} onChange={this.onNotesChanged} />
                                <Button variant="primary" type="submit">Make recommendation</Button>
                            </div>
                        }
                    </Form>
                </React.Fragment>
            );

        } else {
            shortlistContent = "No candidates added to shortlist yet";
        }

        return (
            <Card className="simple-card">
                <Card.Body>
                    {shortlistContent}
                </Card.Body>
            </Card>
        );
    }
}

Shortlist.contextType = AppContext;
export default Shortlist;
