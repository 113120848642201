import React from "react";
import { Form, Col } from "react-bootstrap";
import moment from "moment";

class BirthdatePicker extends React.Component {
    state = {
        day: "",
        month: "",
        year: ""
    };

    componentDidMount() {
        if (this.props.value) {
            this.dateToState(this.props.value);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.dateToState(this.props.value);
        }
    }

    dateToState = (date) => {
        date = moment(date, "DD/MM/YYYY");
        if (date.isValid()) {
            this.setState({
                day: date.date(),
                month: date.month() + 1, // momentjs uses 0 - 11 range instead of 1 - 12
                year: date.year()
            });
        }
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            let dateString = `${ this.state.day }/${ this.state.month }/${ this.state.year }`;
            if (this.state.year > 1000 && moment(dateString, "DD/MM/YYYY").isValid()) {
                this.props.onChange(dateString);
            }
        });
    }

    render() {
        return (
            <Form.Row>
                <Form.Group as={ Col } xs="2">
                    <Form.Label>Day</Form.Label>
                    <Form.Control
                        name="day"
                        value={ this.state.day }
                        onChange={ this.onChange }
                        placeholder="Day"
                        required
                    />
                </Form.Group>

                <Form.Group as={ Col } xs="6">
                    <Form.Label>Month</Form.Label>
                    <Form.Control
                        as="select"
                        name="month"
                        value={ this.state.month }
                        onChange={ this.onChange }
                        className="pers-info-field"
                        placeholder="Month"
                        required
                    >
                        <option value="" hidden>Month</option>
                        <option value={ 1 }>January</option>
                        <option value={ 2 }>February</option>
                        <option value={ 3 }>March</option>
                        <option value={ 4 }>April</option>
                        <option value={ 5 }>May</option>
                        <option value={ 6 }>June</option>
                        <option value={ 7 }>July</option>
                        <option value={ 8 }>August</option>
                        <option value={ 9 }>September</option>
                        <option value={ 10 }>October</option>
                        <option value={ 11 }>November</option>
                        <option value={ 12 }>December</option>
                    </Form.Control>
                </Form.Group>

                <Form.Group as={ Col } xs="4">
                    <Form.Label>Year</Form.Label>
                    <Form.Control
                        name="year"
                        value={ this.state.year }
                        onChange={ this.onChange }
                        placeholder="Year"
                        required
                    />
                </Form.Group>
            </Form.Row>
        );
    }
}

export default BirthdatePicker;
