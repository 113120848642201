import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faCoins, faInfoCircle, faMapMarkerAlt, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Card, Button } from "react-bootstrap";
import Avatar from "../Utils/Avatar";
import NotesModal from "../Utils/NotesModal";
import Shortlist from "./Shortlist";
import CandidatesList from "../Candidates/CandidatesList";
import { URLS } from "../Utils/Constants";
import { AppContext } from "../Utils/AppContext";
import { EventEmitter } from "../Utils/EventEmitter";
import {styleEditorShow} from "../Utils/EditorShow.css";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import Pagination from "react-js-pagination"; // ES6

class JobInfo extends React.Component {
    state = {
        jobToSee: null,
        candidates: {},
        candidatesProfiles : {},
        activePageCandidates :1
    };

    componentDidMount() {
        EventEmitter.on("getCandidateNotes", this.getCandidateNotes);
        this.getJob();
    }

    showNotesModal = (jobId, candidateId) => {
        EventEmitter.emit("showNotesModal", jobId, candidateId);
    };

    closeNotesModal = () => {
        EventEmitter.emit("closeNotesModal", this.state.closeNotesModal);
    };

    showMore = () => {
        this.setState({ showAll: true });
    };

    showLess = () => {
        this.setState({ showAll: false });
    };

    getCandidates = async () => {
        let candidatesId = [];
        if (this.state.jobToSee) {
            candidatesId=this.state.jobToSee.candidates.map(item => item.candidate);
                try {
                    let response = await axios.put(`${ URLS.PROFILES }/resumes/withStatus?page=${this.state.activePageCandidates-1}&size=10`, this.state.jobToSee.candidates,{
                        headers: { "Authorization": `Bearer ${ this.context.token }` },
                    });
                    if (response.status === 200) {
                        this.setState({candidatesProfiles : response.data}, ()=>{
                            this.formListCandidates();
                        });

                    }
                } catch (error) {
                    EventEmitter.emit("showNotification", "danger", "Failed to get candidates.");
                }
        }
    };

    formListCandidates = () =>{
        let candidates = {};
            this.state.candidatesProfiles.resumes.forEach( candidateProfile => {
                if (!candidates[candidateProfile.accountIdentifier]){
                    candidates[candidateProfile.accountIdentifier] = candidateProfile;
                    this.setState(
                       {candidates: candidates}
                    );
                }
            })
    };

    getJob = async () => {
        let criteria={
            "jobTitle": "",
            "listCandidates": [
            ],
            "skills": [
            ],
            "yearsExperience": 0
        };
        let jobId = new URL(window.location.href).searchParams.get("id");
        try {
            let response = await axios.put(`${ URLS.JOBS }/jobs_candidates/${ jobId }`,criteria, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${ this.context.token }`
                }
            });
            if (response.status === 200) {
                this.setState({ jobToSee: response.data });
                this.getCandidates();
            }
        } catch (e) {
            EventEmitter.emit("showNotification", "danger", "Failed to load job opening.");
        }
    }

    getCandidateNotes = async (candidateId) => {
        let jobId = new URL(window.location.href).searchParams.get("id");
        try {
            let response = await axios.get(`${ URLS.JOBS }/jobs/${ jobId }/candidates/${ candidateId }/notes`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${ this.context.token }`
                },
                data: "none"
            });
            if (response.status === 200) {
                this.setState(prevState => {
                    let notes = prevState.notes;
                    notes = response.data;
                    return { notes: notes };
                });
            }
        } catch (e) {
            console.log("FAILURE ON GETTING CANDIDATE NOTES", e);
        }
    }

    toggleShowDescription = () => {
        this.setState(prevState => { return { showAllDescription: !prevState.showAllDescription } });
    }

    goToJobsList = () => {
        this.props.history.push("/jobOpenings");
    };

    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({activePageCandidates: pageNumber},()=>this.getCandidates());
    }

    renderWorkExperience = (workExperience) => {
        switch (workExperience) {
            case 0:
                return "No experience required";
            default:
                return workExperience+"+ years experience";
        }
    };

    render() {
        if (this.state.jobToSee) {
            let shortList = null;
            if (this.context.role === "HR_ROLE" && Object.keys(this.state.candidates).length){
                shortList = (
                    <React.Fragment>
                        <div className="my-3">Short list</div>
                        <Shortlist job={ this.state.jobToSee } />
                        <div className="my-3">Other candidates</div>
                    </React.Fragment>
                );
            }
            let skills = this.state.jobToSee.skillset;
            let skillsList = skills.map((skill, index) => (
                <li key={ index }>{skill}</li>
            ));
            skills = <ul>{ skillsList }</ul>;

            return (
                <div className="container page-container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h1 className="page-title">Job opening info</h1>
                        <Button variant="outline-primary" onClick={ this.goToJobsList }>
                            <FontAwesomeIcon icon={ faChevronLeft } /> Jobs list
                        </Button>
                    </div>
                    <div className="job-info-grid d-flex mb-3">
                        <Avatar name={ this.state.jobToSee.companyName } img={ this.state.jobToSee.logo } />
                        <div className="ml-2">
                            <div className="font-weight-bold">{ this.state.jobToSee.postingTitle }</div>
                            <div>{ this.state.jobToSee.companyName }</div>
                        </div>
                    </div>

                    <Card className="simple-card mb-5">
                        <Card.Body>
                            <div className="d-flex">
                                <div className="flex-1" hidden={ !this.state.jobToSee.description }>
                                    <div className="text-sec2 mb-2">Job description</div>
                                    <ReactQuill
                                        id="editor-show"
                                        readOnly={true}
                                        theme='snow'
                                        value={this.state.jobToSee.description}
                                        modules={JobInfo.modules}
                                        style={styleEditorShow}
                                    />
                                </div>
                                <div className="d-flex justify-content-end ml-4">
                                    <div className="job-info-grid text-sec1 align-self-start">
                                        <FontAwesomeIcon icon={ faBuilding } />
                                        <div className="mx-2">{ this.state.jobToSee.type }</div>
                                        <FontAwesomeIcon icon={ faInfoCircle } hidden={ this.state.jobToSee.workExperience === null } />
                                        <div className="mx-2" hidden={ !this.state.jobToSee.workExperience === null }>{this.renderWorkExperience(this.state.jobToSee.workExperience)}

                                        </div>
                                        <FontAwesomeIcon icon={ faMapMarkerAlt } />
                                        <div className="mx-2">{ this.state.jobToSee.address }</div>
                                        <FontAwesomeIcon icon={ faCoins } hidden={ !this.state.jobToSee.salary } />
                                        <div className="mx-2" hidden={ !this.state.jobToSee.salary }>{ this.state.jobToSee.salary }</div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4" hidden={ !this.state.jobToSee.skillset }>
                                <div className="text-sec2 mb-2">Required skills</div>
                                { skills }
                            </div>
                        </Card.Body>
                    </Card>

                    <h1 className="page-title">Candidates</h1>
                    { shortList }
                    <div>
                        <CandidatesList
                            shortlist
                            job={ this.state.jobToSee }
                            candidates={ this.state.candidatesProfiles.resumes }
                            getCandidates={ this.getJob }
                        />
                        <div className="pt-3 d-flex flex-column align-items-center">
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={this.state.activePageCandidates}
                                itemsCountPerPage={10}
                                totalItemsCount={this.state.candidatesProfiles.totalNoResumes}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChange.bind(this)}
                            />
                        </div>
                    </div>
                    <NotesModal show={ this.state.showNotesModal } />
                </div>
            );

        } else {
            return null;
        }
    }
}
JobInfo.modules = {
    toolbar: false,
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: true,
    }
}
JobInfo.contextType = AppContext;
export default withRouter(JobInfo);
