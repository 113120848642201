import React from "react";
import Form from "react-bootstrap/Form";
import "./RecordedInterview.css";

class InterviewTextAnswerForm extends React.Component {
    onAnswerChanged = (e) => {
        if (!this.props.timerExpired) {
            this.props.onAnswerChanged(e);
        }
    }

    render() {
        let content = null;
        let response = (this.props.crtQuestion.response) ? this.props.crtQuestion.response : "";

        if (this.props.respond) { // respond to interview
            content = (
                <Form.Group>
                    <Form.Label>Answer</Form.Label>
                    <Form.Control
                        type="text"
                        as="textarea"
                        rows="10"
                        disabled={ this.props.timerExpired }
                        onChange={ this.onAnswerChanged }
                        value={ response }
                    />
                </Form.Group>
            );

        } else { // view interview
            content = (
                <div>
                    <div className="text-sec2 mb-1">Answer</div>
                    <p>{ this.props.crtQuestion.response }</p>
                </div>
            );
        }

        return content;
    }
}

export default InterviewTextAnswerForm;