import axios from "axios";
import React from "react";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import AssessmentCard from "Assessments/AssessmentCard";
import CreateAssessment from "Assessments/CreateAssessment";
import { AppContext } from "Utils/AppContext";
import { URLS } from "Utils/Constants";
import { EventEmitter } from "Utils/EventEmitter";
import NotificationModal from "Utils/NotificationModal";
import assessment from "../Images/satisfaction.svg";
import EmptyListInfo from "../Utils/EmptyListInfo";
import {LoadingSpinnerComponent} from "../Utils/LoadingSpinnerComponent";

class Assessments extends React.Component {
    state = {
        assessments: [],
        assessmentToEdit: null,
        shouldCreateNewAssessment: false,
        assessmentToDelete: null,
        isLoadingAssessments: false
    };

    componentDidMount() {
        this.getAssessments();
    }

    deleteAssessment = async () => {
        try {
            let response = await axios.delete(`${ URLS.JOBS }/assessment/${ this.state.assessmentToDelete }`, {
                headers: { "Authorization": `Bearer ${ this.context.token }` }
            });
            this.showDeleteAssessmentModal(null);
            if (response.status === 204) {
                this.getAssessments();
                EventEmitter.emit("showNotification", "success", "Assessment successfully deleted.");
            } else {
                EventEmitter.emit("showNotification", "danger", "Failed to delete assessment.");
            }
        } catch (error) {
            this.showDeleteAssessmentModal(null);
            EventEmitter.emit("showNotification", "danger", "Failed to delete assessment.");
        }
    };

    getAssessments = async () => {
        this.setState({ isLoadingAssessments: true });
        try {
            let response = await axios.get(`${ URLS.JOBS }/assessment`, {
                headers: { "Authorization": `Bearer ${ this.context.token }` }
            });
            if (response.status === 200) {
                this.setState({ assessments: response.data });
                this.setState({ isLoadingAssessments: false });
            } else {
                EventEmitter.emit("showNotification", "danger", "Failed to load assessments.");
                this.setState({ isLoadingAssessments: false });
            }
        } catch (error) {
            EventEmitter.emit("showNotification", "danger", "Failed to load assessments.");
            this.setState({ isLoadingAssessments: false });
        }
    };

    openEditAssessmentModal = (assessmentToEdit) => {
        this.setState({ shouldCreateNewAssessment: false, assessmentToEdit: assessmentToEdit });
    };

    openCreateAssessmentModal = () => {
        this.setState({
            shouldCreateNewAssessment: true,
            assessmentToEdit: { name: "", questions: [{ value: "", responseTime: "", questionType: "text", Answer: null }] }
        });
    };

    closeEditAssessmentModal = () => {
        this.setState({ assessmentToEdit: null });
    };

    showDeleteAssessmentModal = (assessmentId) => {
        this.setState({ assessmentToDelete: assessmentId });
    };

    render() {
        let assessmentsList;
        if (this.state.assessments.length > 0) {
            assessmentsList = [];
            this.state.assessments.forEach((assessment) => {
                assessmentsList.push(
                    <AssessmentCard 
                        assessment={ assessment } 
                        editAssessment={this.openEditAssessmentModal}
                        deleteAssessment={this.showDeleteAssessmentModal}
                        key={ assessment.id }
                    />
                );
            });
        }
        return (
            <div className="container page-container">
                <div className="d-flex align-items-center justify-content-between">
                    <h1 className="page-title">Assessments</h1>
                    <Button onClick={ this.openCreateAssessmentModal }>Create new</Button>
                </div>

                <CreateAssessment
                    assessmentToEdit={ this.state.assessmentToEdit }
                    saveAssessment={ this.saveAssessment }
                    getAssessments={ this.getAssessments }
                    closeModal={ this.closeEditAssessmentModal }
                    shouldCreateNewAssessment={ this.state.shouldCreateNewAssessment }
                />

                <NotificationModal
                    title="Delete assessment"
                    content="Are you sure you want to delete the selected assessment?"
                    confirmAction={ this.deleteAssessment }
                    show={ this.state.assessmentToDelete !== null }
                    onHide={ () => { this.showDeleteAssessmentModal(null) } }
                />
                {this.state.isLoadingAssessments ?
                    <LoadingSpinnerComponent isLoading={this.state.isLoadingAssessments}/>
                    : this.state.assessments.length
                        ?  <div className="items-grid">{ assessmentsList }</div>
                        : <EmptyListInfo icon={assessment} width="10rem" text="You do not have any assessments yet."/>}
            </div>
        )
    }
}

Assessments.contextType = AppContext;
export default withRouter(Assessments);
