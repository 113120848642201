import React from "react";
import Cookies from "js-cookie";
import {Redirect, Route} from "react-router-dom";

function PrivateRoute({component: Component, roles, ...rest}) {
    return (
        <Route {...rest} render={props => {
            if (roles) {
                // if (roles.indexOf(localStorage.getItem("role")) !== -1) {
                if (roles.indexOf(Cookies.get("role")) !== -1) {
                    return <Component {...props} />;

                } else {
                    return <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: props.location}
                        }}
                    />;
                }
            } else {
                return <Component {...props} />;
            }
        }} />
    );
}

export default PrivateRoute;