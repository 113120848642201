import React from "react";
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { AppContext } from "../Utils/AppContext";
import careersLogo from "../Images/hyper-careers-logo-wr.svg";
import "./Navigation.css";

class Navigation extends React.Component {
    handleLogout = () => {
        this.setState({ loggedIn: "loggedOut" });

        Cookies.remove("token");
        Cookies.remove("refresh_token");
        Cookies.remove("role");
        Cookies.remove("userId");
        Cookies.remove("crtUserName");
        Cookies.remove("user");

        this.context.update({
            token: undefined,
            refresh_token: undefined,
            role: undefined,
            userId: undefined,
            crtUserName: undefined,
            user: undefined
        });

        this.props.history.push({ pathname: "/home" });
    };

    checkIfActive = (path) => {
        return path === this.props.location.pathName;
    };

    render() {
        if (this.context.isIframe) {
            return null;
        }

        let isHR = this.context.role === "HR_ROLE";
        let isCompany = this.context.role === "COMPANY_ROLE";
        let isCandidate = this.context.role === "CANDIDATE_ROLE";

        let candidatesBtn = null;
        let assessmentsBtn = null;
        let manageHrsBtn = null;
        let interviewsBtn = null;

        if (this.context.token) { // logged in
            interviewsBtn = (
                <LinkContainer to={ '/interviews' }>
                    <Nav.Link active={ this.checkIfActive('/interviews') } >
                        Interviews
                    </Nav.Link>
                </LinkContainer>
            );
            if (isHR || isCompany) { // HR or company
                candidatesBtn = (
                    <LinkContainer to={ '/candidates' }>
                        <Nav.Link active={ this.checkIfActive('/candidates') }>
                            Candidates
                        </Nav.Link>
                    </LinkContainer>
                );

                assessmentsBtn = (
                    <LinkContainer to={ '/assessments' }>
                        <Nav.Link active={ this.checkIfActive('/assessments') }>
                            Assessments
                        </Nav.Link>
                    </LinkContainer>
                );

                interviewsBtn = (
                    <LinkContainer to={ '/interviews' }>
                        <Nav.Link active={ this.checkIfActive('/interviews') }>
                            Interviews
                        </Nav.Link>
                    </LinkContainer>
                );

                if (isCompany) { // company
                    manageHrsBtn = (
                        <LinkContainer to={ '/manageHRs' }>
                            <Nav.Link active={ this.checkIfActive('/manageHRs') }>
                                Manage HRs
                            </Nav.Link>
                        </LinkContainer>
                    );
                }
            }
        }

        let rightNav;
        if (this.context.token) {
            let profileBtn = null;
            if (isCandidate) {
                profileBtn = (
                    <LinkContainer to={ '/profile' } >
                        <NavDropdown.Item active={ this.checkIfActive('/profile') }>Profile</NavDropdown.Item>
                    </LinkContainer>
                );
            }
            let accSettingsLabel = (isCandidate) ? "Change password" : "Account settings";
            rightNav = (
                <Nav className="profile-div">
                    <NavDropdown title={ this.context.crtUserName } id="collasible-nav-dropdown" alignRight >
                        { profileBtn }
                        <LinkContainer to={ '/changePassword' }>
                            <NavDropdown.Item active={ this.checkIfActive('/changePassword') }>{ accSettingsLabel }</NavDropdown.Item>
                        </LinkContainer>
                        <NavDropdown.Item onClick={ this.handleLogout }>Log out</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            );

        } else {
            rightNav = (
                <Nav>
                    <LinkContainer to={ '/login' }>
                        <Nav.Link active={ this.checkIfActive('/login') }>
                            Log in
                        </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to={ '/register' }>
                        <Nav.Link active={ this.checkIfActive('/register') }>
                            Register
                        </Nav.Link>
                    </LinkContainer>
                </Nav>
            );
        }

        let expand = (isHR || isCompany) ? "lg" : "md";

        return (
            <React.Fragment >
                <Navbar fixed="top" variant="dark" expand={ expand } >
                    <LinkContainer to={ '/home' } >
                        <Navbar.Brand>
                            <img className="pr-4" src={ careersLogo } alt="HyperCareers" style={ { height: "2.2rem" , borderRight: "1px solid rgb(255,255,255,0.2)"} } />
                            {/* <img src={ euLogo } alt="" className="round-header-logo" />
                            <img src={ govLogo } alt="" className="round-header-logo" />
                            <img src={ siLogo } alt="" className="round-header-logo" /> */}
                        </Navbar.Brand>
                    </LinkContainer>

                    <Navbar.Toggle />

                    <Navbar.Collapse >
                        <Nav className="mr-auto">
                            <LinkContainer to={ '/jobOpenings' }>
                                <Nav.Link active={ this.checkIfActive('/jobOpenings') }>
                                    Job Openings
                                </Nav.Link>
                            </LinkContainer>
                            { candidatesBtn }
                            { interviewsBtn }
                            { assessmentsBtn }
                            { manageHrsBtn }
                        </Nav>
                        { rightNav }
                    </Navbar.Collapse>
                </Navbar>
                <div className="navbar-padding"></div>
            </React.Fragment>
        )
    }
}

Navigation.contextType = AppContext;
export default withRouter(Navigation);
