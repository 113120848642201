import React from "react";

class CookieDeclaration extends React.Component{
    render() {
        return (
            <div className="container page-container">
                <h1 className="pb-3">Cookie Declaration</h1>
                <p>
                    This website uses cookies. We use cookies to personalise content and ads, to provide social media features and to analyse our traffic.
                    We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.
                    You consent to our cookies if you continue to use our website.<br/><br/>

                    Cookies are small text files that can be used by websites to make a user's experience more efficient.<br/><br/>

                    The law states that we can store cookies on your device if they are strictly necessary for the operation of this site. For all other types of cookies we need your permission.<br/><br/>

                    This site uses different types of cookies. Some cookies are placed by third party services that appear on our pages.<br/><br/>

                    You can at any time change or withdraw your consent from the Cookie Declaration on our website.
                    Learn more about who we are, how you can contact us and how we process personal data in <a href={process.env.PUBLIC_URL+'/Documents/policy_en.pdf'} target='_blank'> Privacy Policy</a> and <a href={process.env.PUBLIC_URL+'/Documents/gdpr_en.pdf'} target='_blank'>GDPR</a>.<br/><br/>

                    Your consent applies to the following domains: www.hypertalk.net, www.hypercareers.com <br/><br/>
                    <span className="font-weight-bold"> Your current state: Allow all cookies necessary .</span>
                </p>
            </div>)
    }
}
export default CookieDeclaration;