const KEY = new Uint8Array([
    0xeb, 0xdd, 0x62, 0xf1, 0x68, 0x14, 0xd2, 0x7b,
    0x68, 0xef, 0x12, 0x2a, 0xfc, 0xe4, 0xae, 0x3c
]);

const config = [{
    initDataTypes: ['webm'],
    videoCapabilities: [{
        contentType: 'video/webm; codecs="vp8"'
    }]
}];

export const setEncryptedEvent = (video) => {

    const toBase64 = (u8arr) => {
        return btoa(String.fromCharCode.apply(null, u8arr))
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=*$/, '');
    };

    const generateLicense = (message) => {
        // Parse the clearkey license request
        var request = JSON.parse(new TextDecoder().decode(message));

        var keyObj = {
            kty: 'oct', // octet sequence
            alg: 'A128KW',
            kid: request.kids[0],
            k: toBase64(KEY)
            // base64 encoding of the octet sequence containing the symmetric key value
        };
        return new TextEncoder().encode(JSON.stringify({
            keys: [keyObj]
        }));
    };

    const handleMessage = (event) => {
        var license = generateLicense(event.message);
        var session = event.target;
        session.update(license)
            .catch((error) => {
                // console.error('Failed to update the session', error);
            });
    };

    const handleEncrypted = (event, video) => {
        setTimeout(() => {
            var session = video.mediaKeys.createSession();
            session.addEventListener('message', handleMessage, false);
            session.generateRequest(event.initDataType, event.initData)
                .catch((error) => {
                    console.error('Failed to generate a license request', error);
                });
        }, 1000);
    };

    video.addEventListener('encrypted', (event) => {
        handleEncrypted(event, video);
    }, false);

    navigator.requestMediaKeySystemAccess('org.w3.clearkey', config)
        .then((keySystemAccess) => {
            return keySystemAccess.createMediaKeys();
        })
        .then((createdMediaKeys) => {
            return video.setMediaKeys(createdMediaKeys);
        })
        .catch((error) => {
            console.error('Failed to set up MediaKeys', error);
        });
};




