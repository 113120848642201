import React from "react";
import { Button } from "react-bootstrap";
import VideoPlayer from "./VideoPlayer";
import "./RecordedInterview.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import {URLS} from "../Utils/Constants";
import EmptyListInfo from "../Utils/EmptyListInfo";
import noRecord from "../Images/no-video.svg";

class InterviewRecordedAnswerForm extends React.Component {
    state = {
        savingRecord: false,
        currentRecord: null,
        reloadCounter : 0
    };

    constructor(props){
        super(props);
        this.iframeRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.respond){
            this.props.createHtSession();
            console.log("create session")
        }
        if (this.props.crtQuestion.response !== this.state.currentRecord) {
            this.setState({
                savingRecord: false,
                currentRecord: this.props.crtQuestion.response
            });
        }
        window.addEventListener("message", this.onIframeMessage, false);
    }

    componentDidUpdate(prevProps) {
        if (this.props.crtQuestion !== prevProps.crtQuestion) {
            this.setState({
                savingRecord: false,
                currentRecord: this.props.crtQuestion.response
            });
        }
    }

    retake = () => {
        this.setState({ currentRecord: null });
    };

    // saveRecording = () => {
    //     this.props.onAnswerChanged(this.state.currentRecord);
    // };

    onIframeMessage = (event) => {
        let data = event.data;
        if (data && data.event) {
            if (data.event === "record-stopped") {
                this.setState({ savingRecord: true });

            } else if (data.event === "record-saved") {
                this.setState({
                    savingRecord: false,
                    currentRecord: `${URLS.HYPERTALK_BACKEND}` + data.message.message.filePath.split("/var/kurentoRecord")[1] + "/" + data.message.message.fileName
                });
                this.props.onAnswerChanged(this.state.currentRecord);
            }
        }
    };
    reloadFrame = (e) => {
        e.preventDefault();
        this.setState({
            reloadCounter: this.state.reloadCounter+1
        });
    };
    render() {
        let content = null;

        if (this.props.respond) { // respond to interview
            if (this.state.currentRecord) {
                // let saveBtn = null;
                // if (this.props.crtQuestion.response !== this.state.currentRecord) {
                //     saveBtn = (<Button onClick={ this.saveRecording } className="ml-2">Use this recording</Button>);
                // }
                content = (
                    <div>
                        <div className="d-flex justify-content-end pb-3">
                            <Button variant="outline-primary" onClick={ this.retake }>Retake</Button>
                            {/*{ saveBtn }*/}
                        </div>
                        <VideoPlayer videoSrc={ this.state.currentRecord } />

                    </div>
                );

            } else if (this.props.htCredentials) {
                // saving record overlay
                let iframeOverlay = null;
                if (this.state.savingRecord) {
                    iframeOverlay = (
                        <div className="recorded-interview-iframe-overlay">
                            <FontAwesomeIcon icon={ faSave } className="mr-2" />
                            Saving recording...
                        </div>
                    );
                }

                let iframeSrc = `${URLS.HYPERTALK_MEETING}/#!/attend?token=${ this.props.htCredentials.token }&email=${ this.props.htCredentials.email }&language=en&parentComponent=careers`;
                content = (
                    <div>
                        <p><small> Problems with your camera? After you allow it from browser, <a href="#" onClick={this.reloadFrame}> click here </a>to refresh the video tool.</small></p>
                        <div className="recorded-interview-iframe-wrapper">
                            <iframe className="recorded-interview-iframe" src={ iframeSrc } allow="microphone; camera" ref={this.iframeRef} key={this.state.reloadCounter}></iframe>
                            { iframeOverlay }
                        </div>
                    </div>
                );
            } else {
                content = <div>An error occurred while initializing the recording tool.</div>;
            }
        } else { // view interview
            if (this.state.currentRecord === null) {
                content = <div className="d-flex justify-content-center align-items-center"><EmptyListInfo  icon={noRecord} width="25rem" text="No record found."/></div>
            } else{
                content = <VideoPlayer videoSrc={ this.state.currentRecord } />;
            }
        }
        return content;
    }
}

export default InterviewRecordedAnswerForm;
