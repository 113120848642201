import React from "react";
import { Card, Button, Form } from "react-bootstrap";

class AnswerVariantsManager extends React.Component {
    addAnswerVariant = () => {
        let updatedAnsVariants = JSON.parse(JSON.stringify(this.props.question.Answer));
        if (!updatedAnsVariants) updatedAnsVariants = [];
        updatedAnsVariants.push({ value: "", correct: false });
        this.props.updateVariants(this.props.questionIndex, updatedAnsVariants);
    };

    deleteAnswerVariant = (e) => {
        let updatedAnsVariants = JSON.parse(JSON.stringify(this.props.question.Answer));
        updatedAnsVariants.splice(e.target.dataset.id, 1);
        this.props.updateVariants(this.props.questionIndex, updatedAnsVariants);
    };

    updateAnswerVariant = (e) => {
        let updatedAnsVariants = JSON.parse(JSON.stringify(this.props.question.Answer));
        if (this.props.question.questionType === "singleChoice" && e.target.name === "correct") {
            for (let ansVariant of updatedAnsVariants) {
                ansVariant.correct = false;
            }
        }
        updatedAnsVariants[e.target.dataset.id][e.target.name] = (e.target.name === "correct") ? e.target.checked : e.target.value;
        this.props.updateVariants(this.props.questionIndex, updatedAnsVariants);
    };

    render() {
        let answerVariants = [];
        if (this.props.question.Answer) {
            this.props.question.Answer.forEach((ansVariant, index) => {
                let checkType = (this.props.question.questionType === "singleChoice") ? "radio" : "checkbox";
                answerVariants.push((
                    <div className="d-flex align-items-center mb-3" key={ index }>
                        <Form.Check checked={ ansVariant.correct } name="correct" data-id={ index } type={ checkType } onChange={ this.updateAnswerVariant } />
                        <Form.Control name="value" type="text" value={ ansVariant.value } data-id={ index } className="flex-1" onChange={ this.updateAnswerVariant } required />
                        <Button variant="outline-danger" onClick={ this.deleteAnswerVariant } data-id={ index } className="ml-2">Delete</Button>
                    </div>
                ));
            });
        }

        return (
            <Card>
                <Card.Body>
                    <Form>
                        { answerVariants }
                    </Form>
                    <Button variant="outline-primary" onClick={ this.addAnswerVariant }>Add answer variant</Button>
                </Card.Body>
            </Card>
        );
    }
}

export default AnswerVariantsManager;