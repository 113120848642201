import React from "react";
import {Modal, Button, Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt, faEnvelope, faPhone, faBuilding, faDownload} from "@fortawesome/free-solid-svg-icons";
import Avatar from "../Utils/Avatar";
import {AppContext} from "../Utils/AppContext";
import {EventEmitter} from "../Utils/EventEmitter";
import CandidateStatus from "./CandidateStatus";
import {SkillCategories} from "../Utils/Constants";

class CandidateInfo extends React.Component {
    state = {
        show: false
    };

    componentDidMount() {
        EventEmitter.on("showCandidateInfoModal", this.showModal);
        EventEmitter.on("candidateStatusChanged", (candidateId, jobId, status) => {
            if (this.state.show && this.state.candidate.accountIdentifier === candidateId && this.state.jobId === jobId) {
                if (status === "DENIED") {
                    this.setState({show: false});
                } else {
                    this.setState({status: status});
                }
            }
        });
    }

    showModal = (candidate, jobId, status, recommendation) => {
        this.setState({
            show: true,
            candidate: candidate,
            jobId: jobId,
            status: status,
            recommendation: recommendation
        });
    };

    hideModal = () => {
        this.setState({show: false});
    };

    showCV = (cvLink) => {
        window.open(cvLink);
    };

    showCreateInterview = (accountId, jobId) => {
        this.hideModal();
        this.props.showCreateInterview(accountId, jobId);
    };
    
    splitText(text){
        if(text !== null) 
            return text.split("\n").map((item,key)=>(<div key={key}>{item}</div>));
        else 
            return text;
    }
    
    render() {
        let candidate = this.state.candidate;
        if (candidate) {
            let cndName = candidate.personalInformation.firstName + " " + candidate.personalInformation.lastName;
            let recommendation = null;
            if (this.state.recommendation && this.state.recommendation.candidates.includes(candidate.accountIdentifier)) {
                recommendation = (
                    <div>
                        <p className="font-weight-bold mt-3">Recommended by {this.state.recommendation.hr.name} </p>
                        <p className="text-sec1 mt-1">"{this.state.recommendation.details}"</p>
                    </div>
                )
            }
            let studies = [];
            candidate.studies.forEach((study, index) => {
                let endingYear = (study.endingStudyYear) ? study.endingStudyYear : "present";
                studies.push((
                    <Card className="simple-card mb-3" key={index}>
                        <Card.Body className="py-3">
                            <div className="text-sec2 mb-2">{study.beginningStudyYear} - {endingYear}</div>
                            <div className="text-sec1 mb-2"><span><strong>{study.name}</strong></span></div>
                            <div className="text-sec1 py-1"><FontAwesomeIcon icon={faBuilding}
                                                                        className="mr-2"/> {study.school}</div>
                            <div className="text-sec1" >{this.splitText(study.studiesDescription)}</div>
                        </Card.Body>
                    </Card>
                ));
            });

            let workExperience = [];
            candidate.workExperience.forEach((work, index) => {
                let endingYear = (work.endingYear) ? work.endingYear : "present";
                workExperience.push((
                    <Card className="simple-card mb-3" key={index}>
                        <Card.Body className="py-3">
                            <div className="text-sec2 mb-2">{work.beginningYear} - {endingYear}</div>
                            <div className="font-weight-bold mb-2">{work.jobTitle}</div>
                            <div className="text-sec2 mb-2">{this.splitText(work.jobDescription)}</div>
                            <div className="text-sec1"><FontAwesomeIcon icon={faBuilding}
                                                                        className="mr-2"/> {work.companyName}</div>
                        </Card.Body>
                    </Card>
                ));
            });

            let categories = [];
            Object.keys(candidate.skills).map((category, index) => {
                let skillsList = candidate.skills[category].map((skill, i) => (
                    <Card className="simple-card mr-2 mb-2" key={i}>
                        <Card.Body className="px-3 py-2">{this.splitText(skill)}</Card.Body>
                    </Card>));
                categories.push(<div key={index} className="py-2 d-flex flex-column">
                    <p className="text-sec1 mb-2"><strong>{SkillCategories[category]} skills:</strong></p>
                    {skillsList}</div>
                );
            });

            return (
                <Modal size="lg" show={this.state.show} onHide={this.hideModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{cndName}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <CandidateStatus
                            shortlist={this.props.shortlist}
                            status={this.state.status}
                            candidate={this.state.candidate}
                            jobId={this.state.jobId}
                            showCreateInterview={this.showCreateInterview}
                        />
                        {recommendation}
                        <p className="font-weight-bold mt-3">Personal information</p>

                        <div className="d-flex align-items-center">
                            <Avatar name={cndName} img={candidate.personalInformation.profilePictureUrl} size={10}/>
                            <div className="ml-4">

                                <div className="cnd-pers-info-wrapper">
                                    <FontAwesomeIcon icon={faEnvelope}/>
                                    <div>{candidate.personalInformation.email}</div>
                                    <FontAwesomeIcon icon={faPhone}/>
                                    <div>{candidate.personalInformation.telephone}</div>
                                    <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                    <div
                                        hidden={!candidate.personalInformation.address}>{candidate.personalInformation.address}</div>
                                </div>

                                <div className="text-sec1 mt-3">
                                    <span><strong>Sex:</strong> {candidate.personalInformation.sex}</span>
                                    <span className="mx-2">|</span>
                                    <span><strong>Date of birth:</strong> {candidate.personalInformation.dateOfBirth}</span>
                                </div>

                                <div className="text-sec1 mt-3">
                                    <Button variant="link" onClick={() => this.showCV(candidate.cvLink)} className='p-0'
                                            hidden={!candidate.cvLink}>
                                        <FontAwesomeIcon icon={faDownload} className="mr-2"/>Download CV
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <p className="font-weight-bold mt-3">Studies</p>
                        {studies.length === 0 ? <p>No studies to show yet.</p> : null}
                        {studies}

                        <p className="font-weight-bold mt-3">Work Experience</p>
                        {workExperience.length === 0 ? <p>No work experience to show yet.</p> : null}
                        {workExperience}

                        <p className="font-weight-bold mt-3">Skills</p>
                        {candidate.skills === null ? <p>No skills to show yet.</p> : null}
                        <div className="d-flex flex-column">
                            {categories}</div>
                    </Modal.Body>

                    <Modal.Footer className="d-flex justify-content-end">
                        <Button variant="secondary" onClick={this.hideModal}>Close</Button>
                    </Modal.Footer>
                </Modal>
            );

        } else {
            return null;
        }
    }
}

CandidateInfo.contextType = AppContext;
export default CandidateInfo;
