import React from "react";
import { Button, Modal,  FormText } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from 'formik';
import { Form as FormFormik } from 'formik';
import * as Yup from 'yup';
import LoadingButton from "../Utils/LoadingButton";


const createSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, "Please enter a name at least 3 characters long")
        .required("Full name is required"),
    email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
    address: Yup.string()
        .required("Address is required"),
    password: Yup.string()
        .matches(/((?=.*[a-z])(?=.*\d)(?=.*[A-Z]).{8,20})/, "The password must contain at least 8 characters, of which one number, one uppercase and one lowercase letter.")
        .required("Password is required"),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], "Please make sure the new password and the confirmation match.")
        .required("Password confirmation is required")
});

class CreateHR extends React.Component {
    state = {
        hrToEdit: null,
        formValidated: false
    };

    componentDidUpdate(prevProps) {
        if (this.props.hrToEdit !== prevProps.hrToEdit) {
            let hrToEdit = (this.props.hrToEdit) ? JSON.parse(JSON.stringify(this.props.hrToEdit)) : null;
            this.setState({ hrToEdit: hrToEdit });
        }
    }

    shoudDisplayEditHR = () => {
        if (this.props.hrToEdit !== null) {
            return true;
        }
        return false;
    };

    handleSubmit = (fields) => {
        let hr={
            name: fields.name,
            email: fields.email,
            address: fields.address,
            password: fields.password,
            confirmPassword: fields.confirmPassword
        }
        this.setState({ formValidated: false });
        // this.props.saveHR(this.state.hrToEdit);
        this.props.saveHR(hr);
    };

    handleChange = (e) => {
        let target = e.target;
        this.setState(prevState => {
            let hrToEdit = { ...prevState.hrToEdit };
            hrToEdit[target.name] = target.value;
            return { hrToEdit: hrToEdit };
        });
    };

    render() {
        return (
            <Modal size="lg" show={this.shoudDisplayEditHR()} onHide={this.props.onHide}>
                {this.state.hrToEdit && 
                
                <Formik
                    initialValues={{ name: "", email: "", address: "", password: "", confirmPassword: "" }}
                    validationSchema={createSchema}
                    onSubmit={fields => (this.handleSubmit(fields))}
                    render={({ touched, errors }) => (

                        <FormFormik>
                            <Modal.Header closeButton>
                                <Modal.Title>{(this.props.createNew) ? "Create HR" : "Edit HR"}
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>

                                <div className="form-group">
                                    <label htmlFor="name">Full name</label>
                                    <Field
                                        name="name"
                                        className={`form-control ${touched.name && errors.name ? "is-invalid" : ""}`}
                                    />
                                    <ErrorMessage
                                        component="div"
                                        name="name"
                                        className="invalid-feedback"
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="email">Email address</label>
                                    <Field
                                        type="email"
                                        name="email"
                                        className={`form-control ${touched.email && errors.email ? "is-invalid" : ""}`}
                                    />
                                    <ErrorMessage
                                        component="div"
                                        name="email"
                                        className="invalid-feedback"
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="address">Address</label>
                                    <Field
                                        name="address"
                                        className={`form-control ${touched.address && errors.address ? "is-invalid" : ""}`}
                                    />
                                    <ErrorMessage
                                        component="div"
                                        name="address"
                                        className="invalid-feedback"
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <Field
                                        type="password"
                                        name="password"
                                        className={`form-control ${touched.password && errors.password ? "is-invalid" : ""}`}
                                    />
                                    <ErrorMessage
                                        component="div"
                                        name="password"
                                        className="invalid-feedback"
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="confirmPassword">Password confirmation</label>
                                    <Field
                                        type="password"
                                        name="confirmPassword"
                                        className={`form-control ${touched.confirmPassword && errors.confirmPassword ? "is-invalid" : ""}`}
                                    />
                                    <ErrorMessage
                                        component="div"
                                        name="confirmPassword"
                                        className="invalid-feedback"
                                    />
                                </div>
                                <FormText>The password must contain at least 8 characters, of which one number, one uppercase and one lowercase letter.</FormText>
                                
                            </Modal.Body>

                            <Modal.Footer>
                                <Button variant="secondary"  onClick={this.props.onHide}>Close</Button>
                                <LoadingButton className="mt-3 align-self-end" type={"submit"} text={"Save "} isLoading={this.props.isLoadingSaveHR}/>
                            
                            </Modal.Footer>
                        </FormFormik>
                    )}
                />}
            </Modal>
        );
    }
}

export default CreateHR;