import React from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";
import { AppContext } from "./AppContext";
import { URLS, COOKIES_EXP_DAYS } from "./Constants";

class Interceptor extends React.Component {
    constructor(props) {
        super(props);
        this.goToLogin = this.goToLogin.bind(this);
        let cmpThis = this;

        axios.interceptors.request.use((config) => {
            if (Cookies.get("token") === undefined && Cookies.get("refresh_token") === undefined) {

                cmpThis.context.update({
                    token: undefined,
                    refresh_token: undefined,
                    crtUserName: undefined,
                    role: undefined,
                    user: undefined,
                    userId: undefined
                });
                if (config.url === `${ URLS.JOBS }/jobs`) {
                    config.headers.Authorization = undefined;
                }
            }
            return config;
        }, (error) => {
            // Do something with request error
            return Promise.reject(error);
        });

        axios.interceptors.response.use(
            (response) => { return response },

            async (error) => {
                let tokenURL = `${ URLS.ACCOUNTS }/oauth/token`;
                if (error.response.status === 401 && error.config.url !== tokenURL) {
                    let requestData = new URLSearchParams();
                    requestData.append("refresh_token", this.context.refresh_token);
                    requestData.append("grant_type", "refresh_token");

                    try {
                        let response = await axios.post(tokenURL, requestData, {
                            headers: {
                                "Content-Type": "application/x-www-form-urlencoded",
                                "Authorization": "Basic " + window.btoa("WebClient:Parola123")
                            }
                        });

                        if (response.status === 200) {
                            Cookies.set("token", response.data.access_token, { expires: COOKIES_EXP_DAYS });
                            Cookies.set("refresh_token", response.data.refresh_token, { expires: COOKIES_EXP_DAYS });
                            cmpThis.context.update({
                                token: response.data.access_token,
                                refresh_token: response.data.refresh_token
                            });



                            let originalRequest = error.config;
                            originalRequest.headers.Authorization = `Bearer ${ response.data.access_token }`;
                            return axios(originalRequest);

                        } else {
                            this.goToLogin();
                        }
                    } catch (error) {
                        this.goToLogin();
                    }
                }
                return Promise.reject(error);
            }
        )
    }

    goToLogin() {
        Cookies.remove("token");
        Cookies.remove("refresh_token");
        Cookies.remove("role");
        Cookies.remove("userId");
        Cookies.remove("crtUserName");
        Cookies.remove("user");
        this.props.history.push({ pathname: "/login" });
    }

    render() {
        return this.props.children;
    }
}

Interceptor.contextType = AppContext;
export default withRouter(Interceptor);