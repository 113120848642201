import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Card } from "react-bootstrap";

const AssessmentCard = (props) => (
    <Card className="simple-card">
        <Card.Body className="d-flex align-items-center justify-content-between py-3">
            <div>
                <div className="font-weight-bold">{ props.assessment.name }</div>
                <div className="text-sec2">{ props.assessment.questions.length } questions</div>
            </div>
            <div>
                <Button variant="basic" className="btn-ic ml-2" onClick={ () => props.editAssessment(props.assessment) } title="Edit assessment">
                    <FontAwesomeIcon icon={ faEdit } />
                </Button>
                <Button variant="basic" className="btn-ic ml-2" onClick={ () => props.deleteAssessment(props.assessment.id) } title="Delete assessment">
                    <FontAwesomeIcon icon={ faTrash } />
                </Button>
            </div>
        </Card.Body>
    </Card>
);

export default AssessmentCard;
