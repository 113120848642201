import React from "react";
import Content from "./Content/Content";
import { AppContextProvider } from "./Utils/AppContext";
import Interceptor from "./Utils/Interceptor";
import Notifications from "./Utils/Notifications";
import "animate.css/animate.min.css";

class App extends React.Component {
    render() {
        return (
            <AppContextProvider>
                <Interceptor>
                    <Notifications />
                    <Content />
                </Interceptor>
            </AppContextProvider>
        );
    }
}

export default App;
