import React from "react";
import "./Footer.css";
import euLogo from "../Images/european-union.png";
import govLogo from "../Images/romanian-government.png";
import siLogo from "../Images/structural-instruments.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faLink } from "@fortawesome/free-solid-svg-icons";
import CookieConsent, { Cookies } from "react-cookie-consent";


const Footer = () => {
    return (<React.Fragment>
        <CookieConsent location="bottom"
                       buttonText="OK"
                       cookieName="enable_cookies"
                       style={{ background: "#2B373B" }}
                       buttonStyle={{ color: "#ffffff" ,background:"#1bab3a", marginRight:"2rem", marginTop:"auto", marginBottom:"auto"}}
                       expires={150}>
            <p className="text-xl font-weight-bold">This website uses cookies.</p>
            <p className="text-sm font-italic">We use cookies to personalise content and to analyse our traffic. You consent to our cookies if you continue to use this website.</p>
            <a href={"/cookieDeclaration"} >Details</a>
        </CookieConsent>
        <div className="footer d-flex flex-row justify-content-center align-items-center px-2">
            <div className="my-container d-flex justify-content-center flex-column flex-sm-row align-items-center pt-2">

                <div className="flex-1 d-flex text-sm-left text-center flex-column justify-center align-center">
                    <h5>Contact</h5>
                    <ul className="footer-ul text-sm-left text-center justify-center align-center">
                        <li className="footer-li">
                            <FontAwesomeIcon icon={faLink}/> <a href="https://www.hypertalk.net/" target="_blank"
                                                                className="footer-links"> hypertalk.net</a>
                        </li>
                        <li className="footer-li">
                            <FontAwesomeIcon icon={faPhone}/> +40 371 398 899
                        </li>
                        <li className="footer-li">
                            <FontAwesomeIcon icon={faEnvelope}/> contact@hypertalk.net
                        </li>
                    </ul>
                </div>
                <div className="flex-1 d-flex flex-column justify-content-center align-items-center">
                    <p className="images">
                        <img src={euLogo} alt="" className="round-header-logo"/>
                        <img src={govLogo} alt="" className="round-header-logo"/>
                        <img src={siLogo} alt="" className="round-header-logo"/>
                    </p>

                    <p>
                        2019 © HyperCareers. All rights reserved
                    </p>
                </div>
                <div className="flex-1 text-sm-right text-center">
                    <ul className="footer-ul text-sm-right text-center ">
                        <li className="footer-li"><a className="footer-links"
                                                     href={process.env.PUBLIC_URL+'/Documents/gdpr_en.pdf'} target='_blank' > GDPR</a></li>
                        <li className="footer-li"><a className="footer-links"
                                                     href={process.env.PUBLIC_URL+'/Documents/policy_en.pdf'} target='_blank'> Privacy Policy</a></li>
                        <li className="footer-li"><a className="footer-links"
                                                     href={"/cookieDeclaration"}> Cookie Declaration</a></li>
                        <li className="footer-li"><a className="footer-links"
                                                     href={process.env.PUBLIC_URL+'/Documents/terms_en.pdf'} target='_blank'> Terms and Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>


    </React.Fragment>);
};

export default Footer;
