import React from "react";
import "./Rating.css";
import starOff from "../Images/star-off.svg";
import starOn from "../Images/star-on.svg";

class Rating extends React.Component {
    state = { 
        rating: 0
    };

    componentDidMount() {
        let rating = (this.props.rating) ? this.props.rating : 0;
        if (rating < 0) rating = 0;
        if (rating > 5) rating = 5;
        this.setState({ rating: rating });
    }

    componentDidUpdate(prevProps) {
        if (this.props.rating !== prevProps.rating) {
            let rating = (this.props.rating) ? this.props.rating : 0;
            if (rating < 0) rating = 0;
            if (rating > 5) rating = 5;
            this.setState({ rating: rating });
        }
    }

    updateRating = (e) => {
        if (this.props.onRatingUpdate) {
            let newRating = Math.ceil(e.nativeEvent.offsetX / e.target.offsetWidth * 5);
            this.setState({ rating: newRating });
            this.props.onRatingUpdate(newRating);
        }
    };

    render() {
        let starsWrapperStyle = {};

        if (this.props.onRatingUpdate || this.props.rating) {
            if (this.props.onRatingUpdate) {
                starsWrapperStyle.cursor = "pointer";
            }

            return (
                <div className="position-relative" style={ starsWrapperStyle }>
                    <div className="d-flex" onClick={ this.updateRating }>
                        <img className="rating-star" src={ starOff } alt="" />
                        <img className="rating-star" src={ starOff } alt="" />
                        <img className="rating-star" src={ starOff } alt="" />
                        <img className="rating-star" src={ starOff } alt="" />
                        <img className="rating-star" src={ starOff } alt="" />
                    </div>
                    <div id="rating-stars-upper-wrapper" className="d-flex" style={ { width: this.state.rating * 20 + "%" } }>
                        <img className="rating-star" src={ starOn } alt="" />
                        <img className="rating-star" src={ starOn } alt="" />
                        <img className="rating-star" src={ starOn } alt="" />
                        <img className="rating-star" src={ starOn } alt="" />
                        <img className="rating-star" src={ starOn } alt="" />
                    </div>
                </div>
            );
        }

        return <div className="text-sec2">No rating added yet</div>;
    }
}

export default Rating; 
