import React from "react";
import axios from "axios";
import { Button, Form, Col, Modal, Card } from "react-bootstrap";
import Toggle from "react-toggle";
import EditAvatar from "../Utils/EditAvatar";
import { URLS } from "../Utils/Constants";
import { AppContext } from "../Utils/AppContext";
import { EventEmitter } from "../Utils/EventEmitter";
import LoadingButton from "../Utils/LoadingButton";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'; // ES6
class CreateJob extends React.Component {
    state = {
        jobToEdit: null,
        formValidated: false,
        isLoadingCreateJob: false
    };

    componentDidUpdate(prevProps) {
        if (this.props.jobToEdit !== prevProps.jobToEdit) {
            let jobToEdit = (this.props.jobToEdit) ? JSON.parse(JSON.stringify(this.props.jobToEdit)) : null;
            this.setState({ jobToEdit: jobToEdit });
        }
    }

    shouldDisplayModal = () => {
        return (this.props.jobToEdit) ? true : false;
    };

    handleChange = (e) => {

        let target = e.target;
        this.setState(prevState => {
            let updatedJob = JSON.parse(JSON.stringify(prevState.jobToEdit));
            console.log(typeof (target.value))
            if (target.name === "skill") {
                updatedJob.skillset[target.dataset.id] = target.value;
            } else
                if (target.name === "workExperience") {
                    updatedJob[target.name] = parseInt(target.value);
                }else {
                    updatedJob[target.name] = (target.name === "enabled") ? target.checked : target.value;
            }
            return { jobToEdit: updatedJob };
        });
    };

    handleChangeDescription = (html) => {
        this.setState(prevState => {
            let updatedJob = JSON.parse(JSON.stringify(prevState.jobToEdit));
                updatedJob['description'] = html;
            return { jobToEdit: updatedJob };
        })
    };

    handleSubmit = async (e) => {
        this.setState({ isLoadingCreateJob: true });
        console.log(this.state.isLoadingCreateJob);
        e.preventDefault();
        if (e.target.checkValidity()) {
            this.setState({ formValidated: false });
            try {
                let headers = { "Authorization": `Bearer ${this.context.token}` };
                let response;

                if (this.props.shouldCreateNewJob) {
                    response = await axios.post(`${URLS.JOBS}/jobs`, this.state.jobToEdit, { headers: headers });
                } else {
                    response = await axios.put(`${URLS.JOBS}/jobs/${this.state.jobToEdit.id}`, this.state.jobToEdit, { headers: headers });
                }

                if ([200, 201].includes(response.status)) {
                    this.onHide();
                    this.props.getJobs();
                    let alertMsg = (response.status === 201) ? "New job opening successfully created." : "Job opening successfully updated.";
                    EventEmitter.emit("showNotification", "success", alertMsg);

                    this.setState({ isLoadingCreateJob: false });
                }

            } catch (error) {
                EventEmitter.emit("showNotification", "danger", "Failed to save job opening");
                this.setState({ isLoadingCreateJob: false });
            }

        } else {
            this.setState({ formValidated: true });
            EventEmitter.emit("showNotification", "warning", "The entered data seems to be incorrect or incomplete.");
            this.setState({ isLoadingCreateJob: false });
        }
    }

    setAvatar = (value) => {
        this.setState(prevState => {
            let jobToEdit = prevState.jobToEdit;
            jobToEdit.logo = value;
            return { jobToEdit: jobToEdit };
        });
    };

    onHide = () => {
        this.setState({ formValidated: false });
        this.props.closeEditJobModal();
    };

    addSkill = () => {
        this.setState(prevState => {
            let updatedJob = JSON.parse(JSON.stringify(prevState.jobToEdit));
            updatedJob.skillset.push("");
            return { jobToEdit: updatedJob };
        });
    };

    deleteSkill = (e) => {
        let target = e.target;
        this.setState(prevState => {
            let updatedJob = JSON.parse(JSON.stringify(prevState.jobToEdit));
            updatedJob.skillset.splice(target.dataset.id, 1);
            return { jobToEdit: updatedJob };
        });
    }

    render() {
        let jobInfoForm = null;
        let jobStatusToggle = null;
        let skillsInput = null;
        if (this.state.jobToEdit) {

            let skillsList = this.state.jobToEdit.skillset.map((skill, index) => (
                <Card className="simple-card mb-3" key={index}>
                    <Card.Body className="d-flex align-items-center py-3">
                        <Form.Control
                            name="skill"
                            data-id={index}
                            value={skill}
                            onChange={this.handleChange}
                            className="flex-1 skill-field"
                            required
                        />
                        <Button variant="outline-primary" className="ml-2" data-id={index} onClick={this.deleteSkill}>
                            Delete
                        </Button>
                    </Card.Body>
                </Card>
            ));

            skillsInput = (
                <React.Fragment>
                    <div className="d-flex justify-content-between">
                        <Form.Label>Required skills</Form.Label>
                        <Form.Text className="text-sec2">Optional</Form.Text>
                    </div>
                    <div className="d-flex justify-content-end">
                        <Button variant="link" className="p-0 mb-2" onClick={this.addSkill}>Add skill</Button>
                    </div>
                    {skillsList}
                </React.Fragment>
            );

            jobInfoForm = (
                <React.Fragment>
                    <div className="d-flex align-items-center">
                        <EditAvatar
                            base64
                            name={this.state.jobToEdit.companyName}
                            img={this.state.jobToEdit.logo}
                            updateAvatar={this.setAvatar}
                        />

                        <div className="ml-4 flex-1">
                            <Form.Group>
                                <Form.Label>Company name</Form.Label>
                                <Form.Control name="companyName" type="text" onChange={this.handleChange} value={this.state.jobToEdit.companyName} required pattern=".{3,}" />
                                <Form.Control.Feedback type="invalid">Please enter a company name containing at least 3 characters.</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Posting title</Form.Label>
                                <Form.Control name="postingTitle" type="text" onChange={this.handleChange} value={this.state.jobToEdit.postingTitle} required pattern=".{3,}" />
                                <Form.Control.Feedback type="invalid">Please enter a posting title containing at least 3 characters.</Form.Control.Feedback>
                            </Form.Group>
                        </div>
                    </div>

                    <Form.Group>
                        <Form.Label>Job address</Form.Label>
                        <Form.Control name="address" type="text" onChange={this.handleChange} value={this.state.jobToEdit.address} required />
                        <Form.Control.Feedback type="invalid">Please enter a job address.</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Row>
                        <Form.Group as={Col} md="6">
                            <Form.Label>Job type</Form.Label>
                            <Form.Control as="select" name="type" onChange={this.handleChange} value={this.state.jobToEdit.type} required>
                                <option value="" hidden>Choose...</option>
                                <option value="Full Time">Full Time</option>
                                <option value="Part Time">Part Time</option>
                                <option value="Internship">Internship</option>
                                <option value="Temporary">Temporary</option>
                                <option value="Permanent">Permanent</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">Please select a job type.</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="6">
                            <div className="d-flex justify-content-between">
                                <Form.Label>Salary</Form.Label>
                                <Form.Text className="text-sec2">Optional</Form.Text>
                            </div>
                            <Form.Control name="salary" type="text" onChange={this.handleChange} value={this.state.jobToEdit.salary} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Group>
                        <div className="d-flex justify-content-between">
                            <Form.Label>Description</Form.Label>
                            <Form.Text className="text-sec2">Optional</Form.Text>
                        </div>
                        <ReactQuill
                            name="description"
                            theme="snow"
                            onChange={this.handleChangeDescription}
                            value={this.state.jobToEdit.description}
                            modules={CreateJob.modules}
                            formats={CreateJob.formats}
                        />
                    </Form.Group>

                    <Form.Group>
                        <div className="d-flex justify-content-between">
                            <Form.Label>Required work experience</Form.Label>
                            {/*<Form.Text className="text-sec2">Optional</Form.Text>*/}
                        </div>
                        {/*<Form.Control name="workExperience" type="text" onChange={this.handleChange} value={this.state.jobToEdit.workExperience} />*/}
                        <Form.Control as="select" name="workExperience" onChange={this.handleChange} value={this.state.jobToEdit.workExperience} required>
                            {/*{![0,1,2,3,5,10].includes(this.state.jobToEdit.workExperience) && (*/}
                            {/*    <option value={this.state.jobToEdit.workExperience} hidden>*/}
                            {/*        {this.state.jobToEdit.workExperience}*/}
                            {/*    </option>*/}
                            {/*) }*/}
                                <option value={0}> No experience required</option>
                                <option value={1}> 1+ years experience</option>
                                <option value={2}> 2+ years experience</option>
                                <option value={3}> 3+ years experience</option>
                                <option value={5}> 5+ years experience</option>
                                <option value={10}> 10+ years experience</option>
                            }

                            {/*<option value="NO_UNIVERSITY">No studies required</option>*/}
                            {/*<option value="UNIVERSITY">University studies required</option>*/}
                        </Form.Control>
                    </Form.Group>

                    {skillsInput}
                </React.Fragment>
            );

            jobStatusToggle = (
                <div className="d-flex align-items-center">
                    <div className="mr-2">Available</div>
                    <Toggle name="enabled" checked={this.state.jobToEdit.enabled} onChange={this.handleChange} />
                </div>
            );
        }

        let modalTitle = (this.props.shouldCreateNewJob) ? "Create job opening" : "Edit job opening";

        return (
            <Modal size="lg" show={this.shouldDisplayModal()} onHide={this.onHide}>
                <Form onSubmit={this.handleSubmit} noValidate validated={this.state.formValidated}>
                    <Modal.Header closeButton>
                        <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {jobInfoForm}
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        {jobStatusToggle}
                        <div>
                            <Button variant="secondary" className="mx-2" onClick={this.onHide}>Cancel</Button>
                            {/* <Button variant="primary" type="submit">Save</Button> */}
                            <LoadingButton type={"submit"} text={"Save"} isLoading={this.state.isLoadingCreateJob}/>

                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}

CreateJob.modules = {
    toolbar: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'},
            {'indent': '-1'}, {'indent': '+1'}]
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
}
CreateJob.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent'
]

CreateJob.contextType = AppContext;
export default CreateJob;
