import React from "react";
import {Card, Form, Button, Col} from "react-bootstrap";

class ProfileWorkCard extends React.Component {
    render() {

        let validBeginningYear = new Date().getFullYear() - 80;
        let validEndingYear = new Date().getFullYear() - 75;
        let currentYear = new Date().getFullYear();

        return (
            <Card className="simple-card mb-3">
                <Card.Body>
                    <div className="d-flex align-items-start justify-content-between">
                        <Form.Row>
                            <Form.Group as={Col} md="6">
                                <Form.Label>Beginning year</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="beginningYear"
                                    value={this.props.work.beginningYear}
                                    onChange={this.props.onChange}
                                    data-id={this.props.index}
                                    className="work-field"
                                    min={validBeginningYear}
                                    max={currentYear}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">Please enter the beginning
                                    year.</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="6">
                                <Form.Label>Ending year</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="endingYear"
                                    value={this.props.work.endingYear}
                                    onChange={this.props.onChange}
                                    data-id={this.props.index}
                                    className="work-field"
                                    min={validEndingYear}
                                    max={currentYear}
                                />
                                <Form.Text>Leave empty if still ongoing</Form.Text>
                            </Form.Group>
                        </Form.Row>
                        <Button variant="outline-primary" className="ml-2" data-id={this.props.index}
                                onClick={this.props.delete}>
                            Delete
                        </Button>
                    </div>

                    <Form.Group>
                        <Form.Label>Company</Form.Label>
                        <Form.Control
                            name="companyName"
                            value={this.props.work.companyName}
                            onChange={this.props.onChange}
                            data-id={this.props.index}
                            className="work-field"
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter the name of the company
                            you {(this.props.work.endingYear) ? "have worked" : "are working"} for.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Job title</Form.Label>
                        <Form.Control
                            name="jobTitle"
                            value={this.props.work.jobTitle}
                            onChange={this.props.onChange}
                            data-id={this.props.index}
                            className="work-field"
                            required
                        />
                        <Form.Control.Feedback type="invalid">Please enter your job title.</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <div className="d-flex justify-content-between">
                            <Form.Label>Job description</Form.Label>
                            <Form.Text className="text-sec2">Optional</Form.Text>
                        </div>
                        <Form.Control
                            as="textarea"
                            name="jobDescription"
                            value={this.props.work.jobDescription}
                            onChange={this.props.onChange}
                            data-id={this.props.index}
                            className="work-field"
                        />
                    </Form.Group>
                </Card.Body>
            </Card>
        );
    }
}

export default ProfileWorkCard;
