import React from "react";
import axios from "axios";
import { faAddressCard, faCalendarCheck, faCheckCircle, faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { faCalendarPlus, faHourglassHalf, faPlus, faRedo, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup } from "react-bootstrap";
import { AppContext } from "../Utils/AppContext";
import { URLS } from "../Utils/Constants";
import { EventEmitter } from "../Utils/EventEmitter";

class CandidateStatus extends React.Component {
    updateStatus = async (status) => {
        try {
            const response = await axios.put(`${ URLS.JOBS }/jobs/${ this.props.jobId }/candidates/${ this.props.candidate.accountIdentifier }`, { status: status }, {
                headers: { "Authorization": `Bearer ${ this.context.token }` }
            });
            if (response.status === 200) {
                EventEmitter.emit("getCandidates");
                EventEmitter.emit("candidateStatusChanged", this.props.candidate.accountIdentifier, this.props.jobId, status);
                if (status === "DENIED") {
                    EventEmitter.emit("removeCandidateFromShortlist", this.props.candidate);
                }
                EventEmitter.emit("showNotification", "success", "Candidate status updated successfully.");
            } else {
                EventEmitter.emit("showNotification", "danger", "Failed to change status of candidate.");
            }
        } catch (error) {
            EventEmitter.emit("showNotification", "danger", "Failed to change status of candidate.");
        }
    }

    addToShortlist(candidate) {
        EventEmitter.emit("addCandidateToShortlist", candidate);
    }

    render() {
        let isCompany = this.context.role === "COMPANY_ROLE";
        let isHR = this.context.role === "HR_ROLE";
        let createInterviewBtn = (
            <Button key={ 0 } variant="outline-secondary" onClick={ () => this.props.isCandidateWithJob? this.props.showCreateInterview(this.props.candidate.accountIdentifier, this.props.jobId,true)
                : this.props.showCreateInterview(this.props.candidate.accountIdentifier, this.props.jobId)  }>
                <FontAwesomeIcon className="mr-2" icon={ faCalendarPlus } />Interview
            </Button>
        );

        let acceptBtn = isCompany ? (
            <Button key={ 1 } variant="outline-secondary" onClick={ () => this.updateStatus("ACCEPTED") }>
                Accept
            </Button>
        ) : null;

        let denyBtn = (
            <Button key={ 2 } variant="outline-secondary" onClick={ () => this.updateStatus("DENIED") }
                title="Deny candidate">
                <FontAwesomeIcon icon={ faTrash } />
            </Button>
        );

        let shortListBtn = null;
        if (isHR && this.props.shortlist) {
            let disableShortlistBtn = false;
            if (this.context.shortlists && this.context.shortlists[this.props.jobId]) {
                disableShortlistBtn = this.context.shortlists[this.props.jobId].map(cnd => cnd.accountIdentifier).includes(this.props.candidate.accountIdentifier);
            }
            shortListBtn = (
                <Button key={ 3 } variant="outline-secondary" onClick={ () => this.addToShortlist(this.props.candidate) } disabled={ disableShortlistBtn }>
                    <FontAwesomeIcon className="mr-2" icon={ faPlus } />Shortlist
                </Button>
            );
        }

        let actions = [];
        let statusIcon;
        let statusIconClassName = "candidate-status-icon ";
        switch (this.props.status) {
            case "APPLIED":
                actions = [acceptBtn, shortListBtn, createInterviewBtn, denyBtn];
                statusIcon = faAddressCard;
                statusIconClassName += "candidate-status-icon-applied";
                break;

            case "SCHEDULED":
                actions = [acceptBtn, shortListBtn, denyBtn];
                statusIcon = faCalendarCheck;
                statusIconClassName += "candidate-status-icon-scheduled";
                break;

            case "REJECTED":
                actions = [denyBtn];
                statusIcon = faTimesCircle;
                statusIconClassName += "candidate-status-icon-rejected";
                break;

            case "REFUSED":
                actions = [denyBtn];
                statusIcon = faTimesCircle;
                statusIconClassName += "candidate-status-icon-refused";
                break;

            case "PENDING":
                actions = [acceptBtn, shortListBtn, createInterviewBtn, denyBtn];
                statusIcon = faHourglassHalf;
                statusIconClassName += "candidate-status-icon-pending";
                break;

            case "ACCEPTED":
                statusIcon = faCheckCircle;
                statusIconClassName += "candidate-status-icon-accepted";
                break;

            case "RECHECK":
                actions = [acceptBtn, shortListBtn, createInterviewBtn, denyBtn];
                statusIcon = faRedo;
                statusIconClassName += "candidate-status-icon-recheck";
                break;

            case "SCHEDULED_LIVE":
                actions = [acceptBtn, shortListBtn, denyBtn];
                statusIcon = faCalendarCheck;
                statusIconClassName += "candidate-status-icon-scheduled";
                break;

            default:
                statusIcon = null;
        }
        return (
            <div className="d-flex align-items-center justify-content-between ">
                <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={ statusIcon } className={ statusIconClassName } />
                    <select
                        className="ml-2 candidate-status border-0"
                        name="candidateStatus"
                        onChange={ (e) => this.updateStatus(e.target.value) }
                        value={ this.props.status }
                    >
                        <option value="ACCEPTED" hidden={ true }>Accepted</option>
                        <option value="PENDING">Pending interview</option>
                        <option value="SCHEDULED">Scheduled recorded interview</option>
                        <option value="SCHEDULED_LIVE">Scheduled live interview</option>
                        <option value="REFUSED">Refused interview</option>
                        <option value="REJECTED">Rejected</option>
                        <option value="RECHECK" hidden={ !isCompany }>Re-check candidate</option>
                        <option value="APPLIED" hidden={ true }>Applied</option>
                    </select>
                </div>
                <ButtonGroup>{ actions }</ButtonGroup>
            </div>
        );
    }
}

CandidateStatus.contextType = AppContext;
export default CandidateStatus;
