import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import Avatar from "./Avatar";
import "./EditAvatar.css";

class EditAvatar extends React.Component {
    onChange = (e) => {
        if (e.target.files[0]) {
            if (this.props.base64) {
                let reader = new FileReader();
                reader.readAsDataURL(e.target.files[0]);
                reader.onloadend = () => {
                    this.props.updateAvatar(reader.result);
                }
            } else {
                this.props.updateAvatar(e.target.files[0]);
            }
        }
    };

    render() {
        let deleteButton = null;
        if (this.props.base64){
            deleteButton = (
                <Button variant="basic" className="btn-ic ml-2" onClick={ () => this.props.updateAvatar("") } title="Delete">
                    <FontAwesomeIcon icon={ faTrash } />
                </Button>
            );
        }

        return (
            <div className="avatar-wrapper">
                <Avatar name={ this.props.name } img={ this.props.img } size={ 10 } />
                <div className="avatar-actions-wrapper">
                    <label htmlFor="avatar-upload-input" className="btn btn-basic btn-ic m-0" title="Edit" style={ { "cursor": "pointer" } } tabIndex="0">
                        <FontAwesomeIcon icon={ faEdit } />
                    </label>
                    <input id="avatar-upload-input" type="file" accept="image/jpeg, image/png" onChange={ this.onChange } hidden tabIndex="-1" />
                    { deleteButton }
                </div>
            </div>
        );
    }
}

export default EditAvatar;
