import React from "react";
import { Form, Button, Modal, Col, Row } from "react-bootstrap";
import { EventEmitter } from "../Utils/EventEmitter";
import axios from "axios";
import { URLS } from "./Constants";
import { AppContext } from "./AppContext";
import {moment} from "moment";

class NotesModal extends React.Component {
    state = {
        notes: [],
        textNotes: "",
        changed: false,
        show: false
    };

    componentDidMount() {
        EventEmitter.on("showNotesModal", this.showModal);
        EventEmitter.on("closeNotesModal", this.onClose);
    }

    showModal = async (jobId, candidateId) => {
        if (jobId !== undefined && candidateId !== undefined) {
            try {
                let response = await axios.get(`${ URLS.JOBS }/jobs/${ jobId }/candidates/${ candidateId }/notes`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${ this.context.token }`
                    },
                    data: "none"
                });
                if (response.status === 200) {
                    this.setState({
                        notes: response.data,
                        show: true,
                        jobId: jobId,
                        candidateId: candidateId
                    });
                }

            } catch (e) {
                EventEmitter.emit("showNotification", "danger", "Failed to load candidate notes.");
            }
        }
    };

    addNotes = async (e) => {
        e.preventDefault();
        if ((this.state.textNotes !== undefined) && (this.state.jobId !== undefined) && (this.state.candidateId !== undefined)) {
            try {
                let note = {
                    "candidateId": this.state.candidateId,
                    "jobId": this.state.jobId,
                    "notes": this.state.textNotes
                };
                let response = await axios.post(`${ URLS.JOBS }/jobs/${ this.state.jobId }/candidates/${ this.state.candidateId }/notes`, note, {
                    headers: { "Authorization": `Bearer ${ this.context.token }` }
                });
                if (response.status === 201) {
                    this.showModal(this.state.jobId, this.state.candidateId);
                    this.refs.NotesInput.value = "";
                    EventEmitter.emit("showNotification", "success", "Candidate note posted successfully.");
                } else {
                    EventEmitter.emit("showNotification", "danger", "Failed to post candiate note.");
                }

            } catch (e) {
                EventEmitter.emit("showNotification", "danger", "Failed to post candiate note.");
            }
        }
    };

    onNotesChanged = (e) => {
        this.setState({
            notes: e.target.value,
            changed: true
        });
    };

    handleNotes = (e) => {
        const notes = e.target.value;
        this.setState({ textNotes: notes })
    };

    onClose = () => {
        this.setState({ show: false });
    };

    render() {
        let notesHistory = [];
        if (this.state.notes !== undefined) {
            this.state.notes.forEach((note, index) => {
                let date = new Date(note.createdAt * 1000);
                let formattedDate = moment(date).format("DD/MM/YYYY HH:mm");
                notesHistory.push(
                    <li key={ index }>
                        <span className="text-sec2 mr-2">{ formattedDate }</span>
                        <span>{ note.notes }</span>
                    </li>
                );
            });
        }
        return (
            <Modal size="lg" show={ this.state.show } onHide={ this.onClose }>
                <Modal.Header closeButton>
                    <Modal.Title>Notes</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form className="mt-2" onSubmit={ this.addNotes }>
                        <Row>
                            <Col>
                                <ul>{ notesHistory }</ul>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <textarea ref={ "NotesInput" } id={ "NotesInput" } placeholder={ "Add a new note" } rows={ 3 }
                                    cols={ 80 } onChange={ this.handleNotes } />
                                {/*<textarea placeholder={"Add a new note"} value={this.state.textNotes} rows={3} cols={50} onChange={this.handleNotes}/>*/ }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button variant="primary" type="submit">Save note</Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>

                {/*<Modal.Footer>*/ }
                {/*    <Button variant="secondary" onClick={this.onClose}>Close</Button>*/ }
                {/*</Modal.Footer>*/ }
            </Modal>
        );
    }
}

NotesModal.contextType = AppContext;
export default NotesModal;
