import React from "react";
import {AppContext} from "../Utils/AppContext";
import {useContext} from "react";
import {useRouteMatch, withRouter} from "react-router-dom";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {useHistory, useLocation} from "react-router";


const LiveInterviewFrame = ({props}) => {
    const context = useContext(AppContext);
    let liveInterview = useLocation().state.liveInterview;
    let index = liveInterview.participants.map((participant) => {
        return participant.email;
    }).indexOf(context.user.email);
    let token = liveInterview.participants[index].access_token;
    let data=btoa(JSON.stringify({
        name: liveInterview.title,
        id: liveInterview.activity_id,
        email: context.user.email,
        token: token,
        language: 'en'
    }));
    let link='https://htstaging-player.hypertalk.net/?activity='+data;
    console.log(link);
    const history = useHistory();
    const gotoInterviewsList = () => {
        history.push({pathname:'/interviews'});
    };

    return (
        <div className="container page-container">
            <div className="d-flex justify-content-between align-items-center">
                <h1 className="page-title">Live interview record</h1>
                <Button variant="outline-primary" onClick={gotoInterviewsList}>
                    <FontAwesomeIcon icon={ faChevronLeft } /> Interviews list
                </Button>
            </div>
            <iframe className="recorded-interview-iframe" src={link}></iframe>
        </div>
    );
};
export default withRouter(LiveInterviewFrame);
