import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const EmptyListInfo = ({icon, width, text}) => {
    const imgStyle = {
        width,
        maxWidth: '100%'
    };

    return (
        <Row>
            <Col
                className=" d-flex align-items-center justify-content-end"
                xs="5"
                lg="3"
            >
                <img src={icon} alt="" className="p-3" style={imgStyle}/>
            </Col>
            <Col
                className="d-flex align-items-center justify-content-start"
                xs="7"
                lg="9"
            >
                {text}
            </Col>
        </Row>
        // <div className="d-flex flex-row justify-content-center">
        //
        //     <p className="align-self-center">{text}</p>
        // </div>
    );
};

export default EmptyListInfo;