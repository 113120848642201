import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Card, CardGroup } from "react-bootstrap";
import { AppContext } from "../Utils/AppContext";
import selection from "../Images/selection.svg"
import briefcase from "../Images/briefcase.svg"
import login from "../Images/smartphone.svg"
import teamwork from "../Images/teamwork.svg"
import "./Home.css";

class Home extends React.Component {
    gotoJobs = () => {
        this.props.history.push("/jobOpenings");
    };
    gotoCandidates = () => {
        this.props.history.push("/candidates");
    };
    gotoLogin = () => {
        this.props.history.push("/login");
    };
    gotoInterview = () => {
        this.props.history.push("/interviews");
    };

    render() {
        let isHR = this.context.role === "HR_ROLE";
        let isCompany = this.context.role === "COMPANY_ROLE";
        let isCandidate = this.context.role === "CANDIDATE_ROLE";

        let introSubtitle = (isHR || isCompany) ? "Post your jobs and find suitable candidates" : "Check out our app and explore new opportunities to improve your career!";

        let leftCardTitle = (isHR || isCompany) ? "Start a new job" : "View all the job openings";
        let leftCardContent = (isHR || isCompany) ? "Post a new job and get started pre-scanning your candidates" : "See all available jobs and apply for the one that fits your abilities the most!";
        let leftCardBtn = (
            <Button variant="dark" onClick={ this.gotoJobs } className="rounded-item mt-3">
                { isHR || isCompany ? "Start a new job" : "Apply for a job" }
            </Button>
        );

        let rightCardTitle = (isHR || isCompany) ? "Check your candidates" : (isCandidate) ? "Manage your interviews" : "Log in and apply to your dream job";
        let rightCardContent = "Apply to a job and respond to online interviews";
        let rightCardBtn = (<Button variant="dark" onClick={ this.gotoLogin } className="rounded-item mt-3">Log in</Button>);
        if (isCompany || isHR) {
            rightCardContent = (
                <ul className={ "text-left" }>
                    <li>Review and manage your existing interviews</li>
                    <li>Video interview</li>
                    <li>View scheduled interviews</li>
                </ul>
            );
            rightCardBtn = (<Button variant="dark" onClick={ this.gotoCandidates } className="rounded-item mt-3" >Check candidates </Button>);

        } else if (isCandidate) {
            rightCardBtn = (<Button variant="dark" onClick={ this.gotoInterview } className="rounded-item mt-3">Check your interviews</Button>);
        }

        return (
            <React.Fragment>
                <div className="bg-container"></div>
                <div className="homepage-content">
                    <div className="homepage-intro">
                        <h1>Welcome!</h1>
                        <h2>{ introSubtitle }</h2>
                    </div>

                    <CardGroup className="intro-cards">
                        <Card className="transp-card">
                            <Card.Body className="d-flex flex-column justify-content-between align-items-center">
                                <div>
                                    <Card.Title>{ leftCardTitle }</Card.Title>
                                    <img src={ briefcase } className="my-3" alt="" />
                                    <div>{ leftCardContent } </div>
                                </div>
                                { leftCardBtn }
                            </Card.Body>
                        </Card>

                        <Card className="transp-card">
                            <Card.Body className="d-flex flex-column justify-content-between align-items-center">
                                <div>
                                    <Card.Title>{ rightCardTitle }</Card.Title>
                                    <img src={ isHR || isCompany ? selection : isCandidate ? teamwork : login } className="my-3" alt="" />
                                    <div>{ rightCardContent }</div>
                                </div>
                                { rightCardBtn }
                            </Card.Body>
                        </Card>
                    </CardGroup>
                </div>
            </React.Fragment>
        );
    }
}

Home.contextType = AppContext;
export default withRouter(Home);