import React from "react";
import "./VideoPlayer.css";
import {setEncryptedEvent} from "../Utils/Decrypt";

class VideoPlayer extends React.Component {
    // constructor(props){
    //     super(props);
    //     this.videoRef = React.createRef();
    //     this.state={
    //         addedEncoderHandler: false
    //     }
    // }
    // componentDidUpdate() {
    //     if (this.videoRef.current && !this.state.addedEncodedHandler) {
    //         setEncryptedEvent(this.videoRef.current);
    //         this.setState({ addedEncodedHandler: true });
    //     }
    //     if (!this.videoRef.current && this.state.addedEncodedHandler) {
    //         this.setState({addedEncodedHandler: false});
    //     }
    //
    // }

    render() {
        return (
            <div>
                {/*<video className="player-video" src={ this.props.videoSrc } controls crossOrigin='true' ref={ this.videoRef }/>*/}
                <video className="player-video" src={ this.props.videoSrc } controls></video>
            </div>
        );
    }
}

export default VideoPlayer;
