import React from "react";
import { Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faEnvelope, faTrash } from "@fortawesome/free-solid-svg-icons";
import CreateHR from "./CreateHR";
import Avatar from "../Utils/Avatar";
import "./ManageHR.css";

class HRList extends React.Component {
    state = {
        hrToSee: null
    };

    shouldDisplayHR = (hr) => {
        if (hr.name.includes(this.props.searchTerm) || hr.email.includes(this.props.searchTerm)) {
            return true;
        }
        return false;
    };

    render() {
        let hrContent;

        if (this.props.hrs) {
            let hrList = [];
            this.props.hrs.forEach((hr, index) => {
                hrList.push(
                    <Card className="simple-card" key={ index } hidden={ !this.shouldDisplayHR(hr) }>
                        <Card.Header className="d-flex align-items-center">
                            <Avatar name={ hr.name } />
                            <div className="ml-3">
                                <div className="font-weight-bold">{ hr.name }</div>
                                <div>{ hr.company }</div>
                            </div>
                        </Card.Header>
                        <Card.Body className="py-3">
                            <div className="d-flex align-items-end justify-content-between">
                                <div className="hr-info-grid">
                                    <FontAwesomeIcon icon={ faEnvelope } />
                                    <div className="mx-2">{ hr.email }</div>
                                    <FontAwesomeIcon icon={ faMapMarkerAlt } />
                                    <div className="mx-2">{ hr.address }</div>
                                </div>
                                <div>
                                    <Button variant="basic" className="btn-ic ml-2" onClick={ () => this.props.deleteHR(hr.id) } title="Delete HR">
                                        <FontAwesomeIcon icon={ faTrash } />
                                    </Button>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                );
            });
            hrContent = <div className="items-grid">{ hrList }</div>;
        } else {
            hrContent = <div>You do not have any HR specialists yet.</div>;
        }

        return (
            <div>
                { hrContent }
                <CreateHR createNew={ false } hrToEdit={ this.state.hrToSee } closeEditJobModal={ () => { this.setState({ hrToSee: null }) } }></CreateHR>
            </div>
        );
    }
}

export default HRList;
