import React from "react";
import axios from "axios";
import { Modal, Card, Form, Col, Button } from "react-bootstrap";
import { AppContext } from "../Utils/AppContext";
import AnswerVariantsManager from "./AnswerVariantsManager";
import { URLS } from "../Utils/Constants";
import { EventEmitter } from "../Utils/EventEmitter";
import LoadingButton from "../Utils/LoadingButton";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'; // ES6

class CreateAssessment extends React.Component {
    state = {
        assessmentToEdit: null,
        formValidated: false,
        isLoadingSaveAssessment: false
    };

    componentDidUpdate(prevProps) {
        if (this.props.assessmentToEdit !== prevProps.assessmentToEdit) {
            let assessment = (this.props.assessmentToEdit) ? JSON.parse(JSON.stringify(this.props.assessmentToEdit)) : null;
            this.setState({ assessmentToEdit: assessment });
        }
    }

    shouldDisplayModal = () => {
        return (this.props.assessmentToEdit) ? true : false;
    };

    checkAnswerVariantsCount = () => {
        for (let question of this.state.assessmentToEdit.questions) {
            if (["singleChoice", "multipleChoice"].includes(question.questionType) && question.Answer.length < 2) {
                return false;
            }
        }
        return true;
    };

    handleSave = async (e) => {
        this.setState({ isLoadingSaveAssessment: true });
        e.preventDefault();
        if (e.target.checkValidity() && this.checkAnswerVariantsCount()) {
            this.setState({ formValidated: false });
            try {
                let headers = { "Authorization": `Bearer ${this.context.token}` };
                let response;

                if (this.props.shouldCreateNewAssessment) {
                    response = await axios.post(`${URLS.JOBS}/assessment`, this.state.assessmentToEdit, { headers: headers });
                } else {
                    response = await axios.put(`${URLS.JOBS}/assessment/${this.state.assessmentToEdit.id}`, this.state.assessmentToEdit, { headers: headers });
                }

                if ([200, 201].includes(response.status)) {
                    this.onHide();
                    this.props.getAssessments();
                    let alertMsg = (response.status === 201) ? "New assessment successfully created." : "Assessment successfully updated.";
                    EventEmitter.emit("showNotification", "success", alertMsg);
                    this.setState({ isLoadingSaveAssessment: false });
                } else {
                    EventEmitter.emit("showNotification", "danger", "Failed to save assessment.");
                    this.setState({ isLoadingSaveAssessment: false });
                }

            } catch (error) {
                EventEmitter.emit("showNotification", "danger", "Failed to save assessment.");
                this.setState({ isLoadingSaveAssessment: false });
            }

        } else {
            EventEmitter.emit("showNotification", "warning", "The entered data seems to be incorrect or incomplete.");
            this.setState({ formValidated: true });
            this.setState({ isLoadingSaveAssessment: false });
        }
    };

    handleChange = (e) => {
        let target = e.target;
        this.setState(prevState => {
            let assessmentToEdit = JSON.parse(JSON.stringify(prevState.assessmentToEdit));
            if (target.name === "name") {
                assessmentToEdit.name = target.value;
            } else if ([ "responseTime", "questionType"].includes(target.name)) {
                if (target.name === "questionType") {
                    if (Array.isArray(assessmentToEdit.questions[target.dataset.id].Answer)) {
                        for (let ansVariant of assessmentToEdit.questions[target.dataset.id].Answer) {
                            ansVariant.correct = false;
                        }
                    } else {
                        assessmentToEdit.questions[target.dataset.id].Answer = [];
                    }
                }
                assessmentToEdit.questions[target.dataset.id][target.name] = target.value;
            }
            return { assessmentToEdit: assessmentToEdit };
        });
    };

    handleChangeQuestionText = (index,event) => {
        this.setState(prevState => {
            let assessmentToEdit = JSON.parse(JSON.stringify(prevState.assessmentToEdit));
            assessmentToEdit.questions[index]["value"] = event;
            return { assessmentToEdit: assessmentToEdit };
        })
    };

    addNewQuestion = () => {
        this.setState(prevState => {
            let assessmentToEdit = JSON.parse(JSON.stringify(prevState.assessmentToEdit));
            assessmentToEdit.questions.push({ value: "", responseTime: "", questionType: "text", Answer: null });
            return { assessmentToEdit: assessmentToEdit }
        });
    };

    deleteQuestion = (e) => {
        let target = e.target

        this.setState(prevState => {
            let assessmentToEdit = JSON.parse(JSON.stringify(prevState.assessmentToEdit));
            if (assessmentToEdit.questions.length > 1) {
                assessmentToEdit.questions.splice(target.dataset.id, 1);
                return { assessmentToEdit: assessmentToEdit };
            } else console.log("Cannot delete question");
        });


    };

    updateQuestionAnswerVariants = (questionIndex, newAnswerVariants) => {
        this.setState(prevState => {
            let assessmentToEdit = JSON.parse(JSON.stringify(prevState.assessmentToEdit));
            assessmentToEdit.questions[questionIndex].Answer = newAnswerVariants;
            return { assessmentToEdit: assessmentToEdit }
        });
    };

    onHide = () => {
        this.setState({ formValidated: false });
        this.props.closeModal();
    };

    render() {
        let modalTitle = (this.props.shouldCreateNewAssessment) ? "Create assessment" : "Edit assessment";
        let assessmentForm = null;

        if (this.state.assessmentToEdit) {
            let questionsList = [];
            this.state.assessmentToEdit.questions.forEach((question, index) => {

                let answerVariants = null;
                if (question.questionType === "singleChoice" || question.questionType === "multipleChoice") {
                    answerVariants = (
                        <div>
                            <div className="mb-2">Answer variants</div>
                            <AnswerVariantsManager question={question} questionIndex={index} updateVariants={this.updateQuestionAnswerVariants} />
                            <Form.Text className="text-danger mt-2" hidden={!this.state.formValidated || question.Answer.length >= 2}>Please add at least 2 answer variants.</Form.Text>
                        </div>
                    );
                }

                questionsList.push((
                    <Card className="simple-card mb-3" key={index}>
                        <Card.Body>
                            <Form.Group>
                                <Form.Label>Question {index + 1}</Form.Label>
                                {/*<Form.Control as="textarea" name="value" type="text" value={question.value} data-id={index} onChange={this.handleChange} required />*/}
                                <ReactQuill
                                    name="value"
                                    theme="snow"
                                    onChange={this.handleChangeQuestionText.bind(this,index)}
                                    value={question.value}
                                    modules={CreateAssessment.modules}
                                    formats={CreateAssessment.formats}
                                />
                                <Form.Control.Feedback type="invalid">Please enter a text for question {index + 1}.</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Row>
                                <Form.Group as={Col} md="4">
                                    <Form.Label>Response time (minutes)</Form.Label>
                                    <Form.Control name="responseTime" type="number" min="1" value={question.responseTime} data-id={index} onChange={this.handleChange} required />
                                    <Form.Control.Feedback type="invalid">Please enter a response time greater than 1 minute.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="5">
                                    <Form.Label>Question type</Form.Label>
                                    <Form.Control as="select" name="questionType" value={question.questionType} data-id={index} onChange={this.handleChange} required>
                                        <option value="text">Text</option>
                                        <option value="singleChoice">Single choice</option>
                                        <option value="multipleChoice">Multiple choice</option>
                                        <option value="recorded">Recorded</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">Please select question type.</Form.Control.Feedback>
                                </Form.Group>

                                <Col md="3" className="d-flex align-items-end justify-content-end">
                                    <Button variant="danger" onClick={this.deleteQuestion} className="mb-3" data-id={index}>Delete question</Button>
                                </Col>
                            </Form.Row>
                            {answerVariants}
                        </Card.Body>
                    </Card>
                ));
            });

            assessmentForm = (
                <div>
                    <Form.Group>
                        <Form.Label>Assessment name</Form.Label>
                        <Form.Control name="name" type="text" value={this.state.assessmentToEdit.name} onChange={this.handleChange} required />
                        <Form.Control.Feedback type="invalid">Please enter a name for the assessment.</Form.Control.Feedback>
                    </Form.Group>
                    {questionsList}
                </div>
            );
        }

        return (
            <Modal size="lg" show={this.shouldDisplayModal()} onHide={this.onHide} backdrop="static">
                <Form onSubmit={this.handleSave} noValidate validated={this.state.formValidated}>
                    <Modal.Header closeButton>
                        <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {assessmentForm}
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <Button onClick={this.addNewQuestion}>Add new question</Button>
                        <div>
                            <Button className="mr-2" variant="secondary" onClick={this.onHide}>Cancel</Button>
                            <LoadingButton className="ml-2" type={"submit"} text={"Save"} isLoading={this.state.isLoadingSaveAssessment} />
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}
CreateAssessment.modules = {
    toolbar: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'},
            {'indent': '-1'}, {'indent': '+1'}]
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
}
CreateAssessment.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent'
]

CreateAssessment.contextType = AppContext;
export default CreateAssessment;
