import React from "react";
import axios from "axios";
import { Form, Card, Row, Col, FormText, Button } from "react-bootstrap";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { AppContext } from "../Utils/AppContext";
import { URLS } from "../Utils/Constants";
import { EventEmitter } from "../Utils/EventEmitter";
import LoadingButton from "../Utils/LoadingButton";
import { withRouter } from "react-router-dom";


class RecordedInterviewPlanner extends React.Component {
    state = {
        assessments: [],
        selectedAssessments: [],
        formValidated: false,
        isLoadingRecordedInterview: false
    };

    componentDidMount() {
        this.getAssessments();
        this.setState({
            candidateId: this.props.interviewData.candidateId,
            job: this.props.interviewData.jobId,
            expiryDate: null,
            candidateEmail: this.props.interviewData.candidateEmail,
            candidateName: this.props.interviewData.candidateName,
            assessments: []
        });
    }

    getAssessments = async () => {
        try {
            let response = await axios.get(`${URLS.JOBS}/assessment`, {
                headers: { "Authorization": `Bearer ${this.context.token}` }
            });
            if (response.status === 200) {
                this.setState({ assessments: response.data });
            }
        } catch (error) {
            EventEmitter.emit("showNotification", "danger", "Failed to load assessments.");
        }
    };

    planInterview = async (e) => {
        this.setState({ isLoadingRecordedInterview: true });
        e.preventDefault();
        if (e.target.checkValidity() && this.state.selectedAssessments.length > 0 ) {
            this.setState({ formValidated: false });
            let interview = {
                candidateId: this.state.candidateId,
                job: this.state.job,
                expiryDate: this.state.expiryDate,
                candidateEmail: this.state.candidateEmail,
                candidateName: this.state.candidateName,
                assessments: this.state.selectedAssessments.map(assessment => assessment.id)
            };
            try {
                let response = await axios.post(`${URLS.JOBS}/interview?domain=${window.location.origin}`, interview, {
                    headers: { "Authorization": `Bearer ${this.context.token}` }
                });
                if (response.status === 201) {
                    console.log("RECORDED RESPONSE", response.data);
                    this.props.closeModal();
                    EventEmitter.emit("showNotification", "success", "The selected candidate has been successfully invited to a recorded interview.");

                    this.setState({ isLoadingRecordedInterview: false });
                    EventEmitter.emit("getCandidates");
                }
            } catch (error) {
                EventEmitter.emit("showNotification", "danger", "Failed to create recorded interview.");
                this.setState({ isLoadingRecordedInterview: false });
            }
        } else {
            this.setState({ formValidated: true, isLoadingRecordedInterview: false });
            EventEmitter.emit("showNotification", "warning", "The entered data seems to be incorrect or incomplete.");
        }
    };

    handleDateChange = (e) => {
        if (e.toDate){
            let now = new Date();
            if (e.isSame(now, "day") && e.isBefore(now, "minute")){
                e.hour(now.getHours());
                e.minutes(now.getMinutes() + 1);
            }
            this.setState({expiryDate: e})
        }
    };

    dateIsValid = (date) => {
        return date.isAfter(new Date());
    };

    toggleSelectAssessment = (assessment) => {
        let assessmentIndex = this.state.selectedAssessments.indexOf(assessment);
        let selectedAssessments = this.state.selectedAssessments;
        if (assessmentIndex !== -1) {
            selectedAssessments.splice(assessmentIndex, 1);
        } else {
            selectedAssessments.push(assessment);
        }
        this.setState({ selectedAssessments: selectedAssessments });
    }

    redirectToCreateAssessment = () => {
        this.props.history.push({ pathname: "/assessments" });
    }
    render() {
        let assessmentsList = [];
        this.state.assessments.forEach((assessment, index) => {
            let className = (this.state.selectedAssessments.indexOf(assessment) !== -1) ? "assessment-item assessment-item-selected" : "assessment-item";
            assessmentsList.push((
                <div className={className} onClick={() => this.toggleSelectAssessment(assessment)} key={index}>{assessment.name}</div>
            ));
        });

        let selectedAssessmentsList = [];
        this.state.selectedAssessments.forEach((assessment, index) => {
            selectedAssessmentsList.push((
                <div className="selected-assessment mb-2 mr-2" onClick={() => this.toggleSelectAssessment(assessment)} key={index}>{assessment.name}</div>
            ));
        });


        let selectedAssessments = null;
        if (selectedAssessmentsList.length > 0) {
            selectedAssessments = (
                <div className="mb-2">
                    <div className="mb-2">Selected assessments</div>
                    <div className="d-flex flex-wrap">{selectedAssessmentsList}</div>
                </div>
            );
        }

        return (
            <Form onSubmit={this.planInterview} noValidate validated={this.state.formValidated}>
                <div className="mb-2">Assessments</div>
                {assessmentsList.length > 0 ?
                    <div>
                        <Card className="all-assessments-list mb-3">{assessmentsList}</Card>
                        <FormText className="text-danger mb-3" hidden={!this.state.formValidated || this.state.selectedAssessments.length}>Please select at least one assessment.</FormText>
                        {selectedAssessments}
                    </div>
                    :
                    <Button className="mb-3" onClick={this.redirectToCreateAssessment}> Create assessment </Button>
                }



                <Row className="align-items-end">
                    <Form.Group as={Col} className="mb-0" lg="6" sm="8" xs="6" >
                        <Form.Label htmlFor="date" className={"date"}>Expiration date</Form.Label>
                        <div className="d-flex">
                            <DateTime name="date" className=" mr-3 " timeFormat={ false } onChange={this.handleDateChange} value={this.state.expiryDate} isValidDate={this.dateIsValid} closeOnSelect inputProps={{ required: true, placeholder: "Date" }} />
                            <DateTime name="time"  dateFormat={ false } onChange={this.handleDateChange}  closeOnSelect  value={this.state.expiryDate} inputProps={{ required: true, placeholder: "Time" }} />
                        </div>
                    </Form.Group>

                    <Col className="d-flex justify-content-end" lg="6" sm="4" xs="6">
                        <LoadingButton variant={"primary"} type={"submit"} text={"Invite"} isLoading={this.state.isLoadingRecordedInterview}></LoadingButton>
                    </Col>
                </Row>
            </Form>
        );
    }

}

RecordedInterviewPlanner.contextType = AppContext;
export default withRouter(RecordedInterviewPlanner);
