import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'btoa';
import "./Fonts/roboto.css";
import './index.css';

ReactDOM.render((
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    ), document.getElementById('root')
);

    // serviceWorker.unregister();
