import React from "react";
import axios from "axios";
import Cookies from "js-cookie";
import {Card, Button, Col, Form as BSForm} from "react-bootstrap";
import {AppContext} from "../Utils/AppContext";
import {URLS} from "../Utils/Constants";
import EditAvatar from "../Utils/EditAvatar";
import {EventEmitter} from "../Utils/EventEmitter";
import UploadCV from "./UploadCV";
import ProfileStudyCard from "./ProfileStudyCard";
import ProfileWorkCard from "./ProfileWorkCard";
import ProfileSkillCard from "./ProfileSkillCard";
import BirthdatePicker from "./BirthdatePicker";
import LoadingButton from "../Utils/LoadingButton";
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';

class UserProfile extends React.Component {
    state = {
        profile: {
            personalInformation: {
                firstName: "",
                lastName: "",
                email: "",
                telephone: "",
                address: "",
                dateOfBirth: "",
                sex: "",
                profilePictureUrl: ""
            },
            studies: [],
            workExperience: [],
            skills: {
                "Linguistic": [],
                "Communication": [],
                "Organisational": [],
                "JobRelated": [],
                "Computer": [],
                "Other": []
            },
            workingExperienceYears : 0
        },
        formValidated: false,
        isLoadingSaveProfile: false
    };

    componentDidMount() {
        this.getCandidateProfile();
    }

    getCandidateProfile = async () => {
        try {
            const response = await axios.get(`${URLS.PROFILES}/resumes/${this.context.userId}`, {
                headers: {"Authorization": `Bearer ${this.context.token}`},
            });
            if (response.status === 200) {
                this.setState({profile: response.data[0]});
            }
        } catch (error) {
            EventEmitter.emit("showNotification", "info", "No profile found. In order to apply to a job, please create a profile by filling the form below or uploading your CV.");
        }
    };

    saveProfile = async (fields) => {
        this.setState({isLoadingSaveProfile: true});
        //e.preventDefault();
        // if (e.target.checkValidity()) {
        //     this.setState({formValidated: false});
        let profile = {
                personalInformation: {
                    firstName: fields.firstName,
                    lastName: fields.lastName,
                    email: fields.email,
                    telephone: fields.telephone,
                    address: fields.address,
                    dateOfBirth: this.state.profile.personalInformation.dateOfBirth,
                    sex: fields.sex,
                    profilePictureUrl: (this.state.profile.personalInformation.profilePictureUrl ? this.state.profile.personalInformation.profilePictureUrl : "")
                },
                studies: this.state.profile.studies,
                workExperience: this.state.profile.workExperience,
                skills: {
                    "Linguistic": this.state.profile.skills.Linguistic,
                    "Communication": this.state.profile.skills.Communication,
                    "Organisational": this.state.profile.skills.Organisational,
                    "JobRelated": this.state.profile.skills.JobRelated,
                    "Computer": this.state.profile.skills.Computer,
                    "Other": this.state.profile.skills.Other
                },
                workingExperienceYears : this.state.profile.workingExperienceYears
            };

            try {
                let accData = {name: fields.firstName + " " + fields.lastName};
                const accResponse = await axios.put(`${URLS.ACCOUNTS}/users/name`, accData, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${this.context.token}`
                    }
                });
                if (accResponse.status === 200) {
                    this.updateCookies();
                    const profileResponse = await axios.post(`${URLS.PROFILES}/resumes`, profile, {
                        headers: {"Authorization": `Bearer ${this.context.token}`}
                    });
                    if (profileResponse.status === 201) {
                        EventEmitter.emit("showNotification", "success", "Your profile has been updated successfully.");
                        this.setState({isLoadingSaveProfile: false});
                    }
                }
            } catch (error) {
                EventEmitter.emit("showNotification", "danger", "Failed to update profile.");
                this.setState({isLoadingSaveProfile: false});
            }
        // } else {
        //     EventEmitter.emit("showNotification", "warning", "The provided information seems to be incorrect or incomplete. Please try again.");
        //     this.setState({formValidated: true, isLoadingSaveProfile: false});
        // }
    };

    updateCookies = () => {
        let newName = this.state.profile.personalInformation.firstName + " " + this.state.profile.personalInformation.lastName;
        let user = JSON.parse(Cookies.get("user"));
        user.name = newName;
        Cookies.set("user", user);
        Cookies.set("crtUserName", newName);
        this.context.update({
            crtUserName: newName,
            user: user
        });
    };

    handleChange = (e) => {
        let target = e.target;
        this.setState(prevState => {
            let profile = JSON.parse(JSON.stringify(prevState.profile));
            if (target.className.includes("pers-info-field")) {
                profile.personalInformation[target.name] = target.value;
            } else if (target.className.includes("study-field")) {
                profile.studies[target.dataset.id][target.name] = target.value;
            } else if (target.className.includes("work-field")) {
                profile.workExperience[target.dataset.id][target.name] = target.value;
            } else if (target.className.includes("skill-field")) {
                profile.skills[target.dataset.category][target.dataset.id] = target.value;
            }else if (target.className.includes("years-experience-field")){
                if (target.value === null)
                    profile.workingExperienceYears = 0;
                else
                    profile.workingExperienceYears = parseInt(target.value);
            }
            return {profile: profile};
        });
    };

    handleBirthdateChange = (newValue) => {
        this.setState(prevState => {
            let profile = JSON.parse(JSON.stringify(prevState.profile));
            profile.personalInformation.dateOfBirth = newValue;
            return {profile: profile};
        });
    };

    changeProfilePicture = async (file) => {
        let formdata = new FormData();
        formdata.append("file", file);

        try {
            let response = await axios.put(`${URLS.PROFILES}/resumes/updateProfilePicture?id=${this.state.profile.accountIdentifier}`, formdata, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.context.token}`
                }
            });
            if (response.status === 200) {
                // this.setState({ avatar: response.data.avatar });
                this.setState(prevState => {
                    let profile = prevState.profile;
                    profile.personalInformation.profilePictureUrl = response.data;
                    return {profile: profile};
                });
                // EventEmitter.emit("showNotification", "success", "Logo updated successfully.");
            }
        } catch (error) {
            EventEmitter.emit("showNotification", "danger", "Failed to update picture.");
        }
    };

    addStudy = () => {
        this.setState(prevState => {
            let profile = JSON.parse(JSON.stringify(prevState.profile));
            profile.studies.push({
                beginningYear: "",
                endingYear: "",
                jobTitle: "",
                companyName: "",
                jobDescription: "",
                studiesDescription: ""
            });
            return {profile: profile};
        });
    };

    addWorkExperience = () => {
        this.setState(prevState => {
            let profile = JSON.parse(JSON.stringify(prevState.profile));
            profile.workExperience.push({
                beginningStudyYear: "",
                endingStudyYear: "",
                name: "",
                school: ""
            });
            return {profile: profile};
        });
    };

    addSkill = (category) => { //TODO: CHANGE
        this.setState(prevState => {
            let profile = JSON.parse(JSON.stringify(prevState.profile));
            if (profile.skills[category]) {
                profile.skills[category].push("");
            } else {
                profile.skills[category] = [""];
            }
            return {profile: profile};
        });
    };

    deleteStudy = (e) => {
        let target = e.target;
        this.setState(prevState => {
            let profile = JSON.parse(JSON.stringify(prevState.profile));
            profile.studies.splice(target.dataset.id, 1);
            return {profile: profile};
        });
    };

    deleteWorkExperience = (e) => {
        let target = e.target;
        this.setState(prevState => {
            let profile = JSON.parse(JSON.stringify(prevState.profile));
            profile.workExperience.splice(target.dataset.id, 1);
            return {profile: profile};
        });
    };

    deleteSkill = (e, category) => {
        let target = e.target;
        this.setState(prevState => {
            let profile = JSON.parse(JSON.stringify(prevState.profile));
            profile.skills[category].splice(target.dataset.id, 1);
            return {profile: profile};
        });
    };

    showUploadCV = () => {
        EventEmitter.emit("showUploadCVModal");
    };

    showCV = () => {
        window.open(this.state.profile.cvLink);
    };

    render() {
        let studies = [];
        this.state.profile.studies.forEach((study, index) => {
            studies.push(<ProfileStudyCard study={study} onChange={this.handleChange} delete={this.deleteStudy}
                                           key={index} index={index}/>);
        });

        let workExperience = [];
        this.state.profile.workExperience.forEach((work, index) => {
            workExperience.push(<ProfileWorkCard work={work} onChange={this.handleChange}
                                                 delete={this.deleteWorkExperience} key={index} index={index}/>);
        });

        let skills = [];
        Object.keys(this.state.profile.skills).map((category, index) => {
            skills.push(<ProfileSkillCard skills={this.state.profile.skills[category]} category={category}
                                          onChange={this.handleChange} delete={this.deleteSkill}
                                          key={index} index={index} addSkill={this.addSkill}/>);
        });
        let downloadCVBtn = null;
        if (this.state.profile.cvLink) {
            downloadCVBtn =
                <Button variant="outline-primary" className="mr-2" onClick={this.showCV}>Download CV</Button>
        }

        return (
            <div className="container page-container">
                <Formik
                    initialValues={{
                        firstName: this.state.profile.personalInformation.firstName ,
                        lastName: this.state.profile.personalInformation.lastName,
                        email: this.state.profile.personalInformation.email,
                        telephone: this.state.profile.personalInformation.telephone,
                        address: this.state.profile.personalInformation.address,
                        sex: this.state.profile.personalInformation.sex

                    }}
                    validationSchema={Yup.object().shape({
                        firstName: Yup.string()
                            .matches(/^.{2,}$/, "Your first name should have more than one character")
                            .required('First name is required'),
                        lastName: Yup.string()
                            .matches(/^.{2,}$/, "Your last name should have more than one character")
                            .required('Last name is required'),
                        email: Yup.string()
                            .email('Email is invalid')
                            .required('Email is required'),
                        telephone: Yup.string()
                            .matches(/[+(]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*/, "The telephone number must contain numbers in 0-9 interval. Characters ) and + are allowed ")
                            .required('Telephone number is required'),
                        address: Yup.string(),
                            // .required('Address is required'),
                        sex: Yup.string()
                            // .required('Choose between male and female')
                    })}
                    onSubmit={fields => {
                        this.saveProfile(fields);
                    }}
                    enableReinitialize = {true}
                    render={({errors, touched, fields}) => (
                        <Form>
                            <div className="d-flex align-items-center justify-content-between">
                                <h1 className="page-title">Personal information</h1>
                                <div>
                                    {downloadCVBtn}
                                    <Button variant="outline-primary"
                                            title="Generate profile automatically from Europass CV" className="mr-2"
                                            onClick={this.showUploadCV}>Upload CV</Button>
                                    <LoadingButton variant={"primary"} type={"submit"} text={"Save profile"} isLoading={this.state.isLoadingSaveProfile}></LoadingButton>
                                </div>
                            </div>
                            <Card className="simple-card mb-3">
                                <Card.Body>
                                    <div className="d-flex">
                                        <EditAvatar
                                            // base64
                                            name={this.state.profile.personalInformation.firstName + " " + this.state.profile.personalInformation.lastName}
                                            img={this.state.profile.personalInformation.profilePictureUrl}
                                            updateAvatar={this.changeProfilePicture}
                                        />
                                        <div className="flex-1 ml-4">
                                            <div className="form-group">
                                                <label>First name</label>
                                                <Field name="firstName" type="text"
                                                        value={this.state.profile.personalInformation.firstName}
                                                        onChange={this.handleChange}
                                                       className={"form-control pers-info-field" + (errors.firstName && touched.firstName ? ' is-invalid' : '')}/>
                                                <ErrorMessage name="firstName" component="div"
                                                              className="invalid-feedback"/>
                                            </div>
                                            <div className="form-group">
                                                <label>Last name</label>
                                                <Field name="lastName" type="text"
                                                       value={this.state.profile.personalInformation.lastName}
                                                       onChange={this.handleChange}
                                                       className={"form-control pers-info-field" +(errors.lastName && touched.lastName ? ' is-invalid' : '')}/>
                                                <ErrorMessage name="lastName" component="div"
                                                              className="invalid-feedback"/>
                                            </div>
                                        </div>
                                    </div>
                                    <BSForm.Row>
                                        <BSForm.Group as={Col} md="6">
                                            <label>Email</label>
                                            <Field name="email" type="email"
                                                   value={this.state.profile.personalInformation.email}
                                                   onChange={this.handleChange}
                                                   className={"form-control pers-info-field"  +(errors.email && touched.email ? ' is-invalid' : '')}/>
                                            <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                                        </BSForm.Group>
                                        <BSForm.Group as={Col} md="6">
                                            <label>Telephone</label>
                                            <Field name="telephone" type="text"
                                                   value={this.state.profile.personalInformation.telephone}
                                                   onChange={this.handleChange}
                                                   className={"form-control pers-info-field" +(errors.telephone && touched.telephone ? ' is-invalid' : '') }/>
                                            <ErrorMessage name="telephone" component="div"
                                                          className="invalid-feedback"/>
                                        </BSForm.Group>
                                    </BSForm.Row>
                                    <div className="form-group" as={Col} md="12">
                                        <label>Address</label>
                                        <Field name="address" type="text"
                                               value={this.state.profile.personalInformation.address}
                                               onChange={this.handleChange}
                                               className={"form-control pers-info-field" + (errors.address  && touched.address ? ' is-invalid' : '')}/>
                                        <ErrorMessage name="address" component="div" className="invalid-feedback"/>
                                    </div>
                                    <BSForm.Row className="justify-content-between">
                                        <Col md="6">
                                            <BirthdatePicker value={this.state.profile.personalInformation.dateOfBirth}
                                                             onChange={this.handleBirthdateChange}/>
                                        </Col>
                                        <BSForm.Group as={Col} md="4">
                                            <BSForm.Label>Sex</BSForm.Label>
                                            <Field as="select" name="sex"
                                                   value={this.state.profile.personalInformation.sex}
                                                   onChange={this.handleChange} className="form-control pers-info-field">
                                                <option value="">Choose...</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </Field>
                                            {/* <ErrorMessage name="sex" className="invalid-feedback"/> */}
                                        </BSForm.Group>

                                    </BSForm.Row>
                                </Card.Body>
                            </Card>

                            <div className="d-flex align-items-center justify-content-between">
                                <h1 className="page-title">Studies</h1>
                                <Button onClick={this.addStudy}>Add studies</Button>
                            </div>
                            {studies}

                            <div>
                                <h1 className="page-title">Work Experience</h1>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="form-group ">
                                        <label>Total years</label>
                                        <Field name="workingExperienceYears" type="number" min="0"
                                               value={this.state.profile.workingExperienceYears !== null ? this.state.profile.workingExperienceYears : 0}
                                               onChange={this.handleChange}
                                               className={"form-control years-experience-field" }
                                               required
                                        />
                                        <ErrorMessage name="workingExperienceYears" component="div"
                                                      className="invalid-feedback"/>
                                    </div>
                                    <Button onClick={this.addWorkExperience}>Add work experience</Button>
                                </div>
                            </div>
                            {workExperience}

                            <div className="d-flex align-items-center justify-content-between">
                                <h1 className="page-title">Skills</h1>
                            </div>
                            { skills }


                        </Form>
                    )}
                />

                <UploadCV refreshProfile={this.getCandidateProfile}/>
            </div>
        );
    }
}

UserProfile.contextType = AppContext;
export default UserProfile;