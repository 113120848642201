import React from "react";
import { AppContext } from "../Utils/AppContext";
import { EventEmitter } from "../Utils/EventEmitter";

class QuestionTimer extends React.Component {
    state = {
        remainingTime: 0,
        countInterval: null
    };

    componentDidMount() {
        EventEmitter.on("startRecordedInterviewQuestionTimer", this.startCounting);
        this.initCountValue();
        if (this.props.count) this.startCounting();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.questionId !== this.props.questionId) {
            this.initCountValue();
            clearInterval(this.state.countInterval);
            if (this.props.count) this.startCounting();
        }

        if (prevProps.count !== this.props.count) {
            clearInterval(this.state.countInterval);
            if (this.props.count) this.startCounting();
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.countInterval);
    }

    initCountValue = () => {
        this.setState({ remainingTime: this.props.time });
    };

    startCounting = () => {
        clearInterval(this.state.countInterval);
        this.setState({
            countInterval: setInterval(() => {
                if (this.state.remainingTime === 0) {
                    clearInterval(this.state.countInterval);
                    EventEmitter.emit("recordedInterviewQuestionTimeExpired");
                } else {
                    this.setState(prevState => ({ remainingTime: prevState.remainingTime - 1 }));
                }
            }, 1000)
        });
    }

    render() {
        let content;
        if (this.state.remainingTime > 0) {
            content = (
                <React.Fragment>
                    <span className="text-sec2 mr-2">Remaining response time:</span>
                    <span>{ this.state.remainingTime } seconds</span>
                </React.Fragment>
            );
        } else content = <strong>Response time has expired.</strong>;
        return <div className="mt-3">{ content }</div>;
    }
}

QuestionTimer.contextType = AppContext;
export default QuestionTimer;
