import React from "react";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import { EventEmitter } from "./EventEmitter";
import "react-notifications-component/dist/theme.css";

class Notifications extends React.Component {
    componentDidMount() {
        EventEmitter.on("showNotification", (type, message) => {
            store.addNotification({
                message: message,
                type: type,
                container: "top-right",
                animationIn: ["animated", "fadeInDown"],
                animationOut: ["animated", "fadeOutUp"],
                dismiss: { duration: 5000 }
            });
        });
    }

    render() {
        return <ReactNotification />;
    }
}

export default Notifications;
