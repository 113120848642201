import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";


const LoadingButton = (props) => (
    <Button
        type={props.type}
        variant={props.variant}
        text={props.text}
        disabled={props.isLoading}
        onClick={props.onClick}>
        {props.isLoading && (
            <FontAwesomeIcon icon={faSpinner} style={{ marginRight: "5px", color: "white" }} className="fa-spin" />
        )}

        {props.text}
    </Button>

)

export default LoadingButton;