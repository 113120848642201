import React from "react";
import {Button, Card, Form} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBuilding,
    faCoins,
    faEdit,
    faInfo,
    faInfoCircle,
    faMapMarkerAlt,
    faTrash,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import Avatar from "../Utils/Avatar";
import { AppContext } from "../Utils/AppContext";
import LoadingButton from "../Utils/LoadingButton";
import 'react-quill/dist/quill.snow.css'; // ES6
import {styleEditorShow} from "../Utils/EditorShow.css";
import ReactQuill from "react-quill";
import CreateJob from "./CreateJob";
class JobOpeningCard extends React.Component {
    state = {
        showAllDescription: false,
        showAllSkills: false
    };

    toggleShowDescription = () => {
        this.setState(prevState => {
            return { showAllDescription: !prevState.showAllDescription }
        });
    };

    toggleShowSkills = () => {
        this.setState(prevState => {
            return { showAllSkills: !prevState.showAllSkills }
        });
    };

    renderWorkExperience = (workExperience) => {
        switch (workExperience) {
            case 0:
                return "No experience required";
            default:
                return workExperience+"+ years experience";
        }
    };

    render() {
        let isHR = this.context.role === "HR_ROLE";
        let isCompany = this.context.role === "COMPANY_ROLE";

        let applyBtn;
        let userApplied = false;
        for (let cnd of this.props.job.candidates) {
            if (cnd.candidate === this.context.userId) {
                userApplied = true;
                break;
            }
        }
        applyBtn = (userApplied) ?
            <Button disabled>Applied</Button>
            :
            <LoadingButton  onClick={() => { this.props.handleApply(this.props.job.id) }}
                text={"Apply"}
                isLoading={this.props.isLoadingApply} />;


        let description = this.props.job.description;
        let showAllDescriptionBtn = null;
        if (this.props.job.description.length > 105) {
            let showAllDescriptionBtnText = "Show less";
            if (!this.state.showAllDescription) {
                description = this.props.job.description.substring(0, 100) + "...";
                showAllDescriptionBtnText = "Show more";
            }
            showAllDescriptionBtn = <Button className="p-0" variant="link"
                onClick={this.toggleShowDescription}>{showAllDescriptionBtnText}</Button>
        }

        let showAllSkillsBtn = null;
        let skills = this.props.job.skillset;
        if (this.props.job.skillset.length > 3) {
            let showAllSkillsBtnText = "Show less";
            if (!this.state.showAllSkills) {
                skills = skills.slice(0, 3);
                showAllSkillsBtnText = "Show more";
            }
            showAllSkillsBtn = <Button className="p-0" variant="link"
                onClick={this.toggleShowSkills}>{showAllSkillsBtnText}</Button>
        }
        let skillsList = skills.map((skill, index) => (
            <li key={index}>{skill}</li>
        ));
        skills = <ul>{skillsList}</ul>;

        let jobActions;
        if (isHR || isCompany) {
            jobActions = (
                <div className="d-flex flex-column flex-md-row">
                    <Button variant="basic" className="btn-ic ml-2"
                        onClick={() => this.props.openEditJobModal(this.props.job)} title="Edit job opening">
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    <Button variant="basic" className="btn-ic ml-2"
                        onClick={() => this.props.deleteJob(this.props.job.id)} title="Delete job opening">
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                    <Button variant="basic" className="btn-ic ml-2"
                        onClick={() => this.props.showJobInfo(this.props.job)} title="View job info">
                        <FontAwesomeIcon icon={faInfo} />
                    </Button>
                </div>
            );
        } else {
            jobActions = (this.props.job.enabled) ? <div>{applyBtn}</div> :
                <div className="text-sec2">Currently unavailable</div>;
        }

        let cardClassName = (this.props.job.enabled) ? "simple-card job-card-enabled" : "simple-card job-card-disabled";

        let candidatesCount = null;
        if (isHR || isCompany) {
            candidatesCount = (
                <div title="Number of candidates">
                    <FontAwesomeIcon className="font-weight-bold" icon={faUsers} />
                    <span className="ml-2">{this.props.job.candidates.length}</span>
                </div>
            );
        }

        return (
            <Card className={cardClassName}>
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <Avatar name={this.props.job.companyName} img={this.props.job.logo} />
                        <div className="ml-3">
                            <div className="font-weight-bold">{this.props.job.postingTitle}</div>
                            <div>{this.props.job.companyName}</div>
                        </div>
                    </div>
                    {candidatesCount}
                </Card.Header>
                <Card.Body className="py-3 d-flex flex-column justify-content-between">
                    <div className="mb-3 text-sec1" hidden={!this.props.job.description} contentEditable={false} >
                        <ReactQuill
                            id="editor-show"
                            readOnly={true}
                            theme='snow'
                            value={description}
                            modules={JobOpeningCard.modules}
                            style={styleEditorShow}
                            className="job-description"
                        />
                        {showAllDescriptionBtn}
                    </div>
                    <div className="mb-3" hidden={!this.props.job.skillset || this.props.job.skillset.length <= 0}>
                        Required skills:
                        <div>{skills}</div>
                        {showAllSkillsBtn}
                    </div>
                    <div className="d-flex align-items-end justify-content-between">
                        <div className="job-info-grid text-sec2">
                            <FontAwesomeIcon icon={faBuilding} />
                            <div className="mx-2">{this.props.job.type}</div>
                            <FontAwesomeIcon icon={faInfoCircle} hidden={this.props.job.workExperience === null} />
                            <div className="mx-2" hidden={this.props.job.workExperience === null}>{this.renderWorkExperience(this.props.job.workExperience)}</div>
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                            <div className="mx-2">{this.props.job.address}</div>
                            <FontAwesomeIcon icon={faCoins} hidden={!this.props.job.salary} />
                            <div className="mx-2" hidden={!this.props.job.salary}>{this.props.job.salary}</div>
                        </div>
                        <div>{jobActions}</div>
                    </div>
                </Card.Body>
            </Card>
        );
    }
}
JobOpeningCard.modules = {
    toolbar: false,
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: true,
    }
}
JobOpeningCard.contextType = AppContext;
export default JobOpeningCard;
