import React from "react";

class Avatar extends React.Component {
    render() {
        let size = (this.props.size) ? this.props.size : 3.5;
        if (this.props.img) {
            let style = {
                display: "inline-block",
                width: size + "rem",
                height: size + "rem",
                flexGrow: 0,
                flexShrink: 0,
                borderRadius: "50%",
                objectFit: "cover",
                border: "1px solid #aeb9c1"
            }
            return <img src={ this.props.img } style={ style } alt={ this.props.name } title={ this.props.name } />;

        } else {
            let name=this.props.name || "";
            let namesList = name.split(" ");
            let avatarContent = "";
            if (namesList.length <= 3) {
                for (let name of namesList) {
                    avatarContent += name.charAt(0);
                }
            } else {
                avatarContent = namesList[0].charAt(0) + namesList[1].charAt(0) + namesList[namesList.length - 1].charAt(0);
            }
            let style = {
                display: "flex",
                width: size + "rem",
                height: size + "rem",
                borderRadius: "50%",
                alignItems: "center",
                justifyContent: "center",
                flexGrow: 0,
                flexShrink: 0,
                background: "#aeb9c1",
                color: "#fff",
                fontSize: size / 3 + "rem"
            }
            return <div style={ style } title={ this.props.name }>{ avatarContent }</div>;
        }
    }
}

export default Avatar;
