import React from "react";
import Avatar from "../Utils/Avatar";
import { Card } from "react-bootstrap";
import { AppContext } from "../Utils/AppContext";
import { EventEmitter } from "../Utils/EventEmitter";
import CandidateStatus from "./CandidateStatus";

class CandidateCard extends React.Component {
    showCandidateInfo = () => {

        EventEmitter.emit("showCandidateInfoModal", this.props.candidate, this.props.job.id, this.props.status,this.props.recommendation[this.props.job.id]);
    };

    render() {
        let cardClassName = "simple-card ";
        switch (this.props.status) {
            case 'APPLIED':
                cardClassName += "card-candidate-applied";
                break;

            case 'SCHEDULED':
                cardClassName += "card-candidate-scheduled";
                break;

            case 'REJECTED':
                cardClassName += "card-candidate-rejected";
                break;

            case 'REFUSED':
                cardClassName += "card-candidate-refused";
                break;

            case 'PENDING':
                cardClassName += "card-candidate-pending";
                break;

            case 'ACCEPTED':
                cardClassName += "card-candidate-accepted";
                break;

            case 'RECHECK':
                cardClassName += "card-candidate-recheck";
                break;
            default:
                ;
        }

        let cndName = this.props.candidate.personalInformation.firstName + " " + this.props.candidate.personalInformation.lastName;
        let isRecommended = (this.props.recommendation[this.props.job.id] && this.props.recommendation[this.props.job.id].candidates.includes(this.props.candidate.accountIdentifier));
        let hrThatRecommended = this.props.recommendation[this.props.job.id] ? this.props.recommendation[this.props.job.id].hr.name : "";
        let isCompany = this.context.role === "COMPANY_ROLE";
        return (
            <Card className={ cardClassName }>
                <Card.Header className="candidate-card-header" onClick={ this.showCandidateInfo } title="View profile"
                             tabIndex="0" >
                    <Avatar name={ cndName } img={ this.props.candidate.personalInformation.profilePictureUrl }/>
                    <div className="mx-3">
                        <div className="font-weight-bold">{ cndName }</div>
                        { (isRecommended && isCompany) ?
                            <div className="text-sec1">Recommended by { hrThatRecommended }</div> :
                            <div className="text-sec1">applied
                                for { this.props.job.postingTitle } at { this.props.job.companyName }</div> }
                    </div>
                </Card.Header>

                <Card.Body className="d-flex flex-column-reverse py-3">
                    <CandidateStatus
                        shortlist={ this.props.shortlist }
                        status={ this.props.status }
                        candidate={ this.props.candidate }
                        jobId={ this.props.job.id }
                        showCreateInterview={ this.props.showCreateInterview }
                        isCandidateWithJob={this.props.isCandidateWithJob}
                    />
                </Card.Body>
            </Card>
        );
    }
}

CandidateCard.contextType = AppContext;
export default CandidateCard;
