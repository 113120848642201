import React from "react";
import Form from "react-bootstrap/Form";
import { AppContext } from "../Utils/AppContext";
import "./RecordedInterview.css";

class InterviewMultipleAnswerForm extends React.Component {
    state = {
        selectedAnswers: []
    };

    componentDidMount() {
        let selectedAnswers = (this.props.crtQuestion.selectedAnswers) ? [...this.props.crtQuestion.selectedAnswers] : [];
        this.setState({ selectedAnswers: selectedAnswers });
    }

    componentDidUpdate(prevProps) {
        if (this.props.crtQuestion !== prevProps.crtQuestion) {
            let selectedAnswers = (this.props.crtQuestion.selectedAnswers) ? [...this.props.crtQuestion.selectedAnswers] : [];
            this.setState({ selectedAnswers: selectedAnswers });
        }
    }

    onAnswerChanged = (e) => {
        if (!this.props.timerExpired) {
            let selectedAnswers = (this.props.crtQuestion.questionType === "multipleChoice") ? [...this.state.selectedAnswers] : [];
            let answer = parseInt(e.target.dataset.id, 10);
            if (e.target.checked) {
                selectedAnswers.push(answer);
            } else {
                let ansIndex = selectedAnswers.indexOf(answer);
                if (ansIndex !== -1) selectedAnswers.splice(ansIndex, 1);
            }
            this.props.onAnswerChanged(selectedAnswers);
        }
    }

    crtQuestionHasCorrectAnswer = () => {
        for (let answer of this.props.crtQuestion.Answer) {
            if (answer.correct) return true;
        }
        return false;
    }

    render() {
        let answerVariants = []
        if (this.props.respond) { // respond to interview
            this.props.crtQuestion.Answer.forEach((ansVariant, index) => {
                let checkType = (this.props.crtQuestion.questionType === "singleChoice") ? "radio" : "checkbox";
                let isChecked = this.state.selectedAnswers.indexOf(index) !== -1;
                answerVariants.push((
                    <Form.Check key={ index } className="mb-1">
                        <Form.Check.Label>
                            <Form.Check.Input
                                type={ checkType }
                                onChange={ this.onAnswerChanged }
                                checked={ isChecked }
                                data-id={ index }
                                name={ "ans-variant" }
                                disabled={ this.props.timerExpired }
                            />
                            { ansVariant.value }
                        </Form.Check.Label>
                    </Form.Check>
                ));
            });

        } else { // view interview
            this.props.crtQuestion.Answer.forEach((ansVariant, index) => {
                let ansClass = "answer-variant";
                if (["HR_ROLE", "COMPANY_ROLE"].includes(this.context.role) && this.crtQuestionHasCorrectAnswer()) {
                    ansClass = (ansVariant.correct) ? `${ ansClass } answer-variant-correct` : `${ ansClass } answer-variant-incorrect`;
                }
                if (this.state.selectedAnswers.indexOf(index) !== -1) ansClass = `${ ansClass } answer-variant-selected`;
                answerVariants.push((
                    <div className={ ansClass } key={ index }>{ ansVariant.value }</div>
                ));
            });
        }

        return (
            <div>
                <div className="text-sec2 mb-1">Answer variants</div>
                { answerVariants }
            </div>
        );
    }
}

InterviewMultipleAnswerForm.contextType = AppContext;
export default InterviewMultipleAnswerForm;