import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class NotificationModal extends React.Component {
    render() {
        let confirmBtn = (this.props.confirmAction) ? <Button className="ml-2" onClick={ this.props.confirmAction }>Confirm</Button> : null;
        return (
            <Modal size="lg" show={ this.props.show } onHide={ this.props.onHide }>
                <Modal.Header closeButton>
                    <Modal.Title>{ this.props.title }</Modal.Title>
                </Modal.Header>

                <Modal.Body>{ this.props.content }</Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={ this.props.onHide }>Close</Button>
                    { confirmBtn }
                </Modal.Footer>
            </Modal>
        );
    }
}

export default NotificationModal;